<!--<div class="container" id="citation-component">-->
<!--  <div class="d-flex flex-column">-->
<!--    <h3 class="p-3 text-info"> CITATION</h3>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> MHC Class I Prediction </h4>-->
<!--      <p>-->
<!--        ANN Method - -->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=12717023"-->
<!--          target="_blank">-->
<!--          Nielsen M, Lundegaard C, Worning P, Lauemoller SL, Lamberth K, Buus S, Brunak S, Lund O. 2003. Reliable-->
<!--          prediction of T-cell epitopes using-->
<!--          neural networks with novel sequence representations. <i>Protein Sci</i> <b>12</b>:1007-1017.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        ARB Method - -->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=15868141"-->
<!--          target="_blank">-->
<!--          Bui HH, Sidney J, Peters B, Sathiamurthy M, Sinichi A, Purton KA, Mothe BR, Chisari FV, Watkins DI, Sette A.-->
<!--          2005.-->
<!--          Automated generation and evaluation of specific MHC binding predictive tools: ARB matrix applications. <i>Immunogenetics</i>-->
<!--          <b>57</b>:304-314.</a>-->
<!--      </p>-->

<!--      <p>-->
<!--        SMM Method - -->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=15927070"-->
<!--          target="_blank">-->
<!--          Peters B, Sette A. 2005. Generating quantitative models describing the sequence specificity of-->
<!--          biological processes with the stabilized matrix method. <i>BMC Bioinformatics</i> <b>6</b>:132.</a>-->
<!--      </p>-->

<!--      <p>-->
<!--        NetMHCcons Method - -->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=22009319"-->
<!--          target="_blank">-->
<!--          Karosiene E, Lundegaard C, Lund O and Nielsen M. 2012. NetMHCcons: a consensus method for the major-->
<!--          histocompatibility complex class I predictions. <i>Immunogenetics</i> <b>64(3)</b>:177-186</a>-->
<!--      </p>-->


<!--      <p>-->
<!--        Pickpocket Method - -->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=19297351"-->
<!--          target="_blank">-->
<!--          Zhang H, Lund O and Nielsen M. 2009. The PickPocket method for predicting binding specificities for receptors-->
<!--          based on receptor pocket similarities: application to MHC–peptide binding.-->
<!--          <i>Bioinformatics</i> <b>25(10)</b>:1293–1299.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> MHC Class II Prediction </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=15868141"-->
<!--          target="_blank">-->
<!--          Bui HH, Sidney J, Peters B, Sathiamurthy M, Sinichi A, Purton KA, Mothe BR, Chisari FV, Watkins-->
<!--          DI, Sette A. 2005. Automated generation and evaluation of specific MHC binding predictive tools: ARB matrix-->
<!--          applications. <i>Immunogenetics</i> <b>57</b>:304-314.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=17608956"-->
<!--          target="_blank">-->
<!--          Nielsen M, Lundegaard C, Lund O. 2007. Prediction of MHC class II binding affinity using-->
<!--          SMM-align, a novel stabilization matrix alignment method. <i>BMC Bioinformatics</i> <b>8</b>:238.</a>-->
<!--      </p>-->

<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=10385319"-->
<!--          target="_blank">-->
<!--          Sturniolo T, Bono E, Ding J, Raddrizzani L, Tuereci O, Sahin U, Braxenthaler M, Gallazzi F,-->
<!--          Protti MP, Sinigaglia F, Hammer J. 1999. Generation of tissue-specific and promiscuous HLA ligand databases-->
<!--          using DNA microarrays and virtual HLA class II matrices. <i>Nat Biotechnol</i> <b>17</b>:555-561.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Combined Proteasomal Cleavage/TAP Transport/MHC Class I Predictor </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=15868101"-->
<!--          target="_blank">-->
<!--          Tenzer S, Peters B, Bulik S, Schoor O, Lemmel C, Schatz MM, Kloetzel PM, Rammensee HG, Schild-->
<!--          H, Holzhutter HG. 2005. Modeling the MHC class I pathway by combining predictions of proteasomal cleavage, TAP-->
<!--          transport and MHC class I binding. <i>Cell Mol Life Sci</i> <b>62</b>:1025-1037.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=12902473"-->
<!--          target="_blank">-->
<!--          Peters B, Bulik S, Tampe R, Van Endert PM, Holzhutter HG. 2003. Identifying MHC class I epitopes by predicting-->
<!--          the TAP transport efficiency-->
<!--          of epitope precursors. <i>J Immunol</i><b>171</b>:1741-1749.</a>-->
<!--      </p>-->

<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> NetChop, NetCTL and NetCTLpan </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=15744535"-->
<!--          target="_blank">-->
<!--          Nielsen M, Lundegaard C, Lund O, Kesmir C. 2005. The role of the proteasome in generating-->
<!--          cytotoxic T-cell epitopes: insights obtained from improved predictions of proteasomal cleavage.-->
<!--          <i>Immunogenetics</i> <b>57</b>:33-41.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=11983929"-->
<!--          target="_blank">-->
<!--          Kesmir C, Nussbaum AK, Schild H, Detours V, Brunak S. 2002. Prediction of proteasome cleavage-->
<!--          motifs by neural networks. <i>Protein Eng</i> <b>15</b>:287-296.</a>-->
<!--      </p>-->

<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=15997466"-->
<!--          target="_blank">-->
<!--          Larsen MV, Lundegaard C, Lamberth K, Buus S, Brunak S, Lund O, Nielsen M. 2005. An integrative-->
<!--          approach to CTL epitope prediction: a combined algorithm integrating MHC class I binding, TAP transport-->
<!--          efficiency, and proteasomal cleavage predictions. <i>Eur J Immunol</i><b>35</b>:2295-2303.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=12717023"-->
<!--          target="_blank">-->
<!--          Nielsen M, Lundegaard C, Worning P, Lauemoller SL, Lamberth K, Buus S, Brunak S, Lund O. 2003.-->
<!--          Reliable prediction of T-cell epitopes using neural networks with novel sequence representations. <i>Protein-->
<!--          Sci</i> <b>12</b>:1007-1017.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=14617044"-->
<!--          target="_blank">-->
<!--          Buus S, Lauemoller SL, Worning P, Kesmir C, Frimurer T, Corbet S, Fomsgaard A, Hilden J, Holm-->
<!--          A, Brunak S. 2003. Sensitive quantitative predictions of peptide-MHC binding by a 'Query by Committee'-->
<!--          artificial neural network approach. <i>Tissue Antigens</i> <b>62</b>:378-384.</a>1-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=20379710"-->
<!--          target="_blank">-->
<!--          Stranzl T, Larsen MV, Lundegaard C, Nielsen M. NetCTLpan: pan-specific MHC class I pathway epitope-->
<!--          predictions. <i>Immunogenetics</i> <b>62</b>:357-68.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Scale-Based B Cell Epitope Prediction </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=364941"-->
<!--          target="_blank">-->
<!--          Chou PY, Fasman GD. 1978. Prediction of the secondary structure of proteins from their amino-->
<!--          acid sequence. <i>Adv Enzymol Relat Areas Mol Biol</i> <b>47</b>:45-148.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=2991600"-->
<!--          target="_blank">-->
<!--          Emini EA, Hughes JV, Perlow DS, Boger J. 1985. Induction of hepatitis A virus-neutralizing-->
<!--          antibody by a virus-specific synthetic peptide. <i>J Virol</i> <b>55</b>:836-839.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a href="http://www.springerlink.com/content/q1224q1555013j53/" target="_blank">-->
<!--          Karplus PA, Schulz GE. 1985. Prediction of chain flexibility in proteins. <i>Naturwissenschaften</i><b>72</b>:212-213.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=1702393"-->
<!--          target="_blank">-->
<!--          Kolaskar AS, Tongaonkar PC. 1990. A semi-empirical method for prediction of antigenic-->
<!--          determinants on protein antigens. <i>FEBS Lett</i> <b>276</b>:172-174.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=2430611"-->
<!--          target="_blank">-->
<!--          Parker JM, Guo D, Hodges RS. 1986. New hydrophilicity scale derived from high-performance-->
<!--          liquid chromatography peptide retention data: correlation of predicted surface residues with antigenicity and-->
<!--          X-ray-derived accessible sites. <i>Biochemistry</i> <b>25</b>:5425-5432.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=pubmed&amp;dopt=AbstractPlus&amp;list_uids=16635264"-->
<!--          target="_blank">-->
<!--          Larsen JE, Lund O, Nielsen M. 2006. Improved method for predicting linear B-cell epitopes.<i>Immunome Res</i>-->
<!--          <b>2</b>:2.</a>-->
<!--      </p>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=17910770"-->
<!--          target="_blank">-->
<!--          Ponomarenko JV, Bourne PE. 2007. Antibody-protein interactions: benchmark datasets and-->
<!--          prediction tools evaluation. <i>BMC Struct Biol</i> <b>7</b>:64.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Discotope </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=17001032"-->
<!--          target="_blank">-->
<!--          Haste Andersen P, Nielsen M, Lund O. 2006. Prediction of residues in discontinuous B-cell-->
<!--          epitopes using protein 3D structures. <i>Protein Sci</i> <b>15</b>:2558-2567.</a>-->
<!--      </p>-->

<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Ellipro </h4>-->
<!--      <p>-->
<!--        <a href="http://www.biomedcentral.com/1471-2105/9/514" target="_blank">-->
<!--          Ponomarenko JV, Bui H, Li W, Fusseder N, Bourne PE, Sette A, Peters B. 2008. ElliPro: a new structure-based-->
<!--          tool for the prediction of antibody epitopes.-->
<!--          <span style="font-style: italic;">BMC Bioinformatics</span> <span style="font-weight: bold;">9</span>:514</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Population Coverage </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?cmd=Retrieve&amp;db=PubMed&amp;dopt=Citation&amp;list_uids=16545123"-->
<!--          target="_blank">-->
<!--          Bui HH, Sidney J, Dinh K, Southwood S, Newman MJ, Sette A. 2006. Predicting population coverage-->
<!--          of T-cell epitope-based diagnostics and vaccines. <i>BMC Bioinformatics</i> <b>7</b>:153.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Epitope Conservancy Analysis </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?db=pubmed&amp;cmd=Retrieve&amp;dopt=AbstractPlus&amp;list_uids=17897458"-->
<!--          target="_blank">-->
<!--          Bui HH, Sidney J, Li W, Fusseder N, Sette A. 2007. Development of an epitope conservancy-->
<!--          analysis tool to facilitate the design of epitope-based diagnostics and vaccines. <i>BMC Bioinformatics</i>-->
<!--          <b>8</b>:361.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--    <div class="p-3 mb-2 bg-light">-->
<!--      <h4> Homology Mapping </h4>-->
<!--      <p>-->
<!--        <a-->
<!--          href="http://www.ncbi.nlm.nih.gov/sites/entrez?db=pubmed&amp;cmd=Retrieve&amp;dopt=AbstractPlus&amp;list_uids=17313688"-->
<!--          target="_blank">-->
<!--          Beaver JE, Bourne PE, Ponomarenko JV. 2007. EpitopeViewer: a Java application for the-->
<!--          visualization and analysis of immune epitopes in the Immune Epitope Database and Analysis Resource (IEDB).-->
<!--          <i>Immunome Res</i> <b>3</b>:3.</a>-->
<!--      </p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<!--<div class="container" id="citation-component">-->
<!--  <div class="d-flex flex-column">-->
<!--    <h3 class="p-3 text-info"> CITATION</h3>-->
<!--    <p *ngFor="let citation of citations">-->
<!--      {{citation.method_name}} ({{citation.key}}) - -->
<!--      <a-->
<!--        [href]="'https://pubmed.ncbi.nlm.nih.gov/'.concat(citation.pubmed_id)"-->
<!--        target="_blank">-->
<!--        {{citation.citation_html}}</a>-->
<!--    </p>-->
<!--  </div>-->
<!--</div>-->

<div class="container" id="citation-component">
  <div *ngIf="loading" class="p-2 d-flex justify-content-center col">
    <div class="spinner-border text-primary align-self-center p-3 mb-3" role="status">
      <span class="visually-hidden sr-only" >Loading...</span>
    </div>
  </div>
  <div *ngIf="!loading" class="d-flex flex-column">
    <h3 class="p-3 text-info"> CITE US</h3>
    <p class="px-3 fst-italic">Publications for each of the individual methods that have been implemented on this site
      are listed below, as well as publications describing the IEDB Analysis Resource in general. Please cite
      accordingly in any works published that make use of this resource.</p>
    <hr>
    <div *ngFor="let name of uniq" class="p-3 mb-2 bg-light">
    <ng-container *ngFor="let citation of groupedCitations | keyvalue">
      <ng-container *ngIf="name && name===citation.key">
      <h4> {{citation.key}} </h4>
      <p *ngFor="let article of citation.value">
        {{article.display_name}} ({{article.method_version}})
        <a
          [href]="'https://pubmed.ncbi.nlm.nih.gov/'.concat(article.pubmed_id)"
          target="_blank">
          {{article.citation_html}}</a>
      </p>
      </ng-container>
    </ng-container>
    </div>

  </div>
</div>
