import {Component} from '@angular/core';
// import {Router} from '@angular/router';
// import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
// import {Location} from "@angular/common";

export const GLOBALS = {
  logo: 'assets/logos/iedb/IEDB-logo-blue.svg',
  // logo: 'assets/images/logo-new.png',
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  logoImage = GLOBALS.logo;
  status: boolean = false;

  constructor() {}

}
