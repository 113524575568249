import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tag' })
export class tagPipe implements PipeTransform {
  transform(value: string): any {
    if(value) {
      if(value==='mhcI') {
        return 'MHC I';
      }
      else if(value==='allele7') {
        return '7-Allele';
      }
      else if(value==='mhcII') {
        return 'MHC II';
      }
      else if(value==='hlaAssociation') {
        return 'HLA Association';
      }
      else if(value==='structure3D') {
        return '3D Structure';
      }
      else if(value==='nonPeptidic') {
        return 'Non-Peptidic';
      }
      else if(value==='mhcProcessing') {
        return 'MHC Processing';
      }
      else if(value==='tapProcessing') {
        return 'TAP Processing';
      }
      else if(['TCR', 'BCR', 'PDB', 'CD4', 'CD8', 'VCF'].includes(value)) {
        return value;
      }
      else {
        return value
          // insert a space before all caps
          .replace(/([A-Z])/g, ' $1')
          // uppercase the first character
          .replace(/^./, function(str){ return str.toUpperCase(); });
      }
    }
    return null;
  }
}
