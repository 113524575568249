<div class="container">
  <div>
    <ngb-toast class="fixed-bottom w-50 bg-dark text-white" *ngIf="!this.closePopup" [autohide]="false"
               (hidden)="deny()">
      <div class="text-start p-4 d-flex flex-column">
        <h4 class="veryLargeText">Privacy settings</h4>
        <p class="smallerText text-start">
          We use cookies to gather anonymized analytics data and use this information to monitor website performance and
          make user interface improvements. You can learn more about our use of cookies and how your privacy is managed
          in our <a routerLink="/docs/privacy/privacy-notice.html"
                    target="_blank">Privacy Policy</a>
<!--          href="https://nextgen-tools-dev.iedb.org/docs/privacy/privacy-notice.html"-->
        </p>
        <div class="text-end">
          <button type="button" (click)="deny(); setUserAnswered(true);"
                  class="btn btn-outline-light mr-3 mx-2 smallerText" id="btnDeny">
            Reject non-essential cookies
          </button>
          <button type="button" (click)="accept(); setUserAnswered(true);" class="btn btn-light mx-2 smallerText"
                  id="btnAccept">
            Accept all cookies
          </button>
        </div>
      </div>
    </ngb-toast>
  </div>
</div>
