import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {environment} from '../../../environments/environment';
// import * as _ from "lodash";
const API_URL = `${environment['api_url']}/api/v1/announcements`;

@Injectable({
  providedIn: 'root'
})

export class AnnouncementService {

  constructor(private http: HttpClient) { }

  getAnnouncements() : any {
    return this.http.get(API_URL)
  }
}
