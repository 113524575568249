import { Injectable } from '@angular/core';
import _ from 'lodash';
import {isASCII} from "../../util/string-helpers";

@Injectable({
  providedIn: 'root'
})
export class SequenceParserService {
  /**
   * contains functions to parse string and structure them in filteredSequence.
   * Also counts number of amino acids -> numOfAA

   * */
  public filteredSequences: { name: string, sequences: string }[] = [];
  public numOfAA!: number;
  public seqFormat: string = '';

  fastaParser(fastaTexts: string): void {
    this.filteredSequences = [];
    const sequenceArray = fastaTexts.split('>');
    sequenceArray.shift();
    this.numOfAA = 0;
    _.forEach(sequenceArray, (seq, i) => {
      const newObj = {
        name: getFirstLine(seq),
        sequences: getSequence(seq)
      };
      this.filteredSequences.push(newObj);
      this.numOfAA += newObj.sequences.length;
    });
    this.seqFormat = 'FASTA'
  }

  fastaValidate(fastaTexts: string): boolean {
    /**
     Validates the first 20 raw string lines
     *
     * */
    let sequenceArray = fastaTexts.split('\n').slice(0, 20);

    let invalid = false;

    // let nameRegexp = new RegExp('^[a-zA-Z0-9.\\-_>]+$')
    let alphabetRegexp = new RegExp('^[a-zA-Z ]+$')

    _.forEach(sequenceArray, (val, i): any => {
      // console.log(i)
      // console.log(val)
      // console.log(typeof val)

      if (val === ''){
        // console.log('break0')
        return false;
      }
      if (val[0] === '>' && !isASCII(val)) { // name
        // console.log('break1');
        invalid=true; return false;
      }
      else if (val[0] !== '>' && !alphabetRegexp.test(val)) {
        // console.log('break2');
        invalid=true; return false;
      }
    });
    this.seqFormat = 'FASTA'
    return !invalid;
  }

  jsonParser(jsonTexts: string): void {
    this.filteredSequences = [];
    const parseObj = JSON.parse(jsonTexts);
    this.numOfAA = 0;
    if (Array.isArray(parseObj)) {
      this.filteredSequences = parseObj;
      this.numOfAA = 1;
    } else {
      this.filteredSequences.push(parseObj);
      this.numOfAA += parseObj.sequence.length;
    }
    this.seqFormat = 'JSON'
  }

  namedWhiteSpaceParser2(inputString: string): void {
    /**
     Two columns separated by ' ' or '\t'
     *
     EX:
     "seq1 DDEDSKQNIFH
     my_favorite_seq ADPGPHLMG
     denv4.3 KAVELGVKLLHA
     this-is-a_validName2 QLQNLGINP"

     TODO: As a first pass, I think the valid characters for names should be:

     a-z (case insensitive)
     0-9
     - (hyphen)
     . (period)
     _ (underscore)

     * */
    this.filteredSequences = [];

    // console.log(inputString);
    let sequenceArray = inputString.split('\n');
    sequenceArray = _.filter(sequenceArray, (o) => o !== '');
    // console.log(sequenceArray);
    this.numOfAA = 0;
    _.forEach(sequenceArray, (line) => {
      const arr = line.includes(' ') ? line.split(' ') : line.split('\t')
      // console.log(arr);
      const newObj = {
        name: arr[0],
        sequences: arr[1]
      };
      this.filteredSequences.push(newObj);
      this.numOfAA += newObj.sequences.length;
    });
    this.seqFormat = 'Named White Space'
  }

  namedWhiteSpaceValidate(inputString: string, split: string): boolean {
    /**
     Two columns separated by ' ' or '\t'
     *
     EX:
     "seq1 DDEDSKQNIFH
     my_favorite_seq ADPGPHLMG
     denv4.3 KAVELGVKLLHA
     this-is-a_validName2 QLQNLGINP"

     TODO: As a first pass, I think the valid characters for names should be:

     a-z (case insensitive)
     0-9
     - (hyphen)
     . (period)
     _ (underscore)


     ^[\w\_\-\.]+$

     [ACDEFGHIKLMNPQRSTVWYacdefghiklmnpqrstvwy]+

     TODO: Document
     regexp1
     regexp2
      general function

     also take out this.filteredSequence

     * */
    this.filteredSequences = [];

    // console.log(inputString);

    let sequenceArray = inputString.split('\n').slice(0, 10);
    sequenceArray = _.filter(sequenceArray, (o) => o !== '');
    // console.log(sequenceArray);
    this.numOfAA = 0;
    let arr: string[] = [];
    let invalid = false;
    /**
     do
     statement
     while (condition);
     * */
    let separator = 'Space'
    let regexp1 = new RegExp('^[a-zA-Z0-9.\\-_]+$')
    let regexp2 = new RegExp('^[a-zA-Z]+$')
    _.forEach(sequenceArray, (line, i): any => {
      // console.log(i)
      // split by it's separator
      arr = line.split(split)
      // console.log(arr)
      if(
        arr.length !== 2 || !regexp1.test(arr[0]) || !regexp2.test(arr[1])
      ){console.log('break'); invalid=true; return false}

      if(line.includes(' ')){
        // arr = line.split(' ')
        separator = 'Space'
      } else if (line.includes('\t')){
        // console.log('inside tab if statement')
        // arr = line.split('\t')
        separator = 'Tab'
      } else if (line.includes('\s')){
        // arr = line.split('\s')
        separator = 'Space'
      }
      // console.log(separator)

    });
    this.seqFormat = `Named White Space (${separator})`
    // console.log(sequenceArray)
    // console.log(this.filteredSequences)
    return !invalid;

    // return this.filteredSequences.length <= 10
  }

  // namedWhiteSpaceParser(inputString: string): void {
  //   this.filteredSequences = [];
  //
  //   console.log(inputString);
  //   let sequenceArray = inputString.split('\n');
  //   sequenceArray = _.filter(sequenceArray, (o) => o !== '');
  //   this.numOfAA = 0;
  //   _.forEach(sequenceArray, (seq) => {
  //     const newObj = {
  //       name: getFirstLine(seq),
  //       sequences: getSequence(seq)
  //     };
  //     this.filteredSequences.push(newObj);
  //     this.numOfAA += newObj.sequences.length;
  //   });
  // }

  unnamedWhiteSpaceValidate(inputString: string): boolean {
    this.filteredSequences = [];

    console.log(inputString);
    let sequenceArray = [];
    if (inputString.includes(' ')) {
      sequenceArray = inputString.split(' ').slice(0, 10);
    } else {
      sequenceArray = inputString.split('\n').slice(0, 10);
    }
    sequenceArray = _.filter(sequenceArray, (o) => o !== '');
    this.numOfAA = 0;
    let arr: string[] = [];
    let invalid = false;

    let regexp = new RegExp('^[a-zA-Z\s\t]+$')
    _.forEach(sequenceArray, (line, i) : any=> {
      // console.log(i)
      // split by it's separator
      // console.log(arr)
      if(
        !regexp.test(line)
      ){console.log('break'); invalid=true; return false}
    });
    this.seqFormat = 'Un-named White Space'
    // console.log(sequenceArray)
    // console.log(this.filteredSequences)
    if(invalid) {
      return false
    }
    return this.filteredSequences.length <= 10
  }

  // unnamedWhiteSpaceParser(inputString: string): void {
  //   this.filteredSequences = [];
  //
  //   console.log(inputString);
  //   let sequenceArray = [];
  //   if (inputString.includes(' ')) {
  //     sequenceArray = inputString.split(' ');
  //   } else {
  //     sequenceArray = inputString.split('\n');
  //   }
  //   sequenceArray = _.filter(sequenceArray, (o) => o !== '');
  //   this.numOfAA = 0;
  //   _.forEach(sequenceArray, (seq) => {
  //     const newObj = {
  //       name: getFirstLine(seq),
  //       sequences: getSequence(seq)
  //     };
  //     this.filteredSequences.push(newObj);
  //     this.numOfAA += newObj.sequences.length;
  //   });
  //   this.seqFormat = 'Un-named White Space'
  // }

}



function getFirstLine(text: string) {
  let index = text.indexOf('\n');
  if (index === -1) {
    index = NaN;
  }
  return text.substring(0, index);
}

function getSequence(text: string) {
  let index = text.indexOf('\n');
  if (index === -1) {
    index = NaN;
  }
  return text.substring(index).replace(/\n/g, '');
}
