<div class="container-fluid p-2 d-flex flex-column" id="visualization-result-container">
<!--  <div class="d-flex flex-row p-2 align-self-center" role="group" aria-label="button-groups">-->
<!--    <button type="button"-->
<!--            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center">-->
<!--      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>-->
<!--        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>-->
<!--      </svg>-->
<!--      Download-->
<!--    </button>-->
<!--    <button type="button"-->
<!--            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center">-->
<!--      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-share" fill="currentColor"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M11.724 3.947l-7 3.5-.448-.894 7-3.5.448.894zm-.448 9l-7-3.5.448-.894 7 3.5-.448.894z"/>-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M13.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-11-6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>-->
<!--      </svg>-->
<!--      Share-->
<!--    </button>-->
<!--    <button type="button"-->
<!--            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"-->
<!--           class="bi bi-arrow-clockwise" viewBox="0 0 16 16">-->
<!--        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>-->
<!--        <path-->
<!--          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>-->
<!--      </svg>-->
<!--      Reset Graph-->
<!--    </button>-->
<!--  </div>-->
<div #d3force class="p-2 m-4 align-self-center border">
  <h3>Interactive Cluster Visualization</h3>
  <span>
        Visualize a selected Sub-cluster
          <select (change)="onFilterGraph($event)">
            <option value="all"> All </option>
            <option *ngFor="let cluster of uniqueClusters" [value]="cluster"> {{cluster | dashToDecimal }} </option>
          </select>
      </span>
  <div #containerForceChart></div>
</div>
</div>
