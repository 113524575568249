<ng-container [formGroup]="ogFormGroup">
  <div class="form-group bg-light p-3 ">
    <div class="d-flex flex-row">
      <!--MHC Binding-->
      <label class="justify-content-center align-self-center mr-auto p-3"> MHC-I Processing </label>
      <div class="justify-content-center align-content-center flex-fill p-3">
        <label for="predictionMethodSelect">Prediction Method</label>
        <select *ngIf="processingOptions.length" id="predictionMethodSelect" formControlName="method"
                class="form-select form-control w-100" (change)="onMethodChange($event)" [attr.disabled]="disabled ? '' : null">
          <option [value]="option['short_name']"
                  *ngFor="let option of processingOptions">{{option['display_name']}}</option>
        </select>
        <div *ngIf="!processingOptions.length" class="d-flex justify-content-center col">
          <div class="spinner-border text-primary align-self-center p-3 mb-3" role="status">
            <span class="sr-only visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="justify-content-end align-self-center align-content-center p-3">
        <button type="button" class="btn-close" aria-label="Close" (click)="onCancelClick()" [disabled]="disabled">
        </button>
      </div>
    </div>
    <!--    Basic Processing -->
    <ng-container *ngIf="processingMethod==='basic_processing'">
      <hr/>
      <div class="d-flex flex-row flex-fill p-3">
        <label class="justify-content-center align-self-center p-3"> MHC-I Binding Methods </label>
        <div class="justify-content-center align-content-center flex-fill p-3">
          <select class="form-control form-select" formControlName="mhc_binding_method" [attr.disabled]="disabled ? '' : null">
            <option [value]="option['short_name']"
                    *ngFor="let option of basicProcessingPredictionOption">{{option['display_name']}}</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row flex-fill justify-content-center p-3">
        <div class="justify-content-center align-content-center">
          <label for="proteasomeSelect">Proteasome Cleavage</label>
          <select id="proteasomeSelect"
                  class="form-control form-select" formControlName="proteasome" [attr.disabled]="disabled ? '' : null">
            <option [value]="'immuno'">immuno</option>
            <option [value]="'constitutive'">constitutive</option>
          </select>
        </div>
        <div class="justify-content-center align-content-center d-flex flex-column p-2">
          <h6 class="text-center align-self-center">Transporter associated with antigen processing</h6>
          <div class="bd-highlight m-1 align-self-center d-flex flex-row justify-content-center">
            <!--Max Precursor form group-->
            <div class="px-2 mb-3 col-sm-5">
              <div class="form-group row">
                <label for="maxPrecursorInput" class="col-sm-8 col-form-label col-form-label-sm"
                       id="max-precursor-label">Max Precursor Extension</label>
                <div class="col-sm-4">
                  <input type="text"
                         id="maxPrecursorInput"
                         formControlName="tap_precursor"
                         class="form-control form-control-sm"
                         aria-label="Small"
                         aria-describedby="inputGroup-sizing-sm"
                         [attr.disabled]="disabled ? '' : null">
                </div>
              </div>
            </div>
            <!--Alpha Factor form group-->
            <div class="px-2 mb-3 col-sm-5">
              <div class="form-group row">
                <label for="alphaFactorInput" class="col-sm-7 col-form-label col-form-label-sm"
                       id="alpha-factor-label">Alpha Factor</label>
                <div class="col-sm-5">
                  <input type="text"
                         formControlName="tap_alpha"
                         id="alphaFactorInput"
                         class="form-control form-control-sm"
                         aria-label="Small"
                         [attr.disabled]="disabled ? '' : null">
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </ng-container>
    <!--    NetCTL -->
    <ng-container *ngIf="processingMethod==='netctl'">
      <hr/>
      <div class="row p-3 align-items-end">
        <label class="col-4"> Weight on C terminal cleavage </label>
        <div class="col-8">
          <ngx-slider
            formControlName="cleavage_weight"
            [options]="options"
            ></ngx-slider>
        </div>
      </div>
      <div class="row p-3 align-items-end">
        <label class="col-4"> Weight on TAP transport efficiency </label>
        <div class="col-8">
          <ngx-slider
            formControlName="tap_weight"
            [value]="0.05"
            [options]="options"></ngx-slider>
        </div>
      </div>
      <div class="row p-3 align-items-end">
        <label class="col-4"> Threshold </label>
        <div class="col-8">
          <!--             todo - add formControlName    -->
          <ngx-slider
            formControlName='threshold'
            [value]="netCtlThreshold"
            [options]="options"></ngx-slider>
        </div>
      </div>
    </ng-container>
    <!--    Netchop -->
    <ng-container *ngIf="processingMethod==='netchop'">
      <hr/>
      <div class="row p-3 align-items-end">
        <label class="col-2"> Network Method </label>
        <div class="col-10">
          <select class="form-control form-select" formControlName="network_method" [attr.disabled]="disabled ? '' : null">
            <option [value]="'c_term'">C term 3.0</option>
            <option [value]="'20S'">20s 30</option>
          </select>
        </div>
      </div>
      <div class="row p-3 align-items-end">
        <label class="col-2"> Threshold </label>
        <div class="col-10">
          <ngx-slider
            [attr.disabled]="disabled ? '' : null"
            formControlName="threshold"
            [value]="netChopThreshold"
            [options]="options"></ngx-slider>
        </div>
      </div>
    </ng-container>
    <!--    NetCTLpan -->
    <ng-container *ngIf="processingMethod==='netctlpan'">
      <hr/>
      <div class="row p-3 align-items-end">
        <label class="col-4"> Weight on C terminal cleavage </label>
        <div class="col-8">
          <!--             todo - add formControlName    -->
          <ngx-slider
            id="weightOnCTerminalCleavage"
            formControlName="cleavage_weight"
            [value]="0.225"
            [options]="options2"></ngx-slider>
        </div>
      </div>
      <div class="row p-3 align-items-end">
        <label class="col-4"> Weight on TAP transport efficiency</label>
        <div class="col-8">
          <!--             todo - add formControlName    -->
          <ngx-slider
            formControlName="tap_weight"
            id="weightOnTAPtransport"
            [value]="0.025"
            [options]="options2"></ngx-slider>
        </div>
      </div>

      <div class="row p-3 align-items-end">
        <label class="col-4"> Percentile Rank Threshold </label>
        <div class="col-8">
          <!--             todo - add formControlName    -->
          <ngx-slider
            formControlName="epitope_threshold"
            id="thresholdForEpitopeID"
            [value]="1"
            [options]="options3"></ngx-slider>
        </div>
      </div>
    </ng-container>

  </div>
</ng-container>
