<form [formGroup]="clusterForm" (ngSubmit)="onRunMain()" class="container">
  <div class="d-flex flex-row p-2">
    <h2 class="pe-2 display-5">{{index + 1}}</h2>
    <hr class="mt-4 flex-grow-1 divider"/>
  </div>
  <div class="d-flex pb-3">
    <h4 class="p-2 tool-name" id="cluster-label">Cluster</h4>
    <div class="d-flex flex-row tool-links" role="group" aria-label="tool-links">
      <button type="button" class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center">
        <a target="_blank" routerLink="/docs/tools/cluster/index.html" class="text-dark d-flex flex-column justify-content-center align-items-center">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-earmark-text" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
            <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z"/>
            <path fill-rule="evenodd"
                  d="M5 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
          </svg>
          Docs
        </a>
      </button>
      <button type="button" class="btn btn-link">
        <a target="_blank" [href]="swaggerUrl" class="text-dark d-flex flex-column justify-content-center align-items-center">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"/>
            <path fill-rule="evenodd"
                  d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"/>
          </svg>
          API
        </a>
      </button>
      <button type="button" class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"
              routerLink="/download-all"
              [state]="{tool: 'cluster'}">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cloud-arrow-down" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
          <path fill-rule="evenodd"
                d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"/>
        </svg>
        Download
      </button>
      <button type="button" class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"
              routerLink="/citation">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chat-square-quote" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M14 1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2.5a2 2 0 0 1 1.6.8L8 14.333 9.9 11.8a2 2 0 0 1 1.6-.8H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path fill-rule="evenodd"
                d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"/>
        </svg>
        Cite
      </button>
    </div>
  </div>

  <ng-container *ngIf="index===0"> <!-- display sequence input -->
    <div class="d-flex flex-row p-2">
      <h5 class="col-3 text-left">Input Sequence(s)</h5>

      <div class="col-9">
        <!--          <div class="btn-group btn-group-sm ml-auto">-->
        <button type="button" class="btn btn-sm btn-primary" (click)="null"
                [disabled]="!submitted"
                data-toggle="dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-download" viewBox="0 0 16 16">
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
          </svg>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropdown-item">
            <label>Download as: </label>
            &nbsp;
            <div class="btn-group">
              <button
                [className]="submitted ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'"
                type="button" (click)="onDownloadJson()">
                JSON
              </button>
              <button
                [className]="submitted ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'"
                type="button" (click)="onDownloadFasta()">
                FASTA
              </button>
              <button
                [className]="submitted ? 'btn btn-outline-primary' : 'btn btn-outline-secondary'"
                type="button" (click)="onDownloadOriginal()">Original
              </button>
            </div>
          </div>
        </div>
        &nbsp;
        <input type="file"
               #uploader
               style="display: none"
               id="file"
               (change)="uploadSequenceFile($event)">

        <ng-template #uploadSequencesTipContent>
          <div>
            Upload sequence file <br> (.txt, .json, .fasta)
          </div>

        </ng-template>
        <button type="button" class="btn btn-primary btn-sm text-white" (click)="uploader.click()" [disabled]="loading"
                ngbTooltip='Clear allele(s)'
                [ngbTooltip]="uploadSequencesTipContent" placement="right">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload"
               viewBox="0 0 16 16">
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
            <path
              d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
          </svg>
        </button>
        &nbsp;
      </div>
    </div>
    <div class="d-flex flex-column p-2" appDnd (fileDropped)="onFileDropped($event)">
        <textarea class='form-control'
                  [style.border-color]="inputChange.input_sequence_text ? '#f0ad4d' : null"
                  id="sequenceFormControlTextArea"
                  rows="10"
                  formControlName="inputSequenceText"
                  [readOnly]="loading"
                  [placeholder]="'Type/paste/drag a set of peptides (max: 1,000) into this box or click \'Run\' to use the example sequence:\n >Mus Pep1\nLEQIHVLENSLVL\n>Mus Pep2\nFVEHIHVLENSLAFK\n>Mus Pep3\nGLYGREPDLSSDIKERFA\n>Mus Pep4\nEWFSILLASDKREKI\n>Mus Pep5\nDGFNTFTILKTDYDN\n>Mus Pep6\nNEKDGETFQLMGLY\n>Mus Pep7\nYGREPDLSLDIKEK\n>Mus Pep8\nLENSLVLKFHTVRDE\n>Mus Pep9\nAHLINEKDGETFQLM\n>Mus Pep10\nEEASSTGRNFNVQKINGEWHTIIL\n>Mus Pep11\nEKALVSSVRQRMKCS\n>Mus Pep12\nSRPFIFQEVIDLGGE\n>Mus Pep13\nNVEKINGEWHTIIL\n>Mus Pep14\nGIYYLNYDGFNTFTI\n>Mus Pep15\nDDVVASEALNSVWSGF\n>Mus Pep16\nGSMRVFVEHIHVLEN\n>Mus Pep17\nENIIDLTKTNRCLKA\n>Mus Pep18\nILGKLVKDYHLQFHR\n>Mus Pep19\nIGGPDDGVITPWQSSF\n>Mus Pep20\nDKETLSLEELKALLL\n>Mus Pep21\nLQSGFYSLSSLVTVP\n>Mus Pep22\nEELRRLAPITSDPTE\n>Mus Pep23\nTIFISLFLLSVCYSA\n>Rat Pep1\nASNKREKIEENGSMRVFMQH\n>Rat Pep2\nDIKEKFAKLCEAHGITRDNI\n>Rat Pep3\nEEASSTRGNLDVAKLNGDWF\n>Rat Pep4\nEENGSMRVFMQHIDVLENSL\n>Rat Pep5\nENSLGFKFRIKENGECRELY\n>Rat Pep6\nFMQHIDVLENSLGFKFRIKE\n>Rat Pep7\nFVEYDGGNTFTILKTDYDRY\n>Rat Pep8\nGDWFSIVVASNKREKIEENG\n>Rat Pep9\nGRTKDLSSDIKEKFAKLCEA\n>Rat Pep10\nKTPEDGEYFVEYDGGNTFTI\n>Rat Pep11\nLCEAHGITRDNIIDLTKTDR\n>Rat Pep12\nLINFKNGETFQLMVLYGRTK\n>Rat Pep13\nNLDVAKLNGDWFSIVVASNK\n>Rat Pep14\nRDNIIDLTKTDRCLQARG\n>Rat Pep15\nRELYLVAYKTPEDGEYFVEY\n>Rat Pep16\nRIKENGECRELYLVAYKTPE\n>Rat Pep17\nTFQLMVLYGRTKDLSSDIKE\n>Rat Pep18\nTFTILKTDYDRYVMFHLINF\n>Rat Pep19\nYDRYVMFHLINFKNGETFQL'"></textarea>

      <div class="d-flex flex-row-reverse justify-content-between p-2">
        <small *ngIf="inputSequenceText.length >= maxCharacters; else sequenceCounter" class="text-danger">
          Sequence text is too long -  {{maxCharacters | number}} characters allowed
        </small>
        <ng-template #sequenceCounter>
          <small class="text-muted">
            Format: {{seqFormat}} | {{numOfCharacters | number}} characters
          </small>
        </ng-template>
        <small *ngIf="inputChange.input_sequence_text"  class="help-block form-text text-warning">
          Parameter changed since prediction.
        </small>
      </div>
    </div>
  </ng-container>
  <!--  Prediction Parameters -->
  <div class="row p-2">
    <h5 class="col-3 text-left p-2">Prediction Parameters</h5>
    <div class="col-9">
      <button type="button" class="btn btn-sm btn-primary" (click)="onDownloadParameter()"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-download" viewBox="0 0 16 16">
          <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path
            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div class="dropdown-item">
          <button disabled class='btn btn-primary' type="button" (click)="null"
                  ngbTooltip="Download a JSON file that includes the parameters set on this page."
                  placement="left">
            Download Prediction Parameters
          </button>
        </div>
      </div>
      &nbsp;
<!--      <ng-template #uploadJsonTipContent>-->
<!--        Upload inputs as JSON-->
<!--      </ng-template>-->
<!--      <button type="button" class="btn btn-info btn-sm text-white" (click)="paramUploader.click()" [disabled]="loading"-->
<!--              datatype="html" [ngbTooltip]="uploadJsonTipContent">-->
<!--        Upload Parameters-->
<!--      </button>-->
<!--      <input type="file"-->
<!--             #paramUploader-->
<!--             style="display: none"-->
<!--             id="paramUploader"-->
<!--             (change)="uploadParameterFile($event, null)">-->
    </div>
  </div>
<!--  Sequence Identity Threshold -->
  <div class="form-group row p-2" id="minThresholdRow">
    <div class="col-sm-3 col-form-label">
      <label class="text-left">Sequence Identity Threshold</label>
    </div>
    <div class="col-sm-9">
      <div [className]="inputChange.threshold ? 'input-change-slider' : null">
      <ngx-slider formControlName="threshold"
                  (userChangeEnd)="onThresholdChange($event)"
                  [options]="thresholdSliderOptions"></ngx-slider>
        <small *ngIf="inputChange.threshold" class="help-block form-text text-warning">
          Parameter changed since prediction.
        </small>
      </div>
    </div>
    <!--    <div class="col-sm-1">-->
    <!--      <div class="form-check pt-4">-->
    <!--        <input #asIsCheckbox-->
    <!--               class="form-check-input"-->
    <!--               type="checkbox"-->
    <!--               id="asIsCheckbox"-->
    <!--               [checked]="asIs"-->
    <!--               (change)="onAsIsChecked(asIsCheckbox.checked)">-->
    <!--        <label class="form-check-label" for="asIsCheckbox">-->
    <!--          as-is-->
    <!--        </label>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div class="form-group row p-2" id="peptideLengthRow">
    <div class="col-sm-3 col-form-label">
      <label class="text-left">Peptide Length(s)</label>
    </div>
    <div class="col-sm-9">
      <div [className]="inputChange.lengths ? 'input-change-slider' : null">
      <ngx-slider
        formControlName="peptideLength"
        (userChangeEnd)="onLengthChange($event)"
        [options]="peptideLengthOptions"></ngx-slider>
        <small *ngIf="inputChange.lengths" class="help-block form-text text-warning">
          Parameter changed since prediction.
        </small>
      </div>
      </div>
  </div>

  <div class="form-group row p-2" id="methodRow">
    <div class="col-sm-3 col-form-label">
      <label class="text-left">Cluster Method</label>
    </div>
    <div class="col-sm-9">
      <select class="form-select form-control w-100" formControlName="method"
              [style.border-color]="inputChange.predictors ? '#f0ad4d' : null">
        <option value="cluster"> All the connected Peptides in a cluster</option>
        <option value="cluster-break"> Cluster-break for clear representative sequence</option>
        <option value="cliques">Fully interconnected clusters (cliques)</option>
      </select>
      <small *ngIf="inputChange.predictors" class="help-block form-text text-warning">
        Parameter changed since prediction.
      </small>
    </div>
  </div>
  <hr/>

<!--  <div class="form-check row pt-2">-->
<!--    <div class="col text-right">-->
<!--      <input class="form-check-input" type="checkbox" value="" id="emailModalShow" [checked]="showEmailModal"-->
<!--             (change)=onShowEmailModalChange()>-->
<!--      <label class="form-check-label" for="emailModalShow">-->
<!--        Notify me when prediction is complete-->
<!--      </label>-->
<!--    </div>-->
<!--  </div>-->

  <!--    Run/Reset Buttons -->
  <div class="form-group d-flex flex-row-reverse pt-2">

    <div class="p-2">
      <button type="button" class="reset-button btn btn-secondary btn-lg" [disabled]="loading"
              (click)="onReset();">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
        </svg>
      </button>
      &nbsp;
      <span *ngIf="loading; else elseBlock">
          <button type="button" class="btn btn-danger btn-lg" (click)="onCancel()"> Cancel Run </button>
        </span>
      <ng-template #elseBlock>
        <div *ngIf="index!==0; else runButtonBlock" class="btn-group">
          <div class="btn-group">
            <button type="submit" class="run-button btn btn-primary btn-lg text-white"
                    [disabled]="(index>0 && !tools[index-1].submitted) || pipelineLoading"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
              </svg>
            </button>
            <button  type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                     data-toggle="dropdown"
                     data-bs-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false"
                     [disabled]="pipelineLoading">
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="onRunAll()">Run All Above</a>
            </div>
          </div>
        </div>
        <ng-template #runButtonBlock>
          <button type="submit" class="run-button btn btn-primary btn-lg text-white"
                  [disabled]="(index>0 && !tools[index-1].submitted) || pipelineLoading"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
              <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            </svg>
          </button>
        </ng-template>

      </ng-template>
    </div>
  </div>

</form>

<div ngbAccordion>
  <!--  Error -->
  <div ngbAccordionItem="first" *ngIf="errorMessages.length" #first="ngbAccordionItem" [collapsed]="true">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton class="accordion-button justify-content-center">
        <span class="text-danger m-1">Errors ({{errorMessages.length}})</span>
<!--        <div>-->
          <svg *ngIf="first.collapsed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-circle-fill" style="color: #dc3545;" viewBox="0 0 16 16">
            <path
              d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
<!--        </div>-->
<!--        <div>-->
          <svg *ngIf="!first.collapsed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-down-circle-fill" style="color: #dc3545;" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
          </svg>
<!--        </div>-->
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <ol>
            <li *ngFor="let message of errorMessages; let i=index;">{{message}}</li>
          </ol>
        </ng-template>
      </div>
    </div>
  </div>
  <!--  Warning -->
  <div ngbAccordionItem="second" *ngIf="warningMessages.length" #second="ngbAccordionItem" [collapsed]="true">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton class="accordion-button justify-content-center">
        <span class="text-warning m-1">Warnings ({{warningMessages.length}})</span>
          <svg *ngIf="second.collapsed"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-circle-fill" style="color: #f0ad4d;" viewBox="0 0 16 16">
            <path
              d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
          <svg *ngIf="!second.collapsed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-down-circle-fill" style="color: #f0ad4d;" viewBox="0 0 16 16">
            <path
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
          </svg>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <ol>
            <li *ngFor="let message of warningMessages; let i=index;">{{message}}</li>
          </ol>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && !submitted && !errorMessages.length && !warningMessages.length" class="p-2 d-flex justify-content-center col" [style.borderStyle]="styleResultContainer">
  <label class="p-2 font-italic">
    Results will load once step is run
  </label>
</div>

<div *ngIf="loading" class="p-2 d-flex justify-content-center col" [style.borderStyle]="styleResultContainer">
  <div class="spinner-border text-primary align-self-center p-3 mb-3" role="status">
    <span class="visually-hidden sr-only" >Loading...</span>
  </div>
  <label *ngIf="!getResultsClicked" class="p-2 font-italic">
    Calculating results, please wait... Click <a href="javascript:void(0)" (click)="openEmailModal.emit()">here</a> to
    receive email.
  </label>
  <label *ngIf="getResultsClicked" class="p-2 font-italic">
    Checking job status, please wait...
  </label>
</div>


<div class="container-fluid p-2" id="table-result-container" *ngIf="!loading && submitted  && !(errorMessages.length && warningMessages.length)">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" [animation]='false' [destroyOnHide]="false" class="nav-tabs">
    <li *ngIf="peptide_table[0].length" [ngbNavItem]="'peptide_table'">
      <a ngbNavLink>Peptide Table</a>
      <ng-template ngbNavContent>
      <app-result-table [table_data]="peptide_table"
                        table_name="peptide_table"
                        [inputChange]="inputChange.table_state"
                        [order]="peptide_table_order"
                        [columns]="peptide_table_columns"
                        [tool_group]="formGroupName"
                        [index]="index"
                        [tableStateSaving]="tableStateSaving"
                        (emitFilterApplied)="onFilterApplied()"
                        (emitResetTableState)="onClearTableState()"
                        (emitSaveTableState)="onSaveTableStateMain()"></app-result-table>
      </ng-template>
    </li>
    <li [ngbNavItem]="'network_graph'">
      <a ngbNavLink>Visualization</a>
      <ng-template ngbNavContent>
          <app-d3-force [data]="links" [uniqueClusters]="uniqueClusters"></app-d3-force>
      </ng-template>
    </li>
    <li *ngIf="sequence_table[0].length" [ngbNavItem]="'sequence_table'">
      <a ngbNavLink> Sequence Table </a>
      <ng-template ngbNavContent>
        <app-result-table [table_data]="sequence_table"
                          table_name="sequence_table"
                          [order]="sequence_table_order"
                          [columns]="sequence_table_columns"
                          [tool_group]="formGroupName"></app-result-table>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>

<!--Alert Modal -->
<ng-template #alertModal let-modal4>
  <div class="modal-header">
    <h4 class="modal-title" id="alert-modal-title">Validation Error(s)</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal4.dismiss('Cross click');">
    </button>
  </div>
  <div class="modal-body">
    <app-alert-component></app-alert-component>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>

<!--Confirm Modal -->
<ng-template #confirmModal let-confirmModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Are you sure?</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="confirmModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="!saveState; else saveStateConfirmation">
      <p><strong>Re-running this step of the pipeline will delete all downstream results, including:</strong></p>
      <ol>
        <li *ngFor="let tool of tools.slice(this.index+1)"> {{tool.name}} </li>
      </ol>
      <p><strong>To access the current results, you can use this link</strong></p>
    </div>
    <ng-template #saveStateConfirmation>
      <p><strong>Applying these filters for input to the next stage will delete results from all downstream steps in
        this session, including:</strong></p>
      <ol>
        <li *ngFor="let tool of tools.slice(this.index+1)"> {{tool.name}} </li>
      </ol>
      <p><strong>To access the current results, you can use this link</strong></p>
    </ng-template>
    <!-- pipeline URL input group-->
    <div class="input-group">
      <input type="text" class="form-control" id="pipeline-url-input" [value]="pipelineUrl">
      <button class="btn btn-secondary" [cdkCopyToClipboard]="pipelineUrl" ngbTooltip="copy url" placement="top">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="bi bi-clipboard" viewBox="0 0 16 16">
          <path
            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
          <path
            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
        </svg>
      </button>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="confirmModal.dismiss('cancel click')">No
    </button>
    <button type="button" class="btn btn-danger" (click)="confirmModal.close('Ok click'); onRunConfirm();">Yes, continue
      and destroy downstream steps
    </button>
  </div>
</ng-template>
