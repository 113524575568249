import * as _ from "lodash";

export function columnListToObject(columns: any[], tool_group: string)
{
  /**
   * Creates column object that contains

   * */
  let colObj = {}

  _.forEach(columns, (col, i) => {
    const {type, value_limits} = col
    // console.log(col.filterKey)
    // console.log(value_limits)
    if(type==='text' && col.value_limits.selectedValues.length) {
      /** Text filter */
      const key = col.filterKey;
      const { selectedValues } = col.value_limits
      const searchString = selectedValues.length == 1 ? selectedValues[0] : selectedValues.join('|')

      // @ts-ignore
      colObj[key] = {
        search: {
          search: searchString
        },
        tool_group: tool_group,
        source: columns[i]['source'],
        name: columns[i]['name']
      }
    }
    //@ts-ignore
    else if ((type==='int'|| type==='float') &&
      (value_limits.minValue || value_limits.maxValue || value_limits.minValue === 0 || value_limits.maxValue === 0)) {
      /** Number filter */
        // this._stateService.applyFiltersToParams()
      const key = col.filterKey;
      // @ts-ignore
      colObj[key] = {
        search: {
          min: col.value_limits.minValue,
          max: col.value_limits.maxValue
        },
        tool_group: tool_group,
        source: columns[i]['source'],
        name: columns[i]['name']
      };
    }
  })

  return colObj
}

export function columnObjectToList(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
