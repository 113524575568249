import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlContainer, UntypedFormBuilder, FormGroup} from "@angular/forms";
import * as _ from "lodash";
// import {Options} from "@angular-slider/ngx-slider";
import {Options} from 'ngx-slider-v2'

@Component({
  selector: 'app-mhc-i-processing-formgroup-new',
  templateUrl: './mhc-i-processing-formgroup-new.component.html',
  styleUrls: ['./mhc-i-processing-formgroup-new.component.scss']
})
export class MhcIProcessingFormgroupNewComponent implements OnInit, OnChanges {

  @Input() modelIndex: number | any;
  @Input() disabled: boolean = false;
  @Input() processingOptions: { display_name: string, short_name: string, group: string, source: string, has_ic50: boolean }[] = [];
  @Input() basicProcessingPredictionOption: { display_name: string, short_name: string, group: string, source: string, has_ic50: boolean }[] = [];

  @Output() emitPredictionModelIndex = new EventEmitter<number>();
  addProcessing: boolean = false;
  public ogFormGroup: any = null;

  // Netchop
  netChopThreshold = 0.5
  netCtlThreshold = 0.75
  options: Options = {
    disabled: false,
    floor: 0,
    ceil: 1,
    step: 0.01,
    ticksArray: [0, 0.5, 1],
    showTicksValues: true,
    translate: (value: number): string => {
      return value.toFixed(2);
    }
  };

  options2: Options = {
    disabled: true,
    floor: 0,
    ceil: 1,
    step: 0.005,
    ticksArray: [0, 0.5, 1],
    showTicksValues: true,
    translate: (value: number): string => {
      return value.toFixed(3);
    }
  };

  options3: Options = {
    disabled: false,
    floor: 0,
    ceil: 100,
    step: 0.5,
    ticksArray: [0, 50, 100],
    showTicksValues: true,
    translate: (value: number): string => {
      return value.toFixed(1);
    }
  };

  constructor(
    public controlContainer: ControlContainer,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.ogFormGroup = this.controlContainer.control;
    // this.options = Object.assign({}, this.options, {disabled: this.disabled});
    // this.options2 = Object.assign({}, this.options2, {disabled: this.disabled});
    // this.options3 = Object.assign({}, this.options3, {disabled: this.disabled});

    // this.ogFormGroup.controls['method'].valueChanges.subscribe((val: any) => {
    //   console.log(val);
    // })
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']){
      const disabled = changes['disabled'].currentValue;
      console.log(`disabled=${disabled}`)
      this.options = Object.assign({}, this.options, {disabled});
      this.options2 = Object.assign({}, this.options2, {disabled});
      this.options3 = Object.assign({}, this.options3, {disabled});
    }
  }


  get has_ice50() {
    return _.find(this.processingOptions, {short_name: this.ogFormGroup.controls['method'].value})?.has_ic50
  }

  get processingMethod() {
    return this.ogFormGroup.controls['method'].value
  }

  onCancelClick() {
    this.emitPredictionModelIndex.emit(this.modelIndex);
  }

  onMethodChange(e: any) {
    /**
     * Note MHC-NP only requires 'type' and 'method'
     * */

    const value = e.target.value
    _.forOwn(this.ogFormGroup.value, (v, k) => {this.ogFormGroup.removeControl(k)}) // clear ogFormGroup

    this.ogFormGroup.setControl('type', this.fb.control('processing'))
    this.ogFormGroup.setControl('method', this.fb.control(value))

    switch (value) {
      case 'basic_processing': {
        this.ogFormGroup.setControl('mhc_binding_method', this.fb.control('netmhcpan_ba'))
        this.ogFormGroup.setControl('proteasome', this.fb.control('immuno'))
        this.ogFormGroup.setControl('tap_precursor', this.fb.control(1))
        this.ogFormGroup.setControl('tap_alpha', this.fb.control(0.2))
        break;
      }
      case 'netchop': {
        this.ogFormGroup.setControl('network_method', this.fb.control('c_term'))
        this.ogFormGroup.setControl('threshold', this.fb.control(0.7))
        break;
      }
      case 'netctl': {
        this.ogFormGroup.setControl('cleavage_weight', this.fb.control(0.15))
        this.ogFormGroup.setControl('tap_weight', this.fb.control(0.05))
        this.ogFormGroup.setControl('threshold', this.fb.control(0.75))
        break;
      }
      case 'netctlpan': {
        this.ogFormGroup.setControl('cleavage_weight', this.fb.control(0.225))
        this.ogFormGroup.setControl('tap_weight', this.fb.control(0.025))
        this.ogFormGroup.setControl('epitope_threshold', this.fb.control(1.0))
        break;
      }
      default:
        break
    }

  }
}
