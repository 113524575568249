import { Component, Input, OnInit } from '@angular/core';
// import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { CookieDialogComponent } from '../cookie-dialog/cookie-dialog.component';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input()
  public cookieComponent!: CookieDialogComponent;

  // modalOpen = false;

  constructor(
    // private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  showCookieDialog() {
    this.cookieComponent.showCookieDialog();
  }

  // open(content: any) {
  //   this.modalOpen = true;
  //   this.modalService.open(content, {
  //     ariaLabelledBy: 'modal-basic-title',
  //     size: 'lg',
  //     animation: true,
  //     backdrop: 'static'
  //   }).result.then((result) => {
  //     // this.closeResult = `Closed with: ${result}`;
  //     // this.saveState = false;
  //     this.modalOpen = false;
  //   }, (reason) => {
  //     // this.saveState = false;
  //     // this.alleleSearch.nativeElement.focus();
  //     // this.closeResult = `Dismissed ${TCellPredictionComponent.getDismissReason(reason)}`;
  //   });
  // }

}
