import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Router} from '@angular/router';

import _ from 'lodash';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

import {Observable, of} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";

import Allele from "../_global/Allele";
import {AlleleSearchService} from "../services/allele-search/allele-search.service";
import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import {
  initTCellPredictionState
} from "../services/state-services/default-stage-states";
import {environment} from "../../environments/environment";
import * as alleleData from "../services/data/27-alleles.json";
import {isASCII} from "../util/string-helpers";
import {Location} from "@angular/common";
import {isMaxLength} from "../util/is-max-length";
import {AlertServiceService} from "../services/alert-service/alert-service.service";
import {AnnouncementService} from "../services/announcement-service/announcement-service.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  swaggerUrl = `${environment['api_url']}/apidocs`

  maxCharacters = 1000000

  classI = true;
  tCellForm!: UntypedFormGroup;
  searching = false;
  searchFailed = false;

  bCellForm = new UntypedFormGroup({});

  /** Allele Variables */
  selectedAlleles: Allele[] = [];
  modalAlleles: Allele[] = [];

  // topics: any[] = []; // events
  announcements: any[] = [
    "Next-Generation IEDB Tools site released to public"
  ]; // topics

  releases:{title: string, url: string}[] = [
    {title: 'Next-Generation IEDB Tools Website and API - Release 2.0', url: 'https://help.iedb.org/hc/en-us/articles/27040672746907'},
    {title: 'Next-Generation IEDB Tools Website and API - Release 1.2', url: 'https://help.iedb.org/hc/en-us/articles/23989174535707'},
    {title: 'Next-Generation IEDB Tools Website and API - Release 1.1', url: 'https://help.iedb.org/hc/en-us/articles/19688305823515'},
    {title: 'Next-Generation IEDB Tools Website and API - Release 1.0', url: 'https://help.iedb.org/hc/en-us/articles/12422998636955'}
  ]

  tools = [
    // {queryParams: {tool: 'tc1'}, name: 'T Cell Prediction - Class I', tags: ["CD8", "bindingAffinity", "elutedLigand", "epitope", "hlaAssociation", "immunogenicity", "mhcI", "tCell", "tapProcessing"], count: 0, routerLink: 't-cell-prediction'},
    {queryParams: {tool: 'cluster'}, name: 'Cluster', tags: ["conservation", "dataVisualization", "hlaAssociation", "peptideSimilarity"], count: 0, routerLink: 'cluster'},
    {queryParams: {tool: 'pepmatch'}, name: 'PEPMatch', tags: ['conservation', 'peptideSimilarity', 'proteomeSearch'], count: 0, routerLink: 'pepmatch'},
    {queryParams: {tool: 'pepx'}, name: 'PepX', tags: ['geneExpression', 'rnaExpression', 'transcription'], count: 0, routerLink: '_blank'},
    {queryParams: {tool: 'pepvcomp'}, name: 'Peptide Variant Comparison', tags: ['bindingAffinity', 'cancer', 'CD8', 'dataVisualization', 'elutedLigand', 'epitope', 'hlaAssociation', 'immunogenicity', 'mhcI', 'neoepitope', 'tCell'], count: 0, routerLink: 'pepcomp'},
    {queryParams: {tool: 'mutpepgen'}, name: 'Mutated Peptide Generator', tags: ['cancer','neoepitope', 'VCF'], count: 0, routerLink: 'pepcomp'},
  ]

  @ViewChild('selectedAlleleModal') alleleModal!: ElementRef;
  @ViewChild('alertModal') alertModal!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: Router,
    private location: Location,
    private modalService: NgbModal,
    private _stateService: PipelineStateService,
    private _service: AlleleSearchService,
    private _announcementService: AnnouncementService,
    public alertService: AlertServiceService,
  ) { }

  ngOnInit(): void {
    this.tCellForm = this.formBuilder.group({
      sequences: '',
    });
    this.bCellForm = this.formBuilder.group({
      sequences: '',
    });

    this._stateService.setPipelineId('');
    this._stateService.setPipelineSpecId('');

    // this._announcementService.getAnnouncements().subscribe((resp: { [x: string]: any[]; }) => {
    //   console.log(resp)
    //   // this.topics = resp['topics'];
    //   // this.events = resp['topics'].filter(o=>o['tags'][0] === 'event').slice(-3)
    //   // this.announcements = resp['topics'].filter(o=>o['tags'][0] === 'announcement').slice(-3)
    // })
  }


  onFileDropped($event: any[]) {
    console.log($event);
    const file = $event[0];
    const fileSize = file.size / 1024; // in kB
    console.log(file.type);
    if (fileSize > 20000) {
      this.alertService.add('File Size is too big! (20mB max)', 'alert-danger');
      this.open(this.alertModal);
    }
    else {
      this.prepareSequenceFileList($event);
    }
  }

  prepareSequenceFileList(files: Array<any>) {
    console.log(files);
    // this.fileName = files[0].name;
    const reader = new FileReader();
    const myForm = this.tCellForm;
    for (const item of files) {
      // console.log(item);
      reader.readAsText(item);
      reader.onload = () => {
        const val = reader.result?.toString() || '';
        console.log(val);
        const v = isASCII(val);
        console.log(v);
        if (v) {
          // myForm.patchValue({inputSequenceText: val.substring(0, this.maxCharacters)});
          const w = isMaxLength(val, this.maxCharacters)
          if (w) {
            myForm.patchValue({sequences: val});
          } else {
            this.alertService.add(`Sequence is too long - max Sequence length is ${this.maxCharacters.toLocaleString("en-US")} characters`, 'alert-danger');
            this.open(this.alertModal);
          }
          // this.sequenceParser(val);  // auto parser
        } else {
          this.alertService.add('Invalid file type.\nValid file types are .txt, .fa, .fasta, .json, .faa,', 'alert-danger');
          this.open(this.alertModal);
        }
      };

      reader.onerror = () => {
        console.log(reader.error);
      };
      item.progress = 0;
    }
  }

  runTCellPrediction() {
    const initTCellPredictionTool2 = {
      name: 'T-Cell Prediction',
      toolGroup: 'mhci',
      loading: false,
      submitted: false,
      appliedFilters: undefined,
      disabled: false
    }

    sessionStorage.setItem('sequences', this.tCellForm.get('sequences')?.value);
    sessionStorage.setItem('selectedAlleles', JSON.stringify(this.selectedAlleles));

    // const source = {
    //   input_parameters: {
    //     inputSequenceText: this.tCellForm.get('sequences')?.value,
    //     alleles: this.selectedAlleles
    //   }
    // }
    // const initState = Object.assign(initTCellPredictionState, source)
    //
    // console.log(initState)
    this._stateService.setInitialStage(initTCellPredictionState)
    this._stateService.setInitialTool(initTCellPredictionTool2)
    // this._stateService.setParams(initParams)
    this._stateService.setPipelineId('')
    this._stateService.setPipelineSpecId('')
  }


  /** T Cell Prediction Functions */
  get selectedAlleleLabels() {
    return this.selectedAlleles.map((o => o.label)).join(', ');
  }

  get selectedModalAlleleLabels() {
    return this.modalAlleles.map(((o: { label: string; }) => o.label)).join(', ');
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
          console.log(term);
          return this._service.search(term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        }
      ),
      tap(() => this.searching = false)
    )

  resultFormatter = (result: any) => {
    /**
     *  The function that converts an item from the result list to a string to display in the popup.
     * */
    return result.synonyms.length > 0 ? `${result.label} - ${result.synonyms}` : result.label;
  }


  inputFormatter(value: any) {
    /**
     * The function that converts an item from the result list to a string to display in the <input> field.
     * */
    if (value.label) {
      return `${value.label}`;
    }
    return '';
  }

  /** Allele Search Functions */
  onAlleleSelect(e: any, input: any) {
    // this.inputEl.nativeElement.value = '';
    e.preventDefault();
    const selectedAllele = e.item;
    console.log(selectedAllele);
    let labels = _.map(this.selectedAlleles, (allele) => allele.label)
    if (!labels.includes(selectedAllele.label)) {
      this.selectedAlleles.push(selectedAllele);
    }
    // sessionStorage.setItem('selectedAlleles', JSON.stringify(this.selectedAlleles));
    input.value = '';
  }

  onAlleleSelectModal({e, input}: { e: any, input: any }) {
    e.preventDefault();
    const selectedAllele = e.item;
    let labels = _.map(this.modalAlleles, (allele) => allele.label)
    if (!labels.includes(selectedAllele.label)) {
      this.modalAlleles.push(selectedAllele);
    }
    input.value = '';
  }

  onBlurAlleleSelect(e: any, input: any) {
    /**
     * Displays alleles in the text box when out of focus.
     * */
    e.preventDefault();
    input.value = '';
    input.placeholder = 'Ex. HLA-A*02:01'
    if(this.selectedAlleles.length) {
      const labels = _.map(this.selectedAlleles, (o) => {
        return o.label;
      });
      input.value = labels.join(', ');
    }
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true,
      backdrop: 'static'
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
      // this.saveState = false;
      // this.modalOpen = false;
    }, (reason) => {
      // this.closeResult = `Dismissed ${HomeComponentComponent.getDismissReason(reason)}`;
    });
  }

  onOpenAlleleModal() {
    this.open(this.alleleModal)
    this.modalAlleles = [...this.selectedAlleles]
    console.log(this.modalAlleles)
  }


  dismissAlleleModal() {
    this.modalService.dismissAll()
  }


  onAlleleClear(input: any) {
    this.selectedAlleles = []
    input.value = '';
    input.placeholder = 'Ex. HLA-A*02:01'
  }

  onAlleleDeselect(selectedAllele: Allele, input: any) {
    this.selectedAlleles = _.filter(this.selectedAlleles, (a) => a.label !== selectedAllele.label);
    if(this.selectedAlleles.length) {
      const labels = _.map(this.selectedAlleles, (o) => {
        return o.label;
      });
      input.value = labels.join(', ');
    }
  }

  onAlleleDeselectModal(index: number, input: { value: string }) {
    _.pullAt(this.modalAlleles, [index])
    const labels = this.modalAlleles.map(((o: { label: string; }) => o.label)).join(', ')
    input.value = this.modalAlleles.length ? labels : ''
  }

  generate27Alleles(input: any) {
    const { alleles } = alleleData;
    this.modalAlleles = _.map(alleles.split(','), (allele) => {return{label: allele, synonym: null, score: null}})
    input.value = this.selectedAlleleLabels;
  }

  onSubmitModalAlleles() {
    this.selectedAlleles = []
    _.forEach(this.modalAlleles, (selectedAllele) => {
      this.selectedAlleles.push(selectedAllele);
    })
    this.modalService.dismissAll()
  }

  onAlleleClearModal(input: { value: string; }) {
    this.modalAlleles = []
    input.value = ''
  }
}
