import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertServiceService {

  public messages: string[] = []; // used only for conditional rendering
  public message = '';
  public colors = 'alert-danger';

  add(message: string, alertType: string) {
    this.messages.push(message);
    this.message = message;
    this.colors = alertType;
  }
  clear() {
    this.message = '';
    this.messages = [];
  }

  displayMessage() {
    if (this.messages.length > 0) {
      return true;
    }
    return null;
  }
}
