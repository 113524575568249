import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, timer, of} from "rxjs";
import {debounce, catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

const ALLELE_SEARCH_API_URL = `${environment['api_url']}/api/v1/alleles/search`;

const PARAMS = new HttpParams();

@Injectable({
  providedIn: 'root'
})
export class AlleleSearchService {

  constructor(private http: HttpClient) { }

  search(term: string) {
    if (term === '') {
      return of([]);
    }
    PARAMS.set('query', term)
    let new_search_api_url = `${ALLELE_SEARCH_API_URL}?query=${term}&method=mhci`
    console.log(new_search_api_url);
    return this.http
      .get(new_search_api_url).pipe(
        map(response => {
          // @ts-ignore
          console.log(response['alleles']);
          // @ts-ignore
          return response['alleles']
        })
      );
  }
}
