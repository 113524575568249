import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {ControlContainer, UntypedFormBuilder} from '@angular/forms'

@Component({
  selector: 'app-mhc-binding-formgroup',
  templateUrl: './mhc-binding-formgroup.component.html',
  styleUrls: ['./mhc-binding-formgroup.component.scss']
})
export class MhcBindingFormgroupComponent implements OnInit {

  @Input() modelIndex: number | any;
  @Input() disabled: boolean | any;
  @Input() predictionOption: {
    display_name: string,
    short_name: string,
    group: string,
    source: string,
    has_ic50: boolean
  }[] = [];

  @Output() emitPredictionModelIndex = new EventEmitter<number>();
  addProcessing: boolean = false;
  public ogFormGroup: any = null;

  constructor(
    public controlContainer: ControlContainer,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.ogFormGroup = this.controlContainer.control;
  }

  onCancelClick() {
    this.emitPredictionModelIndex.emit(this.modelIndex);
  }

  addProcessingFormGroup(proteasome = 'immuno', tap_precursor = 1, tap_alpha = 0.2) {
    this.ogFormGroup.addControl(
      'processing',
      this.fb.group({
        proteasome: this.fb.control(proteasome),
        tap_precursor: this.fb.control(tap_precursor),
        tap_alpha: this.fb.control(tap_alpha)
      })
    )
  }

  removeProcessingFormGroup() {
    this.ogFormGroup.removeControl('processing')
  }

  onAddProcessingChange() {
    this.addProcessing = !this.addProcessing
    this.addProcessing ? this.addProcessingFormGroup() : this.removeProcessingFormGroup()
  }
}
