import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as d3 from "d3";
import _ from 'lodash';
import {BehaviorSubject} from "rxjs";

interface IAData {
  label: string,
  value: number
}


@Component({
  selector: 'app-d3-force',
  templateUrl: './d3-force.component.html',
  styleUrls: ['./d3-force.component.scss']
})

export class D3ForceComponent implements AfterViewInit {
  @ViewChild("containerForceChart") element: ElementRef | undefined

  // initialize a private variable _data, it's a BehaviorSubject
  private _data = new BehaviorSubject<IAData[]>([]);

  // change data to use getter and setter
  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  @Input() uniqueClusters: string[] = [];

  private host: d3.Selection<d3.BaseType, {}, d3.BaseType, any> | undefined
  private svg?: d3.Selection<SVGElement, {}, d3.BaseType, any>
  private width: number = 960;
  private height: number = 600;
  private radius: number = 10;

  private htmlElement?: HTMLElement;

  private links: any[] = [];
  nodes = {}

  constructor(private http: HttpClient) { }

  ngAfterViewInit() {
    this.htmlElement = this.element?.nativeElement;
    // @ts-ignore
    this.host = d3.select(this.htmlElement);
    this.buildSVG()

    this._data
      .subscribe(data => {
        this.links = data;
        this.buildForce()
      })
  }

  private buildSVG(): void {
    this.host?.html("");

    // zoom
    const zoom: d3.ZoomBehavior<any, any> = d3.zoom()
      .scaleExtent([-1, 3])
      .on("zoom", event => {
        this.svg?.attr('transform', event.transform)
      })


    // @ts-ignore
    this.svg = this.host?.append("svg")
      .attr("width", this.width)
      .attr("height", this.height) // add zoom feature here
      .call(zoom)
      .append("g")
  }

  private buildForce(): void {
    _.forEach(this.links, (link) => {
      // @ts-ignore
      link.source = this.nodes[link.source] || (this.nodes[link.source] = {name: link.source, cluster: link.cluster});
      // @ts-ignore
      link.target = this.nodes[link.target] || (this.nodes[link.target] = {name: link.target, cluster: link.cluster});
    })

    this.nodes = {}

    _.forEach(this.links, (link) => {
      // @ts-ignore
      if( this.nodes[link.source.name] ){
        // @ts-ignore
        this.nodes[link.source.name].cluster = this.nodes[link.source.name].cluster.concat(link.cluster)
      }
      else{ // @ts-ignore
        this.nodes[link.source.name] = {
          name: link.source.name,
          cluster: link.cluster
        }
      }
      // @ts-ignore
      link.source = this.nodes[link.source.name]
      // @ts-ignore
      if( this.nodes[link.target.name] ){
        // @ts-ignore
        this.nodes[link.target.name].cluster = this.nodes[link.target.name].cluster.concat(link.cluster)
      }
      else{
        // @ts-ignore
        this.nodes[link.target.name] = {
          name: link.target.name,
          cluster: link.cluster
        }
      }
      // @ts-ignore
      link.target = this.nodes[link.target.name]
    })

    // @ts-ignore
    let simulation = d3.forceSimulation(Object.values(this.nodes))
      .force("charge", d3.forceManyBody().strength(-10))
      .force("link", d3.forceLink(this.links))
      .force('center', d3.forceCenter(this.width/2, this.height/2))
    // @ts-ignore
    const link = this.svg
      .selectAll(".link")
      .data(this.links)
      .enter().append("line")
      .style("stroke", "#6b7071")
      .attr("class", (d) => "link "+ d.cluster.join(' '));

    // mouseover
    const mouseover = (event: { currentTarget: any; }, d: any) => {
      d3.select(event.currentTarget).select("circle").transition()
        .duration(750)
        .attr("r", 8)

      d3.select(event.currentTarget).select("text").transition()
        .duration(750)
        .attr("visibility", "visible");
    }

    // mouseout
    const mouseout = (event: { currentTarget: any; }, d: any) => {
      d3.select(event.currentTarget).select("circle").transition()
        .duration(750)
        .attr("r", 4.5);

      d3.select(event.currentTarget).select("text").transition()
        .duration(750)
        .attr("visibility", "hidden");
    }
    // @ts-ignore
    const drag = simulation => {

      function dragstarted(event: { active: any; subject: { fx: any; x: any; fy: any; y: any; }; }) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event: { subject: { fx: any; fy: any; }; x: any; y: any; }) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event: { active: any; subject: { fx: null; fy: null; }; }) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      return d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    }
    // @ts-ignore
    let node = this.svg
      .selectAll(".node")
      .data(Object.values(this.nodes))
      .enter().append("g")
      .attr("class", (d: any) => "node "+ d['cluster'].join(' '))
      .on('mouseover', mouseover)
      .on('mouseout', mouseout)
      // @ts-ignore
      .call(drag(simulation))

    const circle = node.append("circle")
      .attr("r", 4.5);

    const label = node.append("text")
      .attr("dy", ".35em")
      .attr('visibility','hidden')
      .text(function(d: any) { return d['name']; });

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
      circle.attr("cx", (d:any) => d['x']).attr("cy", (d: any) => d['y']);
      label.attr("x", function(d: any) { return d['x'] + 8; }).attr("y", function(d: any) { return d['y']; });
    });
  }

  private populateForce(): void {
    /** can break down buildForce to populate Force here.*/
  }

  onFilterGraph(e: any) {
    e.preventDefault();
    const value = e.target.value;
    /* https://gitlab.lji.org/iedb/tools/djangotools/-/blob/master/cluster2/templates/cluster2/results.html#L340 */
    if (value == 'all'){
      $(".node").show();
      $(".link").show();
    }
    else{

      $(".node").hide();
      $(".link").hide();

      $("."+value).show();
    }
  }

}
