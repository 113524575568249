<div class="container" id="tools-list-component">
  <div class="row mx-auto">
    <div class="col-3 p-3 bg-light">
      <b>Tags</b>
      <div class="form-group py-1">
        <button class="btn btn-secondary" [disabled]="!selectedTags.length" (click)="deSelectAllTags($event)">
          Reset
        </button>
      </div>
      <form [formGroup]="toolsListForm">
        <div class="form-check" *ngFor="let key of initialTags | keys;">
          <input type="checkbox" class="form-check-input" [id]="key" [formControlName]="key" [value]="initialTags[key]">
          <label class="form-check-label" [for]="key">{{ key | tag }}</label>
        </div>
      </form>
    </div>

    <div class="col-9 p-3 bg-info bg-opacity-50">
      <div class="container p-3" *ngIf="selectedTools.length">
        <h3> Tools Matching Any Selected Tags: {{ selectedTools.length }}</h3>
        <h3> Tools Matching All Selected
          Tags: {{ selectedTags.length === sortedTools[0].count ? countsForSelectedTools[0].length : 0 }}</h3>
      </div>

      <div *ngFor="let tool of sortedTools;" class="container p-3">
        <hr *ngIf="horizontalDivider.includes(tool.name)"/>
        <h5 *ngIf="horizontalDivider.includes(tool.name)">
          <u>Tools Matching {{ tool.count }} Tags:</u> &nbsp;
          {{ countsForSelectedTools[horizontalDivider.indexOf(tool.name)].length }}
        </h5>

        <!--        Tool Name   -->
        <h5>
          <a *ngIf="tool.queryParams; else windowOnClick" [routerLink]="'/pipeline'" [queryParams]="tool.queryParams">
            {{ tool.name }}
          </a>
          <ng-template #windowOnClick>
            <a [href]="tool.url" target="_blank">
              {{ tool.name }}
            </a>
          </ng-template>
        </h5>
        <div>
          <span *ngFor="let tag of tool.tags"
                [style.font-weight]=" toolsListForm.controls[tag].value ? 'bold' : 'normal'"
                [className]="toolsListForm.controls[tag].value ? 'p-2 badge rounded-pill bg-success' : 'p-2 badge rounded-pill bg-secondary'">
            {{ tag | tag }}
          </span>
        </div>
      </div>

      <hr>

      <p> * - Redirect to legacy site.</p>
    </div>

  </div>
</div>
