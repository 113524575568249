<ng-container [formGroup]="ogFormGroup">
  <div class="form-group bg-light p-3 ">
    <div class="d-flex flex-row">
      <!--MHC Binding-->
      <label class="justify-content-center align-self-center mr-auto p-3"> MHC-I Binding/Elution </label>
      <div class="justify-content-center align-content-center flex-fill p-3">
        <label for="predictionMethodSelect">Prediction Method</label>
        <select *ngIf="predictionOption.length" id="predictionMethodSelect" formControlName="method"
                class="form-select form-control w-100" [attr.disabled]="disabled ? '' : null">
          <option [value]="option['short_name']"
                  *ngFor="let option of predictionOption">{{option['display_name']}}</option>
        </select>
        <div *ngIf="!predictionOption.length" class="d-flex justify-content-center col">
          <div class="spinner-border text-primary align-self-center p-3 mb-3" role="status">
            <span class="sr-only visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="justify-content-end align-self-center align-content-center p-3">
        <button type="button" class="btn-close" aria-label="Close" (click)="onCancelClick()" [disabled]="disabled">
        </button>
      </div>
    </div>
  </div>
</ng-container>
