/**
 *
 * Used for vcf file validation
 * limit should be 5000
 * */

export function isMaxLines(str: string, maxNoLines: number)
{
  return (String(str).match(/\n/g) || '').length + 1 < maxNoLines
}
