import {HttpClient, HttpHeaders} from '@angular/common/http';

export default function onCsvDownloadAllClickedNew(API_URL: string, http: HttpClient, extension = 'txt') {
  /**
   * Creates a csv file of all the query_set in the backend.
   * */
  // console.log(API_URL);
  const tableType = API_URL.split('/')[API_URL.split('/').length - 1];

  const headers = new HttpHeaders();
  headers.append('Accept', 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet');

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  function downLoadFile(data: any, type: string) {
    const blob = new Blob([data], {type: type}); // thx: http://stackoverflow.com/a/18925211
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
    // var blob = new Blob([csvData], { type: contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = `${tableType}.${extension}`;
    a.click();
  }


  const resp = http.get<any>(API_URL, {responseType: 'arraybuffer' as 'json', headers: headers,});

  resp.subscribe((response) => {
    downLoadFile(response, 'application/ms-excel');
  });
}
