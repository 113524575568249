import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UntypedFormGroup} from "@angular/forms";
import {Observable, BehaviorSubject} from 'rxjs';
import {debounce, catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import * as _ from "lodash";

const API_URL = `${environment['api_url']}/api/v1/pipeline`;
const PIPELINE_SPEC_API_URL = `${environment['api_url']}/api/v1/pipeline_spec`;
const RESULT_API_URL = `${environment['api_url']}/results`;
const PARAMS = new HttpParams();
import Allele from "../../_global/Allele";
@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  public _currentPipelineId  = new BehaviorSubject('');

  public get currentPipelineId(): Observable<string> {
    return this._currentPipelineId.asObservable();
  }
  runSetInterval: any;

  constructor(private http: HttpClient) {
  }

  postPipeline(data: object): Observable<any> {
    /**
     /api/v1/pipeline
     * */
    console.log(JSON.stringify(data, null, 2));
    return this.http.post(API_URL, data)
      .pipe(
        tap((resp: any) => {
          // console.log(resp); // debugger
        })
      )
  }

  putPipeline(pipelineId: string, data: object): Observable<any> {
    /**
     /api/v1/pipeline
     * */
    console.log(JSON.stringify(data, null, 2));
    return this.http.put(`${API_URL}/${pipelineId}`, data)
      .pipe(
        tap((resp: any) => {
          // console.log(resp); // debugger
        })
      )
  }

  getPipeline(pipelineId: string) {
    /**
     /api/v1/pipeline/{pipeline_id}
     {
  "pipeline_id": "b5bec4c9-d657-483f-9cab-5113d62c6c2e",
  "pipeline_title": "",
  "pipeline_date": "2022-01-17",
  "pipeline_spec_id": "631f3a1c-4fbd-4968-9266-b56019f0a5e4",
  "email": "",
  "title": "",
  "stages": [
    {
      "stage_id": "dd20849b-642a-4c00-9b71-8b799c6c8e57",
      "stage_url": "https://api-iedb-ar-prototype-dev.lji.org/api/v1/stage/dd20849b-642a-4c00-9b71-8b799c6c8e57",
      "stage_number": 1,
      "input_stage_number": null,
      "input_stage_id": null,
      "stage_type": "prediction",
      "tool_group": "mhci",
      "stage_messages": {},
      "stage_status": "done",
      "stage_result_id": "1642448438.4946783-crBK5D3C",
      "stage_result_uri": "https://api-iedb-ar-prototype-dev.lji.org/api/v1/results/1642448438.4946783-crBK5D3C",
      "input_data": {
        "input_parameters_id": "1642448437.0379062-7KNyAzhE",
        "input_parameters_uri": "https://api-iedb-ar-prototype-dev.lji.org/api/v1/get_object/1642448437.0379062-7KNyAzhE",
        "input_sequence_text_id": "1642448437.0429754-blM9DgMu",
        "input_sequence_text_uri": "https://api-iedb-ar-prototype-dev.lji.org/api/v1/get_object/1642448437.0429754-blM9DgMu"
      }
    }
  ]
}
     */

    return this.http.get(`${API_URL}/${pipelineId}?return_input_URIs=True`)
      .pipe(
        tap((resp: any) => {
          console.log(resp);
        })
      )
  }

  getPipelineSpec(pipelineSpecId: string) {
    /**
     {
  "pipeline_spec_id": "8acffb74-f561-433e-b1f4-082e616ef287",
  "description": "",
  "pipeline_params": {
    "stages": [
      {
        "stage_type": "prediction",
        "tool_group": "cluster",
        "table_state": {
          "columns": {}
        },
        "stage_number": 1,
        "input_datasets": [
          {}
        ],
        "input_parameters": {
          "predictors": [
            {
              "type": "cluster",
              "method": "cliques"
            }
          ],
          "cluster_pct_identity": 0.4,
          "peptide_length_range": [
            0,
            0
          ]
        }
      },
      {
        "stage_type": "prediction",
        "tool_group": "mhci",
        "table_state": {
          "columns": {}
        },
        "stage_number": 2,
        "input_datasets": [
          {
            "data_to_pipe": "peptide",
            "input_stage_number": 1
          }
        ],
        "input_parameters": {
          "alleles": "H2-Kb,H2-Db",
          "predictors": [
            {
              "type": "binding",
              "method": "netmhcpan_el"
            }
          ],
          "peptide_length_range": [
            9,
            10
          ]
        }
      }
    ],
    "api_version": "0.1.0"
  }
}
     */

    return this.http.get(`${PIPELINE_SPEC_API_URL}/${pipelineSpecId}`)
      .pipe(
        tap((resp: any) => {
          console.log(resp);
        })
      )
  }

  getResults(result_url: string) {
    console.log(result_url);
    return this.http.get<any>(result_url);
  }

  /** formgroup -> params convert functions */
  convertTCellForm (tCellForm: UntypedFormGroup) {
    /** Alleles */
    const selectedAlleles = _.map(tCellForm.get('alleles')?.value, allele => allele.label).join(',').replace(/\s/g, '')
    const break_peptides = tCellForm.get('breakPeptides')?.value;

    /** predictors */
    const predictors = tCellForm.value.predictionModels;
    /** peptide length range */
    const peptide_length_range = tCellForm.value.peptideLength;

    return {
      alleles: selectedAlleles,
      // break_peptides: !break_peptides,
      peptide_length_range: break_peptides ? null : peptide_length_range,
      predictors,
    };
  }

  convertClusterForm (clusterForm: UntypedFormGroup) {
    /** threshold */
    const threshold = clusterForm.getRawValue().threshold;
    /** minimum_len */
    let minimum_len = clusterForm.getRawValue().peptideLength[0];
    minimum_len = minimum_len === 4 ? 0 : minimum_len;
    /** maximum_len */
    let maximum_len = clusterForm.getRawValue().peptideLength[1];
    maximum_len = maximum_len === 26 ? 0 : maximum_len;
    /** method */
    let method = clusterForm.getRawValue().method;

    return {
      "cluster_pct_identity": threshold,
      "peptide_length_range": [minimum_len,maximum_len],
      "predictors": [
        {
          "type": "cluster",
          "method": method
        }
      ]
    }
  }

  convertPepmatchForm (form: UntypedFormGroup) {
    /** mismatch */
    const mismatch = form.getRawValue().mismatch;
    /** one_match */
    let best_match = form.getRawValue().bestMatch;
    /** proteome */
    let proteome = form.getRawValue().proteome;
    /** include_unmatched_peptides */
    let include_unmatched_peptides = form.getRawValue().include_unmatched_peptides;

    return {
      mismatch,
      proteome,
      best_match,
      include_unmatched_peptides
    }
  }

  convertPepxForm (form: UntypedFormGroup) {
    /** qLevel */
    const qlevel = form.getRawValue().qlevel;
    /** datasource - optional */
    let datasource = form.getRawValue().datasource;
    /** datasets */
    let dataset_id = form.getRawValue().dataset_id;

    return {
      qlevel,
      datasource,
      dataset_id
    }
  }

  convertPepcompForm(form: UntypedFormGroup) {
    /** alleles */
    const selectedAlleles = _.map(form.get('alleles')?.value, allele => allele.label).join(',').replace(/\s/g, '')
    /** predictors */
    const predictors = form.value.predictionModels;

    return {
      alleles: selectedAlleles,
      predictors,
    }
  }

  convertMutgenForm(form: UntypedFormGroup) {
    /** peptideLength */
    const peptide_length = form.getRawValue().peptideLength;
    /** peptideMutationPosition1 */
    let peptide_mutation_position1 = form.getRawValue().peptideMutationPosition1;
    /** peptideMutationPosition1 */
    let peptide_mutation_position2 = form.getRawValue().peptideMutationPosition2;
    /** frameshiftOverlap */
    let frameshift_overlap = form.getRawValue().frameshiftOverlap;
    // /** maximum_peptide_length */
    let maximum_peptide_length = form.getRawValue().maximumPeptideLength;
    /** min_len_near_start_stop */
    let min_len_near_start_stop = form.getRawValue().minLengthNearStartStop;
    return {
      peptide_length,
      peptide_mutation_position1,
      peptide_mutation_position2,
      frameshift_overlap,
      maximum_peptide_length,
      min_len_near_start_stop: 16,
      "reference_genome": "GRCh38",
    }
  }
}
