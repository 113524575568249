<ng-container [formGroup]="ogFormGroup">
  <div class="form-group bg-light p-3 d-flex flex-row">
    <label class="justify-content-center align-self-center p-3">
      Class I pMHC Immunogenicity
    </label>
    <div class="justify-content-center align-self-stretch align-content-center flex-fill p-3">

      <label for="position-input">Positions To Mask</label>
      <!--      Select -->
      <select id="position-input" formControlName="mask_choice" class="form-select form-control w-100"
              [attr.disabled]="disabled ? '' : null">
        <option [value]="'default'">1,2,C Terminal (Default)</option>
        <option [value]="'by_allele'">Allele Specific</option>
        <option [value]="'custom'">Custom</option>
      </select>
      <input id="custom-position" class="form-control" formControlName="position_to_mask" type="text"
             *ngIf="mask_choice === 'custom'">
    </div>
    <!--    Cancel Button -->
    <div class="justify-content-end align-self-center align-content-center p-3">
      <button type="button" class="btn-close" aria-label="Close" (click)="onCancelClick()" [disabled]="disabled">
      </button>
    </div>
  </div>
</ng-container>
