import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer} from '@angular/forms'

@Component({
  selector: 'app-pmhc-immunogenicity-formgroup',
  templateUrl: './pmhc-immunogenicity-formgroup.component.html',
  styleUrls: ['./pmhc-immunogenicity-formgroup.component.scss']
})
export class PmhcImmunogenicityFormgroupComponent implements OnInit {
  @Input() modelIndex: number | any;
  @Input() disabled: boolean | any;

  @Output() emitPredictionModelIndex = new EventEmitter<number>();
  public ogFormGroup: any = null;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    this.ogFormGroup = this.controlContainer.control;
  }

  get mask_choice() {
    return this.ogFormGroup.controls['mask_choice'].value
  }

  onCancelClick() {
    this.emitPredictionModelIndex.emit(this.modelIndex);
  }

}
