import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tool-contribution',
  templateUrl: './tool-contribution.component.html',
  styleUrls: ['./tool-contribution.component.scss']
})
export class ToolContributionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
