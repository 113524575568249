import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';

import {DataTablesModule} from "angular-datatables";
import { NgxSliderModule } from 'ngx-slider-v2';
import {
  NgbTypeaheadModule,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbPopoverModule,
  NgbNavModule,
  NgbAlertModule,
  NgbToastModule, NgbModule
} from '@ng-bootstrap/ng-bootstrap';

// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

// import {environment} from "../environments/environment";

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AlertComponentComponent} from './alert-component/alert-component.component';
import {HomeComponent} from './home/home.component';
import {FooterComponent} from './footer/footer.component';
import { CookieDialogComponent } from './cookie-dialog/cookie-dialog.component';
import {NavbarComponent} from "./navbar/navbar.component";
import {DndDirectiveDirective} from './directives/dnd-directive.directive';
// import {PlaygroundComponent} from './playground/playground.component';
import {TCellPredictionComponent} from './mhci/t-cell-prediction/t-cell-prediction.component';

import { BenchmarkComponent } from './benchmark/benchmark.component';
import { CitationComponent } from './citation/citation.component';
import { ToolContributionComponent } from './tool-contribution/tool-contribution.component';
import { DownloadAllComponent } from './download-all/download-all.component';
import { MhcBindingFormgroupComponent } from './mhci/prediction-model-formgroups/mhc-binding-formgroup/mhc-binding-formgroup.component';
import { PmhcImmunogenicityFormgroupComponent } from './mhci/prediction-model-formgroups/pmhc-immunogenicity-formgroup/pmhc-immunogenicity-formgroup.component';
import { MhcIProcessingFormgroupNewComponent } from './mhci/prediction-model-formgroups/mhc-i-processing-formgroup-new/mhc-i-processing-formgroup-new.component';

// import { MhcNpFormgroupComponent } from './mhci/prediction-model-formgroups/mhc-np-formgroup/mhc-np-formgroup.component';
import { ResultTableComponent } from './result-table/result-table.component';
import { ClusterComponent } from './cluster/cluster.component';
import {ToolsListComponent} from './tools-list/tools-list.component';
import { SequenceInputComponent } from './sequence-input/sequence-input.component';
import { ParameterTableComponent } from './parameter-table/parameter-table.component';
import { D3ForceComponent } from './d3-force/d3-force.component';
import { KeysPipe } from "./_pipes/keys.pipe";
import { tagPipe } from "./_pipes/tag.pipe";
import { DashToDecimalPipe } from "./_pipes/dash-to-decimal.pipe";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from "@angular/material/icon";
import {PipelineNewComponent} from "./pipeline-new/pipeline-new.component";
import { PepmatchComponent } from './pepmatch/pepmatch.component';
import { VersionComponent } from './version/version.component';
import { ResultTableAngularComponent } from './result-table-angular/result-table-angular.component';
import { PipelineErrorComponent } from './pipeline-error/pipeline-error.component';
import { PepxComponent } from './pepx/pepx.component';
import { PeptideVariantComparisonComponent } from './mhci/peptide-variant-comparison/peptide-variant-comparison.component';
import { D3PlotComponent } from './d3-plot/d3-plot.component';
import { MutatedPeptideGeneratorComponent } from './mutated-peptide-generator/mutated-peptide-generator.component';
@NgModule({
  declarations: [
    AppComponent,
    AlertComponentComponent,
    FooterComponent,
    CookieDialogComponent,
    HomeComponent,
    NavbarComponent,
    DndDirectiveDirective,
    TCellPredictionComponent,
    BenchmarkComponent,
    CitationComponent,
    ToolContributionComponent,
    ToolsListComponent,
    DownloadAllComponent,
    MhcBindingFormgroupComponent,
    // MhcNpFormgroupComponent,
    PmhcImmunogenicityFormgroupComponent,
    ResultTableComponent,
    ClusterComponent,
    SequenceInputComponent,
    ParameterTableComponent,
    D3ForceComponent,
    KeysPipe,
    tagPipe,
    DashToDecimalPipe,
    PipelineNewComponent,
    MhcIProcessingFormgroupNewComponent,
    PepmatchComponent,
    VersionComponent,
    ResultTableAngularComponent,
    PipelineErrorComponent,
    PepxComponent,
    PeptideVariantComparisonComponent,
    D3PlotComponent,
    MutatedPeptideGeneratorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // ...environment.optionalModules,
    AppRoutingModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbAlertModule,
    NgbTypeaheadModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
    NgbToastModule,
    NgxSliderModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatMenuModule,
    MatChipsModule,
    MatIconModule,
    ClipboardModule,
    NgbModule
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
