import { Component } from '@angular/core';
import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-pipeline-error',
  templateUrl: './pipeline-error.component.html',
  styleUrls: ['./pipeline-error.component.scss']
})
export class PipelineErrorComponent {

  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  pipelineId = '';

  errorMessage = ''

  unsubscribe$ = new Subject();

  constructor(
    private _stateService: PipelineStateService,
  ) {
  }

  ngOnInit(): void {
    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`pipelineId=${value}`);
      this.pipelineId = value;
      this.errorMessage = `Pipeline ${this.pipelineId} does not exist`
    })

  }

}
