
<div class="container p-3" id="error-component">
  <div class="alert alert-danger" role="alert">
    <p>
    {{errorMessage}}
    </p>
    <a routerLink="/home">
      Home
    </a>
  </div>
</div>
