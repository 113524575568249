<div class="container">
  <div class="d-flex flex-column">
    <h3 class="p-3 text-info"> Benchmarks</h3>

    <div class="p-3 mb-2 bg-white">
      <select class="m-3 form-control form-control-lg text-info">
        <option>T Cell - Class I</option>
        <option>B Cell - Class I</option>
        <option>Epitope Clustering and Homology</option>
        <option>MHC Allele Frequency</option>
      </select>

      <table class="table table-bordered m-3">
        <thead>
        <tr>
          <th scope="col">Server</th>
          <th scope="col">2021-03-05</th>
          <th scope="col">2021-02-19</th>
          <th scope="col">2021-01-29</th>
          <th scope="col">2021-01-15</th>
          <th scope="col">2021-01-01</th>
          <th scope="col">2020-12-25</th>
          <th scope="col">2020-12-18</th>
          <th scope="col">2020-12-11</th>
          <th scope="col">2020-11-13</th>
          <th scope="col">2020-10-02</th>
          <th scope="col">2020-09-11</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">
            <a routerLink="/">ARB</a>
          </th>
          <td>78</td>
          <td>63</td>
          <td>62</td>
          <td>37</td>
          <td>38</td>
          <td>68</td>
          <td>51</td>
          <td>36</td>
          <td>50</td>
          <td>45</td>
          <td>51</td>
        </tr>
        <tr>
          <th scope="row">
            <a routerLink="/">SMMPMBEC</a>
          </th>
          <td>74</td>
          <td>62</td>
          <td>63</td>
          <td>41</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>
        <tr>
          <th scope="row">
            <a routerLink="/">NetMHCSPan 4.0</a>
          </th>
          <td>74</td>
          <td>62</td>
          <td>63</td>
          <td>41</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>
        <tr>
          <th scope="row">
            <a routerLink="/">NetMHCcons</a>
          </th>
          <td>74</td>
          <td>62</td>
          <td>63</td>
          <td>41</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>
        <tr>
          <th scope="row">
            <a routerLink="/">DeepSeqPa</a>
          </th>
          <td>74</td>
          <td>62</td>
          <td>63</td>
          <td>41</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">NetMHCpan 2.8</a>
          </th>
          <td>74</td>
          <td>62</td>
          <td>63</td>
          <td>41</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">SMM</a>
          </th>
          <td>56</td>
          <td>55</td>
          <td>61</td>
          <td>62</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">ANN 4.0</a>
          </th>
          <td>56</td>
          <td>55</td>
          <td>61</td>
          <td>62</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">mhcflurry 1.2.0</a>
          </th>
          <td>43</td>
          <td>44</td>
          <td>44</td>
          <td>70</td>
          <td>31</td>
          <td>63</td>
          <td>80</td>
          <td>68</td>
          <td>65</td>
          <td>45</td>
          <td>51</td>
        </tr>
        <tr>
          <th scope="row">
            <a routerLink="/">Pickpocket</a>
          </th>
          <td>25</td>
          <td>23</td>
          <td>35</td>
          <td>23</td>
          <td>31</td>
          <td>63</td>
          <td>30</td>
          <td>18</td>
          <td>57</td>
          <td>54</td>
          <td>41</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">NetMHCpan 3.0</a>
          </th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>66</td>
          <td>65</td>
          <td>61</td>
        </tr>

        <tr>
          <th scope="row">
            <a routerLink="/">Ann 3.4</a>
          </th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>66</td>
          <td>66</td>
          <td>66</td>
        </tr>

        </tbody>
      </table>
    </div>

  </div>
</div>
