import Stage from "../../_global/Stage";
import Tool from "../../_global/Tool";

export const initTCellPredictionState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'mhci',
  stage_display_name: 'T Cell Prediction - Class I',
  input_parameters: {},
  // table_state: {columns: {}},
  table_state: [{table: 'peptide_table', columns: {}}],
  stage_messages: {errors: [], warnings: []}
}

export const initClusterState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'cluster',
  stage_display_name: 'Cluster',
  input_parameters: {},
  // table_state: {columns: {}},
  table_state: [{table: 'peptide_table', columns: {}}],
  stage_messages: {errors: [], warnings: []}
}

export const initPepMatchState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'pepmatch',
  stage_display_name: 'Pepmatch',
  input_parameters: {},
  // table_state: {columns: {}},
  table_state: [{table: 'peptide_table', columns: {}}],
  stage_messages: {errors: [], warnings: []}
}

export const initPepXState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'pepx',
  stage_display_name: 'PepX',
  input_parameters: {},
  // table_state: {columns: {}},
  table_state: [{table: 'peptide_table', columns: {}}, {table: 'peptide_gene_summary_table', columns: {}}],
  stage_messages: {errors: [], warnings: []}
}

export const initPeptideBindingComparisonState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'peptide_variant_comparison',
  stage_display_name: 'Peptide Variant Comparison',
  input_parameters: {},
  table_state: [{table: 'peptide_table', columns: {}}],
  // table_state: {columns: {}},
  stage_messages: {errors: [], warnings: []}
}

export const initMutatedPeptideGeneratorState: Stage = {
  stage_number: 1,
  stage_type: 'prediction',
  tool_group: 'mutgen',
  stage_display_name: 'Mutated Peptide Generator',
  input_parameters: {},
  table_state: [{table: 'unique_peptide', columns: {}}, {table: 'peptide', columns: {}}],
  stage_messages: {errors: [], warnings: []}
}

/////////////

export const initTCellPredictionTool: Tool = {
  name: 'T-Cell Prediction',
  toolGroup: 'mhci',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

export const initClusterTool: Tool = {
  name: 'Cluster',
  toolGroup: 'cluster',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

export const initPepmatchTool: Tool = {
  name: 'Pepmatch',
  toolGroup: 'pepmatch',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

export const initPepxTool: Tool = {
  name: 'Pepx',
  toolGroup: 'pepx',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

export const initPeptideBindingComparisonTool: Tool = {
  name: 'Peptide Variant Comparison',
  toolGroup: 'peptide_variant_comparison',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

export const initMutatedPeptideGeneratorTool: Tool = {
  name: 'Mutated Peptide Generator',
  toolGroup: 'mutgen',
  loading: false,
  submitted: false,
  appliedFilters: undefined,
  disabled: false
}

/////////// Params //////////

export const initParams = {
  "pipeline_id": '',
  "run_stage_range": [1, 1],
  "stages": []
}
