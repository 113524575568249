import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import Citation from "../_global/Citation";
import {environment} from "../../environments/environment";
import * as _ from "lodash";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  loading: boolean = false;
  // citations: any[] = [];
  // uniq: string[] = []
  // groupedCitations: {"T cell - class I": Citation[]} = {"T cell - class I": []}

  versionInfo: string = ''
  versionArr: string[] = []


  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getVersionInfo()
  }

  getVersionInfo() {
    this.loading = true;
    const CITATIONS_API_URL = `${environment['api_url']}/api/v1/citations`;
    const URL = 'assets/_version/versioninfo.yml'
    this.http.get(URL, {responseType: 'text'}).subscribe(value => {
      console.log(value)
      this.versionInfo = value;
      this.versionArr = value.split('\n')
      // this.citations = value.data;
      // // this.citations =  _.orderBy(value.data, ['grouping_title', 'key']) /** uncomment later */
      // this.uniq = []
      // _.forEach(this.citations, (c) => {
      //   if (!this.uniq.includes(c.grouping_title)) {
      //     this.uniq.push(c.grouping_title)
      //     console.log(c.grouping_title)
      //     // @ts-ignore
      //     this.groupedCitations[c.grouping_title] = [c]
      //   } else {
      //     // @ts-ignore
      //     this.groupedCitations[c.grouping_title].push(c)
      //   }
      // })
      // console.log(this.uniq)
      // console.log(this.groupedCitations)
      this.loading = false;
    })
  }

}
