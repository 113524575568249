<div class="container" id="download-all-component">
  <div class="d-flex flex-column">
    <h3 class="py-3 text-info"> IEDB TOOLS WEBSITE USAGE</h3>
    <div class="p-3 mb-2 bg-info bg-opacity-50">
<!--      <h5 class="text-primary">Complete Download: Entire IEDB Analysis Resource</h5>-->
      <p>
        Data and tools within the IEDB are presented as a public resource and are free to access for all users, including commercial users.
      </p>
    </div>

    <h3 class="py-3 text-info"> IEDB TOOLS DOWNLOAD</h3>
    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <h5 class="text-primary">Complete Download: Entire IEDB Analysis Resource</h5>
      <p>
        For users that would like to run the entire analysis resource locally, a virtual machine image file is available
        with a paid commercial license. The image is kept in sync with the current version of the IEDB Analysis Resource
        and is updated on a six month cycle. Please <a href="mailto: license@iedb.org">contact us</a> for details on
        licensing options.
      </p>
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <h5 class="text-primary">Standalone Download</h5>
      <p>
        Several of the tools hosted on the IEDB NG Tools website are available as command-line tools, freely available
        to academic users through an open source license. For complete list of all versions of standalone tools used on
        the Legacy or NG Tools websites, click <a href="https://downloads.iedb.org/tools/" target="_blank">here</a>.
      </p>
      <!--      <ul>
              <li>
                <a href="mailto: help@iedb.org">Email us</a> if you have questions
              </li>
              <li>
                For complete list of all versions of standalone tools, click <a href="https://downloads.iedb.org/tools/"
                                                                                target="_blank">here</a>.
              </li>
            </ul>-->
    </div>

    <div class="py-3 mb-2 bg-white">
      <select class="mb-3 form-select form-control-lg text-info" [(ngModel)]="selectedTool">
<!--        <option value="t-cell-prediction-class-i">-->
<!--          T Cell Prediction - Class I-->
<!--        </option>-->
        <option value="mhc-class-i-binding">
          T Cell Prediction - Class I
        </option>
<!--        <option value="immunogenicity">-->
<!--          Immunogenicity-->
<!--        </option>-->
<!--        <option value="netchop">-->
<!--          NetChop-->
<!--        </option>-->
        <option value="pepmatch">
          Pepmatch
        </option>
        <option value="cluster">
          Cluster
        </option>
        <option value="pepx">
          PepX
        </option>
        <option value="pepcomp">
          Peptide Binding Comparison
        </option>
        <option value="mutgen">
          Mutated Peptide Generator
        </option>
      </select>

      <h5 class="text-primary">Description </h5>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='mhc-class-i-binding'">
        This tool wraps up several methods to support the prediction of class I peptide: MHC binding, elution, immunogenicity, and antigen processing
      </p>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='cluster'">
        This tool groups epitopes into clusters based on sequence identity using one of 3 different algorithms
      </p>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='pepmatch'">
        The PepMatch standalone includes all functionality to scan a list of peptides for closely related sequences in
        custom proteome databases using an ultra-efficient, deterministic algorithm.
      </p>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='pepx'">
        The Peptide eXpression annotator (pepX) takes a peptide as input,
        identifies from which proteins the peptide can be derived, and returns
        an estimate of the expression level of those source proteins from selected
        public databases.
      </p>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='pepcomp'">
        This package is a wrapper around T Cell Class I tool and will run predictors
        from that tool (binding, elution, immunogenicity) on a set of paired peptides
        and compare the results.
      </p>
      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='mutgen'">
        The Mutant Peptide Generator tool will take a two-sample, SNPEff-annotated VCF as
        input and generate predicted neo-peptides and the reference / WT peptides
        with which they pair.
      </p>

<!-- TODO - need to put if statements for cedar tools - waiting on J for descriptions -->
<!--      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='cluster'">-->
<!--        This tool groups epitopes into clusters based on sequence identity using one of 3 different algorithms-->
<!--      </p>-->
<!--      <p class="bg-secondary bg-opacity-50 p-3" *ngIf="selectedTool==='cluster'">-->
<!--        This tool groups epitopes into clusters based on sequence identity using one of 3 different algorithms-->
<!--      </p>-->

      <h5 class="text-primary">License Agreements </h5>
      <p class="bg-secondary bg-opacity-50 p-3">
        All standalone tools are distributed under the <a href="javascript:void(0);" (click)="downloadNonProfit()">Non-Profit Open Software License (Non-Profit OSL) version 3.0.</a>
        Some tools, developed in coordination with the Technical University of Denmark, are distributed with <a href="javascript:void(0);" (click)="downloadDTU()"> additional
        terms and conditions</a>. By downloading the tools, you are implicitly agreeing to the terms of all applicable
        licenses.
      </p>
      <!--      <p>-->
      <!--        For batch processing needs, both tools are available as standalone scripts. The command line tool is kept in-->
      <!--        sync with web tools. Both produce the same results.-->
      <!--      </p>-->

      <a type="button" class="btn btn-secondary btn-lg" (click)="onAgreeAndDownload()">
        Agree and Download
      </a>
    </div>

  </div>
</div>
