<div class="container-fluid p-2 d-flex flex-column" id="visualization-result-container">
  <div class="p-2 m-4 align-self-center border">
      <span>
        Metric
          <select (change)="onSeriesPlotChange($event)" [(ngModel)]="selectedSeries">
            <option *ngFor="let s of series" [value]="s"> {{ s }} </option>
          </select>
      </span>
    <span>
        Allele
          <select (change)="onAllelePlotChange($event)" [(ngModel)]="selectedAllele">
            <option *ngFor="let allele of uniqueAlleles" [value]="allele"> {{ allele }} </option>
          </select>
      </span>
  </div>
  <div class="p-2 m-4 align-self-center border">
    <div #containerScatterPlot></div>
  </div>
</div>
