<div class="container-fluid fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light bg-primary" style="height: 81px">
    <div class="container">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto">
          <li>
            <a class="navbar-brand ms-3" routerLink="/">
              <img [src]="logoImage" width="274" height="81" alt="iedb-logo" id="logo-image"
                   class="d-inline-block align-text-top px-2">
            </a>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" id="toolsListNavbarDropdownMenuLink"
               data-bs-toggle="dropdown"
               data-toggle="dropdown" aria-expanded="false"
               aria-haspopup="true">
              Tools
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'tc1'}">T Cell Prediction -
                Class I </a>
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'cluster'}">Cluster </a>
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepmatch'}">PEPMatch </a>
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepx'}">PepX
                <img src="assets/symbols/cedar/symbol.svg" width="16" height="16" alt="cedar-symbol.svg">
              </a>
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepvcomp'}">Peptide Variant
                Comparison
                <img src="assets/symbols/cedar/symbol.svg" width="16" height="16" alt="cedar-symbol.svg">
              </a>
              <a class="dropdown-item" [routerLink]="'/pipeline'" [queryParams]="{tool: 'mutpepgen'}">Mutated Peptide
                Generator
                <img src="assets/symbols/cedar/symbol.svg" width="16" height="16" alt="cedar-symbol.svg">
              </a>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/all-tools-list">All Tools List</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink"
               data-bs-toggle="dropdown"
               data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              Help & Info
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <!-- Link to readthedocs-style (e.g. sphinx)  documentation -->
              <a class="dropdown-item" routerLink="/docs" target="_blank">Tools Documentation</a>
              <a class="dropdown-item" href="https://iedb.zendesk.com " target="_blank">Support</a>
              <!--              Links to Discourse where we have user forum and ticketing -->
              <a class="dropdown-item" routerLink="/citation">Cite Us</a>
              <a class="dropdown-item" routerLink="/download-all">Licensing</a>
              <a class="dropdown-item" routerLink="/contributing-tools">Contribute a Tool</a>
            </div>
          </li>
        </ul>

      </div>
    </div>
  </nav>

</div>
