import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Title } from '@angular/platform-browser';

import Stage from "../_global/Stage";
import {Observable, Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {PipelineService} from "../services/pipeline-service/pipeline.service";
import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import Allele from "../_global/Allele";
import _ from 'lodash';

import * as pipelineData from "../services/data/pipeline-example.json";
import * as clusterData from "../services/data/cluster-sample.json";
import * as tCellData from "../services/data/t-cell-sample.json";
import * as pepmatchiData from "../services/data/pepmatch-mismatch-sample.json";
import * as pepxData from "../services/data/pepx-sample.json";
import * as peptideBindingComparisonData from "../services/data/pepcomp-sample.json";


import {environment} from "../../environments/environment";
import Tool from "../_global/Tool";
import PipelineParams from "../_global/PipelineParams";
import {Location} from "@angular/common";
import {GoogleAnalyticsService} from "ngx-google-analytics";

import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
// import * as sarsSampleData from "../services/data/t-cell-sample.json";

import {
  initClusterState,
  initParams,
  initPepMatchState,
  initTCellPredictionState,
  initPepXState,
  initPeptideBindingComparisonState,
  initMutatedPeptideGeneratorState,
} from "../services/state-services/default-stage-states";

@Component({
  selector: 'app-pipeline-new',
  templateUrl: './pipeline-new.component.html',
  styleUrls: ['./pipeline-new.component.scss']
})
export class PipelineNewComponent implements OnInit, OnDestroy {

  @ViewChild('alertModal') alertModal!: ElementRef;
  @ViewChild('emailModal') emailModal!: ElementRef;

  runTCellSubject: Subject<void> = new Subject<void>();
  runClusterSubject: Subject<void> = new Subject<void>();
  runPepmatchSubject: Subject<void> = new Subject<void>();
  runPepxSubject: Subject<void> = new Subject<void>();
  runPepcompSubject: Subject<void> = new Subject<void>();
  runMutgenSubject: Subject<void> = new Subject<void>();

  runAllTCellSubject: Subject<void> = new Subject<void>();
  runAllClusterSubject: Subject<void> = new Subject<void>();
  runAllPepmatchSubject: Subject<void> = new Subject<void>();
  runAllPepxSubject: Subject<void> = new Subject<void>();
  runAllPepcompSubject: Subject<void> = new Subject<void>();
  runAllMutgenSubject: Subject<void> = new Subject<void>();

  sidebarOpened = true;

  // state
  stages: Stage[] = []

  tools: Tool[] = []
  params!: PipelineParams
  paramsString: string = ''
  pipelineId: string = ''
  pipelineUrl: string = ''
  pipelineSpecUrl: string = ''
  maxCharacters = 1000000
  maxVcfLines = 5000

  stages$: Observable<Stage[]> = this._stateService.stages$;
  tools$: Observable<Tool[]> = this._stateService.tools$;
  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  pipelineSpecId$: Observable<string> = this._stateService.pipelineSpecId$;
  params$: Observable<PipelineParams> = this._stateService.params$;

  /**
   {
  "mhci": [
    {
      "valid_next_step": "cluster",
      "columns_to_pipe": [
        "peptide"
      ]
    },
    {
      "valid_next_step": "pepmatch",
      "columns_to_pipe": [
        "peptide"
      ]
    }
  ],
  "cluster": [
    {
      "valid_next_step": "mhci",
      "columns_to_pipe": [
        "peptide",
        "cluster_consensus"
      ]
    },
    {
      "valid_next_step": "pepmatch",
      "columns_to_pipe": [
        "peptide",
        "cluster_consensus"
      ]
    }
  ],
  "pepmatch": [
    {
      "valid_next_step": "mhci",
      "columns_to_pipe": [
        "peptide"
      ]
    },
    {
      "valid_next_step": "cluster",
      "columns_to_pipe": [
        "peptide"
      ]
    }
  ]
}
   * */
  possibleInputsMHCI = [
    {stage_type: 'prediction', tool_group: 'cluster', name: 'Cluster', enabled: true},
  ]
  possibleInputsCluster = [
    {stage_type: 'prediction', tool_group: 'mhci', name: 'T-Cell Prediction', enabled: true},
  ]

  validSteps: object = {};

  rootForm!: UntypedFormGroup;

  emailForm!: UntypedFormGroup;
  submittedEmailForm: { name: string, email: string } = {name: '', email: ''};

  inputSequenceText = '';

  unsubscribe$ = new Subject();

  showEmailModal: boolean = false;

  // get pipeline
  loading: boolean = false
  errorMessages: string[] = []
  warningMessages: string[] = []

  pipelineLoading = false;


  constructor(
    private _pipelineService: PipelineService,
    private _stateService: PipelineStateService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router, private activatedRoute: ActivatedRoute, private location: Location,
    private http: HttpClient,
    private titleService: Title,
    protected $gaService: GoogleAnalyticsService
  ) {
    // console.log(this.router.getCurrentNavigation()!.extras.state);
  }

  ngOnInit(): void {
    // this.$gaService.pageView('/pipeline-new', 'Teste de Title')


    this.activatedRoute.params.subscribe((params: Params) => {
      console.log(params)
      const id = params['id'];
      if (id) {
        this.loading = true;
        this._pipelineService.getPipeline(id).subscribe({
          next: (resp) => {
            /**
             if pipeline doesn't exist, navigate back to /pipeline

             {
             "errors": [
             "pipeline: aaaa4968-744f-49e9-be96-95d49a does not exist"
             ]
             }
             */
            if (resp.errors) {
              this.router.navigate(['/error'])
            }

            console.log(JSON.stringify(resp, null, 2));

            this._stateService.setPipelineId(id);
            this._stateService.setPipelineIdToParams(id);
            this._stateService.setPipelineSpecId(resp.pipeline_spec_id);

            /** set emailForm and submittedEmailForm */
            // const emailValue = {
            //   email: resp.email,
            //   name: resp.pipeline_title
            // }
            // this.emailForm.setValue(emailValue)
            // this.submittedEmailForm = emailValue

            /** stages */
            const stages = resp.stages;
            this.initPipeline(stages)
            this.titleService.setTitle(`${stages[0].stage_display_name}`)
          },
          error: (err) => {
            console.error(err)
          }
        })
      }

      const specId = params['pipelineSpecId']
      if (specId) {
        console.log(specId)
        this.loading = true;
        this._pipelineService.getPipelineSpec(specId).subscribe((resp) => {

          console.log(JSON.stringify(resp, null, 2));

          /** stages */
          const stages = resp.pipeline_params.stages;
          this.initPipeline(stages)

          this.titleService.setTitle(`${stages[0].stage_display_name}`);
        })

        this.location.replaceState('pipeline');

      }

      this.fetchValidSteps();
      this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        console.log(value);
        this.stages = value;
      })
      this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        console.log(value);
        this.tools = value;
        !id && !specId && value.length === 1 && !value[0].submitted && !value[0].loading && this.setInitialForm(value[0]);

        this.pipelineLoading = _.some(this.tools, {loading: true})
      })
      this.params$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        console.log(value);
        this.params = value;
        this.paramsString = JSON.stringify(value, null, 2)
      })

      this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        console.log(`pipelineId=${value}`);
        this.pipelineId = value;
        this.pipelineUrl = `${window.location.origin}/pipeline/${value}`;
      })

      this.pipelineSpecId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        console.log(`pipelineSpecId=${value}`);
        this.pipelineSpecUrl = `${window.location.origin}/pipeline_spec/${value}`;
      })
    })

    this.activatedRoute.queryParamMap.subscribe(data => {
      /**
       * init pipeline setup
       default) /pipeline
       1) T-Cell Class I - http://localhost:4200/pipeline?tool=tc1
       2) Cluster - http://localhost:4200/pipeline?tool=cluster
       3) PEPMatch - http://localhost:4200/pipeline?tool=pepmatch
       4) PepX - http://localhost:4200/pipeline?tool=pepx
       5) Peptide Comparision - http://localhost:4200/pipeline?tool=pepvcomp
       6) Mutated Peptide Generator - http://localhost:4200/pipeline?tool=mutpepgen
       * */
      const data1 = data
      console.log(data1)
      const initTool = data1.get('tool');
      if(initTool) {
        switch (data1.get('tool')) {
          case 'cluster':
            this.titleService.setTitle(`Epitope Cluster Analysis`);
            const initClusterTool: Tool = {
              name: 'Cluster',
              toolGroup: 'cluster',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initClusterState, initClusterTool);
            break;
          case 'tc1':
            this.titleService.setTitle(`T Cell Prediction - Class I`);
            const initTCellPredictionTool: Tool = {
              name: 'T-Cell Prediction',
              toolGroup: 'mhci',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initTCellPredictionState, initTCellPredictionTool)
            break
          case 'pepmatch':
            this.titleService.setTitle(`PEPMatch`);
            const initPepmatchTool = {
              name: 'Pepmatch',
              toolGroup: 'pepmatch',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initPepMatchState, initPepmatchTool)
            break
          case 'pepx': // http://localhost:4200/pipeline?tool=pepx
            this.titleService.setTitle(`PepX`);
            const initPepxTool = {
              name: 'Pepx',
              toolGroup: 'pepx',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initPepXState, initPepxTool)
            break
          case 'pepvcomp': // http://localhost:4200/pipeline?tool=pepvcomp
            this.titleService.setTitle(`Peptide Variant Comparison`);
            const initPeptideVariantComparisonTool = {
              name: 'Peptide Variant Comparison',
              toolGroup: 'peptide_variant_comparison',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initPeptideBindingComparisonState, initPeptideVariantComparisonTool)
            break
          case 'mutpepgen': // http://localhost:4200/pipeline?tool=mutpepgen
            this.titleService.setTitle(`Mutated Peptide Generator`);
            const initMPGTool = {
              name: 'Mutated Peptide Generator',
              toolGroup: 'mutgen',
              loading: false,
              submitted: false,
              appliedFilters: undefined,
              disabled: false
            }
            this.setInitialStageAndToolGroup(initMutatedPeptideGeneratorState, initMPGTool)
            break
          default:
            this.location.replaceState('pipeline');
            this.titleService.setTitle(`IEDB NG Tools Pipeline`);
            break;
        }
      }
    });


    this.emailForm = this.fb.group({
      email: '',
      name: ''
    });
  }

  emitEventToChild() {
    // !tools[tools.length-1].submitted
    if(this.tools[this.tools.length-1].toolGroup === 'mhci'){
      this.runTCellSubject.next();
    }
    if(this.tools[this.tools.length-1].toolGroup === 'cluster'){
      this.runClusterSubject.next();
    }
    if(this.tools[this.tools.length-1].toolGroup === 'pepmatch'){
      this.runPepmatchSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'pepx'){
      this.runPepxSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'peptide_variant_comparison'){
      this.runPepcompSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'mutgen'){
      this.runMutgenSubject.next()
    }
  }

  emitRunAllToChild() {
    if(this.tools[this.tools.length-1].toolGroup === 'mhci'){
      this.runAllTCellSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'cluster'){
      this.runAllClusterSubject.next();
    }
    if(this.tools[this.tools.length-1].toolGroup === 'pepmatch'){
      this.runAllPepmatchSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'pepx'){
      this.runAllPepxSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'peptide_variant_comparison'){
      this.runAllPepcompSubject.next()
    }
    if(this.tools[this.tools.length-1].toolGroup === 'mutgen'){
      this.runAllMutgenSubject.next()
    }
  }


  generateStage(stage: any): Stage {
    /**
     * export default interface Stage {
     *   stage_number: number // index
     *   stage_type: string // prediction | filter
     *   tool_group: string // mhci | cluster | ''
     *   input_sequence_text?: string
     *   input_stage_number?: number
     *   data_to_pipe?: string
     *   input_parameters: any
     *   name: string
     *   result_url?: string
     * }
     * */

    return {
      stage_display_name: stage.stage_display_name,
      stage_number: stage.stage_number,
      stage_type: stage.stage_type,
      tool_group: stage.tool_group,
      input_parameters: stage.input_parameters,
      result_url: stage.stage_result_uri ? stage.stage_result_uri : undefined,
      input_sequence_text: stage.input_sequence_text ? stage.input_sequence_text : undefined,
      input_vcf_text: stage.input_vcf_text ? stage.input_vcf_text : undefined,
      piped_data: stage.piped_data ? stage.piped_data : undefined,
      // piped_input: stage.piped_input ? stage.piped_input : undefined,
      table_state: stage.table_state ? stage.table_state : [
        {
          "table": "",
          "columns": {}
        }
      ],
      stage_messages: stage.stage_messages ? stage.stage_messages : {errors: [], warnings: []}
    }
  }

  generateTool(stage: any, loading = true): Tool {
    /**
     *  toolGroup: 'mhci',
     *   loading: false,
     *   submitted: false,
     *   appliedFilters: undefined,
     *   disabled: false
     * */
    const appliedFilters = !_.isEmpty(stage.table_state[0]?.columns) || !_.isEmpty(stage.table_state[1]?.columns)
    return {
      name: stage.tool_group,
      toolGroup: stage.tool_group,
      loading,
      appliedFilters,
      disabled: false,
      submitted: false,
      input_data_uri: stage.input_data_uri ? stage.input_data_uri : undefined
    }
  }

  initPipeline(stages: Stage[]) {
    /**
     * Used for GET Pipeline and PipelineSpec
     * */
    let finalStages: Stage[] = []
    let paramStages: Stage[] = []
    let finalTools: Tool[] = []
    _.forEach(stages, (stage, i) => {

      const newStage = this.generateStage(stage);

      // this._stateService.setInitialStage(newStage);
      finalStages.push(newStage)
      paramStages.push({...newStage, result_url: undefined});

      const newTool = this.generateTool(stage, !!this.pipelineId);
      finalTools.push(newTool)

      const data = stage.input_parameters;

      // Sequence Input
      const input_sequence_text = stage.input_sequence_text;

      if (stage.tool_group === 'cluster') { // #1
        if (stage.stage_number === 1) {
          this.setClusterFormGroup(data, input_sequence_text);
        } else {
          this.setClusterFormGroup2(data)
        }
      } else if (stage.tool_group === 'mhci') { // #2
        if (stage.stage_number === 1) {
          this.setMhciFormGroup(data, input_sequence_text);
        } else {
          this.setMhciFormGroup2(data)
        }
      } else if (stage.tool_group === 'pepmatch') { // #3
        if (stage.stage_number === 1) {
          this.setPepmatchFormGroup(data, input_sequence_text);
        } else {
          this.setPepmatchFormGroup2(data)
        }
      } else if (stage.tool_group === 'pepx') { // # 4
        if (stage.stage_number === 1) {
          this.setPepXFormGroup(data, input_sequence_text);
        } else {
          this.setPepXFormGroup2(data)
        }
      } else if (stage.tool_group === 'peptide_variant_comparison') { // # 5
        if (stage.stage_number === 1) {
          this.setPepCompFormGroup(data, input_sequence_text);
        } else {
          this.setPepCompFormGroup2(data)
        }
      } else if (stage.tool_group === 'mutgen') { // # 6
        const input_vcf_text = stage.input_vcf_text;
        if (stage.stage_number === 1) {
          this.setMutGenFormGroup(data, input_vcf_text);
        } else {
          // this.setPepXFormGroup2(data)
        }
      }
    })

    this._stateService.setTools(finalTools);
    this._stateService.setStages(finalStages);
    this._stateService.setStagesToParams(paramStages);

    this.loading = false;
  }

  /** Pipeline Error Modal Functions */
  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true,
      backdrop: "static"
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.alleleSearch.nativeElement.focus();
      // this.closeResult = `Dismissed ${TCellPredictionComponent.getDismissReason(reason)}`;
    });
  }

  /**
   Set FormGroup
   * */
  setMhciFormGroup(data: any, inputSequenceText = '') {
    console.log(data);
    console.log(inputSequenceText);

    const {alleles, predictors, peptide_length_range, break_peptides} = data;
    // alleles
    const allelesFormatted = _.map(alleles.split(','), (a: string) => {
      return {label: a, synonym: null, score: null}
    })

    // predictors
    const predictorsFormatted: UntypedFormGroup[] = _.map(predictors, (p) => {
      if (p.type === 'binding') {
        return this.mhcBindingFormGroup(p.method)
      } else if (p.type === 'processing') {
        return this.mhcIProcessingFormGroup(p)
      } else if (p.type === 'immunogenicity') {
        const {mask_choice, position_to_mask} = p;
        return this.pmhcImmunogencityFormGroup(mask_choice, position_to_mask)
      } else if (p.type === 'mhcnp') {
        return this.mhcNpFormGroup()
      }
      return this.fb.group({})
    });

    const fg = this.addTCellPredictionFormGroup(
      inputSequenceText,
      peptide_length_range ? peptide_length_range[0] : 9,
      peptide_length_range ? peptide_length_range[1] : 9,
      allelesFormatted,
      !peptide_length_range,
      predictorsFormatted
    )
    let root_config = {mhci: fg}
    this.rootForm = this.fb.group(root_config);
  }

  setMhciFormGroup2(data: any) {
    console.log(data);

    const {alleles, predictors, peptide_length_range, break_peptides} = data;

    const allelesFormatted = _.map(alleles.split(','), (a: string) => {
      return {label: a, synonym: null, score: null}
    })

    const predictorsFormatted: UntypedFormGroup[] = _.map(predictors, (p) => {
      if (p.type === 'binding') {
        return this.mhcBindingFormGroup(p.method)
      } else if (p.type === 'processing') {
        return this.mhcIProcessingFormGroup(p)
      }
      return this.fb.group({})
    });

    const initialControlConfig = {
      peptideLength: new UntypedFormControl([
        peptide_length_range ? peptide_length_range[0] : 9,
        peptide_length_range ? peptide_length_range[1] : 9,
      ]),
      alleles: this.fb.array(allelesFormatted),
      predictionModels: this.fb.array(predictorsFormatted),
      breakPeptides: !peptide_length_range,
    };

    const fg = this.fb.group(initialControlConfig)
    this.rootForm.addControl('mhci', fg);
    // let root_config = {mhci: fg}
    // this.rootForm = this.fb.group(root_config);
  }

  setClusterFormGroup(data: any, inputSequenceText = '') {
    console.log(data);
    const {cluster_pct_identity, predictors, peptide_length_range} = data;
    const method = predictors[0].method;
    // const allelesFormatted = _.map(alleles.split(','), (a: string) => {return {label: a, synonym: null, score: null}})


    const fg = this.addClusterFormGroup(
      inputSequenceText,
      cluster_pct_identity,
      peptide_length_range[0],
      peptide_length_range[1],
      method
    )
    let root_config = {cluster: fg}
    this.rootForm = this.fb.group(root_config);
  }

  setClusterFormGroup2(data: any) {
    console.log(data);
    const {cluster_pct_identity, predictors, peptide_length_range} = data;
    const method = predictors[0].method;
    // const allelesFormatted = _.map(alleles.split(','), (a: string) => {return {label: a, synonym: null, score: null}})

    let minPeptideLength = peptide_length_range[0];
    let maxPeptideLength = peptide_length_range[1];

    minPeptideLength = minPeptideLength === 0 ? 4 : minPeptideLength
    maxPeptideLength = maxPeptideLength === 0 ? 26 : maxPeptideLength

    /** state update*/
    this._stateService.addStage({
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'cluster',
      stage_display_name: 'Epitope Cluster Analysis',
      // data_to_pipe: 'peptide',
      input_parameters: {}
    })

    this._stateService.insertTool({
      name: 'Cluster',
      toolGroup: 'cluster',
      loading: false,
      submitted: false,
      disabled: false
    }, this.tools.length)

    this._stateService.setToolDisabled(true, this.tools.length - 1)

    const initialControlConfig = {
      threshold: cluster_pct_identity,
      peptideLength: new UntypedFormControl([minPeptideLength, maxPeptideLength]),
      method
    };

    const fg = this.fb.group(initialControlConfig)
    // let root_config = {cluster: fg}
    this.rootForm.addControl('cluster', fg);
  }

  setPepmatchFormGroup(data: any, inputSequenceText = '') {
    console.log(data);
    const {proteome, mismatch, best_match, include_unmatched_peptides} = data;
    // const method = predictors[0].method;
    const fg = this.addPepmatchFormGroup(
      inputSequenceText,
      proteome,
      mismatch,
      best_match,
      include_unmatched_peptides
    )
    let root_config = {pepmatch: fg}
    this.rootForm = this.fb.group(root_config);
  }

  setPepmatchFormGroup2(data: any) {
    console.log(data);

    /** state update*/
    this._stateService.addStage({
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'pepmatch',
      stage_display_name: 'Pepmatch',
      // data_to_pipe: 'peptide',
      input_parameters: {}
    })

    this._stateService.insertTool({
      name: 'Pepmatch',
      toolGroup: 'pepmatch',
      loading: false,
      submitted: false,
      disabled: false
    }, this.tools.length)

    const {proteome, mismatch, best_match, include_unmatched_peptides} = data;

    const initialControlConfig = {
      proteome,
      mismatch,
      bestMatch: best_match,
      include_unmatched_peptides
    };

    const fg = this.fb.group(initialControlConfig)
    this.rootForm.addControl('pepmatch', fg);
  }

  setPepXFormGroup(data: any, inputSequenceText = '') {
    console.log(data);
    const {qlevel, datasource, dataset_id } = data;
    const fg = this.addPepXFormGroup(
      inputSequenceText,
      qlevel,
      datasource,
      dataset_id
    )
    let root_config = {pepx: fg}
    this.rootForm = this.fb.group(root_config);
  }

  setPepXFormGroup2(data: any) {
    console.log(data);

    /** state update*/
    this._stateService.addStage({
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'pepx',
      stage_display_name: 'PepX',
      // data_to_pipe: 'peptide',
      input_parameters: {}
    })

    this._stateService.insertTool({
      name: 'PepX',
      toolGroup: 'pepx',
      loading: false,
      submitted: false,
      disabled: false
    }, this.tools.length)

    const {qlevel, datasource, dataset_id} = data;

    const initialControlConfig = {
      qlevel,
      datasource,
      dataset_id
    };

    const fg = this.fb.group(initialControlConfig)
    this.rootForm.addControl('pepx', fg);
  }

  setPepCompFormGroup(data: any, inputSequenceText = '') {
    console.log(data);
    console.log(inputSequenceText);

    const {alleles, predictors, break_peptides} = data;
    // alleles
    const allelesFormatted = _.map(alleles.split(','), (a: string) => {
      return {label: a, synonym: null, score: null}
    })

    // predictors
    const predictorsFormatted: UntypedFormGroup[] = _.map(predictors, (p) => {
      if (p.type === 'binding') {
        return this.mhcBindingFormGroup(p.method)
      } else if (p.type === 'processing') {
        return this.mhcIProcessingFormGroup(p)
      } else if (p.type === 'immunogenicity') {
        const {mask_choice, position_to_mask} = p;
        return this.pmhcImmunogencityFormGroup(mask_choice, position_to_mask)
      } else if (p.type === 'mhcnp') {
        return this.mhcNpFormGroup()
      }
      return this.fb.group({})
    });

    const fg = this.initPepCompFormGroup(
      inputSequenceText,
      allelesFormatted,
      predictorsFormatted
    )
    let root_config = {pepcomp: fg}
    this.rootForm = this.fb.group(root_config);
  }

  setPepCompFormGroup2(data: any) {
    console.log(data);

    /** state update*/
    this._stateService.addStage({
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'peptide_variant_comparison',
      stage_display_name: 'Peptide Variant Comparison',
      input_parameters: {},
      table_state: [{table: 'peptide_table', columns: {}}],
      stage_messages: {errors: [], warnings: []}
    })

    this._stateService.insertTool({
      name: 'Peptide Variant Comparison',
      toolGroup: 'peptide_variant_comparison',
      loading: false,
      submitted: false,
      appliedFilters: undefined,
      disabled: false
    }, this.tools.length)

    const {alleles, predictors, break_peptides} = data;
    // alleles
    const allelesFormatted = _.map(alleles.split(','), (a: string) => {
      return {label: a, synonym: null, score: null}
    })

    // predictors
    const predictorsFormatted: UntypedFormGroup[] = _.map(predictors, (p) => {
      if (p.type === 'binding') {
        return this.mhcBindingFormGroup(p.method)
      } else if (p.type === 'processing') {
        return this.mhcIProcessingFormGroup(p)
      } else if (p.type === 'immunogenicity') {
        const {mask_choice, position_to_mask} = p;
        return this.pmhcImmunogencityFormGroup(mask_choice, position_to_mask)
      } else if (p.type === 'mhcnp') {
        return this.mhcNpFormGroup()
      }
      return this.fb.group({})
    });

    const initialControlConfig = {
      // inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      // peptideLength: new UntypedFormControl([minPeptideLength, maxPeptideLength]),
      alleles: this.fb.array(allelesFormatted),
      predictionModels: this.fb.array(predictorsFormatted)
    };


    const fg = this.fb.group(initialControlConfig)
    this.rootForm.addControl('pepcomp', fg);
  }

  setMutGenFormGroup(data: any, inputVcfText = '') {
    // console.log(data);
    // console.log(inputVcfText);
    const {peptide_length, peptide_mutation_position1, peptide_mutation_position2,
           frameshift_overlap, maximum_peptide_length} = data;

    const fg = this.addMutGenFormGroup(
      inputVcfText,
      peptide_length,
      peptide_mutation_position1,
      peptide_mutation_position2,
      frameshift_overlap,
      maximum_peptide_length
    )

    let root_config = {mutgen: fg}
    this.rootForm = this.fb.group(root_config);
  }

  ngOnDestroy() {
    clearInterval(this._pipelineService.runSetInterval)
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get addedToolGroups() {
    return _.map(this.stages, (o) => o.tool_group);
  }

  setInitialForm(stage: Tool) {
    /**
     Used to set rootForm with either 'mhci' or 'cluster'
     * */
    console.log(stage);
    let root_config = {}
    if (stage?.toolGroup === 'mhci') { // t-cell prediction
      root_config = {mhci: this.addTCellPredictionFormGroup()}
    } else if (stage?.toolGroup === 'cluster') { // cluster
      root_config = {cluster: this.addClusterFormGroup()}
    } else if (stage?.toolGroup === 'pepmatch') { // pepmatch
      root_config = {pepmatch: this.addPepmatchFormGroup()}
    } else if (stage?.toolGroup === 'pepx') { // pepx
      root_config = {pepx: this.addPepXFormGroup()}
    }
    else if (stage?.toolGroup === 'peptide_variant_comparison') { // pepx
      root_config = {pepcomp: this.addPepCompFormGroup()}
    }
    else if (stage?.toolGroup === 'mutgen') { // mutgen
      root_config = {mutgen: this.addMutGenFormGroup()}
    }
    console.log(root_config);
    this.rootForm = this.fb.group(root_config);
  }

  setInitialStageAndToolGroup(state: any, tool: any) {
    /**
     Mainly used in this.activatedRoute.queryParamMap.subscribe()
     * */
    const initState = _.cloneDeep(state)
    // const initTool = _.cloneDeep(tool)

    // const initState = JSON.parse(JSON.stringify(state))
    // const initTool = JSON.parse(JSON.stringify(tool))

    // const initState = Object.assign({}, state)
    // const initTool = Object.assign({}, tool)

    this._stateService.setInitialStage(initState)
    this._stateService.setInitialTool(tool)
    this._stateService.setParams(initParams)
    this._stateService.setPipelineId('')
    this._stateService.setPipelineSpecId('')
  }

  get tCellForm() {
    return this.rootForm.get('mhci') as UntypedFormGroup;
  }

  get clusterForm() {
    return this.rootForm.get('cluster') as UntypedFormGroup;
  }

  get pepmatchForm() {
    return this.rootForm.get('pepmatch') as UntypedFormGroup;
  }

  get pepxForm() {
    return this.rootForm.get('pepx') as UntypedFormGroup;
  }

  get pepcompForm() {
    return this.rootForm.get('pepcomp') as UntypedFormGroup;
  }

  get mutgenForm() {
    return this.rootForm.get('mutgen') as UntypedFormGroup;
  }

  get selectedAlleles() {
    /**

     * */
    return this.tCellForm.controls['alleles'].value;
  }

  get selectedAlleles2() {
    /**

     * */
    return this.pepcompForm.controls['alleles'].value;
  }

  get selectedAlleleLabels() {
    /**
     sidebar - Used to show min and max peptide length
     * */
    return this.tCellForm.controls['alleles'].value.map((o: Allele) => o.label).join(', ');
  }

  get predictionModels() {
    /**
     sidebar - Used to show min and max peptide length
     * */
    return this.tCellForm.controls['predictionModels'].value;
  }

  get predictionModels2() {
    /**
     sidebar - Used to show min and max peptide length
     * */
    return this.pepcompForm.controls['predictionModels'].value;
  }

  get clusterMinPeptideLength() {
    return [4, 26].includes(this.clusterForm.controls['peptideLength'].value[0]) ?
      0 : this.clusterForm.controls['peptideLength'].value[0]
  }

  get clusterMaxPeptideLength() {
    return [4, 26].includes(this.clusterForm.controls['peptideLength'].value[1]) ?
      0 : this.clusterForm.controls['peptideLength'].value[1]
  }

  debug() { // debugger
    /** defaults - use Sars placeholders*/

    console.log(this.inputSequenceText);
    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`pipelineId=${value}`);
    })
    this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('this.stages$');
      console.log(value);
    })
    this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('this.tools$');
      console.log(value);
    })
    // this.inputSequenceText$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
    //   console.log(value);
    // })
    this.params$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('this.params$');
      console.log('params');
      console.log(value);
    })
  }

  onCancelRuns() {
    /**
     Sends a PUT request to change status to 'canceled'

     Also stops
     * */
    const params = {
      cancel_run: true
    }

    this._pipelineService.putPipeline(this.pipelineId, params).subscribe((resp) => {
      console.log(resp)
    })

    _.forEach(this.tools, (tool, i) => {
      console.log(tool)
      const cancelTool = {
        ...tool,
        loading: false,
      }
      this._stateService.insertTool(cancelTool, i);
    })
  }

  onShowEmailChange(e: boolean) {
    console.log(e)
    this.showEmailModal = e;
  }

  onOpenEmailModal() {
    this.open(this.emailModal)
  }

  onRunAll(index: number) {
    // debugger
    // console.log(JSON.stringify(this.rootForm.value, null, 2));

    $.fn.dataTable.ext.search = [];

    const stage_number = index + 1;
    /** run_stage_range */
    const run_stage_range: [number, number] = [1, stage_number]

    console.log(this.params);

    const stages = this.stages.slice(0, stage_number)
    console.log(stages);
    this.params.run_stage_range = run_stage_range
    let initTool: Tool;

    _.forEach(this.stages, (stage, i) => {
      console.log(stage)
      const key = stage.tool_group

      /** Input sequence text */

      switch (key) {
        case "mhci": {
          if (i === 0) {
            let seq: string = ''
            let all: string = ''
            if(environment.production || environment['name'] === 'DEV') { // prod or dev
              const {input_sequence_text, alleles } = tCellData; // correct examples
              seq = input_sequence_text
              all = alleles
            } else {
              const {input_sequence_text, alleles } = pipelineData;
              seq = input_sequence_text
              all = alleles
            }
            // const {input_sequence_text} = tCellData;
            let sequences = this.tCellForm.get('inputSequenceText')?.value;
            if (!sequences) {
              this._stateService.setInputSequenceText(seq); // TODO - delete (prob don't need)
              this.tCellForm.controls.inputSequenceText.setValue(seq);
              sequences = seq;
            }
            this.params.stages[i].input_sequence_text = sequences
          }

          if (i <= index) {
            this.params.stages[i].input_parameters = this._pipelineService.convertTCellForm(this.tCellForm)
            this._stateService.setToolLoading(true, false, i)
          } else {
            this.params.stages[i].input_parameters = {}
            initTool = this.tools[i];
            const unSubmittedTool = {
              ...initTool,
              loading: false,
              submitted: false,
            }
            this._stateService.insertTool(unSubmittedTool, i);
          }

          break
        }
        case 'cluster': {
          if (i === 0) {
            const {input_sequence_text} = clusterData;
            let sequences = this.clusterForm.get('inputSequenceText')?.value;
            if (!sequences) {
              this._stateService.setInputSequenceText(input_sequence_text); // TODO - delete (prob don't need)
              this.clusterForm.controls.inputSequenceText.setValue(input_sequence_text);
              sequences = input_sequence_text;
            }
            this.params.stages[i].input_sequence_text = sequences
          }
          if (i <= index) {
            this.params.stages[i].input_parameters = this._pipelineService.convertClusterForm(this.clusterForm)
            this._stateService.setToolLoading(true, false, i)
          } else {
            this.params.stages[i].input_parameters = {}
            initTool = this.tools[i];
            const unSubmittedTool = {
              ...initTool,
              loading: false,
              submitted: false,
            }
            this._stateService.insertTool(unSubmittedTool, i);
          }
          break
        }
        case 'pepmatch': {

          if (i === 0) {
            const {input_sequence_text} = pepmatchiData;
            let sequences = this.pepmatchForm.get('inputSequenceText')?.value;
            if (!sequences) {
              this._stateService.setInputSequenceText(input_sequence_text); // TODO - delete (prob don't need)
              this.pepmatchForm.controls.inputSequenceText.setValue(input_sequence_text);
              sequences = input_sequence_text;
            }
            this.params.stages[i].input_sequence_text = sequences
          }
          if (i <= index) {
            this.params.stages[i].input_parameters = this._pipelineService.convertPepmatchForm(this.pepmatchForm)
            this._stateService.setToolLoading(true, false, i)
          } else {
            this.params.stages[i].input_parameters = {}
            initTool = this.tools[i];
            const unSubmittedTool = {
              ...initTool,
              loading: false,
              submitted: false,
            }
            this._stateService.insertTool(unSubmittedTool, i);
          }
          break
        }
        case 'pepx': {
          if (i === 0) {
            const {input_sequence_text} = pepxData;
            let sequences = this.pepxForm.get('inputSequenceText')?.value;
            if (!sequences) {
              this._stateService.setInputSequenceText(input_sequence_text); // TODO - delete (prob don't need)
              this.pepxForm.controls.inputSequenceText.setValue(input_sequence_text);
              sequences = input_sequence_text;
            }
            this.params.stages[i].input_sequence_text = sequences
          }
          if (i <= index) {
            this.params.stages[i].input_parameters = this._pipelineService.convertPepxForm(this.pepxForm)
            this._stateService.setToolLoading(true, false, i)
          } else {
            this.params.stages[i].input_parameters = {}
            initTool = this.tools[i];
            const unSubmittedTool = {
              ...initTool,
              loading: false,
              submitted: false,
            }
            this._stateService.insertTool(unSubmittedTool, i);
          }
          break
        }
        case 'peptide_variant_comparison': {
          if (i === 0) {
            const {input_sequence_text} = peptideBindingComparisonData;
            let sequences = this.pepcompForm.get('inputSequenceText')?.value;
            if (!sequences) {
              this._stateService.setInputSequenceText(input_sequence_text); // TODO - delete (prob don't need)
              this.pepcompForm.controls.inputSequenceText.setValue(input_sequence_text);
              sequences = input_sequence_text;
            }
            this.params.stages[i].input_sequence_text = sequences
          }
          if (i <= index) {
            this.params.stages[i].input_parameters = this._pipelineService.convertPepcompForm(this.pepcompForm)
            this._stateService.setToolLoading(true, false, i)
          } else {
            this.params.stages[i].input_parameters = {}
            initTool = this.tools[i];
            const unSubmittedTool = {
              ...initTool,
              loading: false,
              submitted: false,
            }
            this._stateService.insertTool(unSubmittedTool, i);
          }
          break
        }
        case 'mutgen': {

          break;
        }
        default: {
          break
        }
      }
    })

    this.params.pipeline_title = this.emailForm.controls['name'].value;
    this.params.email = this.emailForm.controls['email'].value;


    console.log(this.params);
    this._stateService.setParams(this.params);

    this.postPipelineAPI(this.params);
  }

  postPipelineAPI(params: PipelineParams, getResults: boolean = true) {
    /**
     * Run All Above Post Pipeline
     * */
    this._pipelineService.postPipeline(params).subscribe(
      (resp) => {
      console.log(resp);
      if (resp.pipeline_id) {
        this._stateService.setPipelineId(resp.pipeline_id);
        this._stateService.setPipelineIdToParams(resp.pipeline_id);
        this._stateService.setPipelineSpecId(resp.pipeline_spec_id);
        this.location.replaceState(`pipeline/${resp.pipeline_id}`)
        // this.titleService.setTitle(`IEDB NG Tools Pipeline`);

        this._pipelineService.getPipeline(resp.pipeline_id).subscribe(value => {
          console.log(value);
          console.log(value.stages);
          _.forEach(value.stages, (s, i: number) => {
            console.log(i)
            if (i===0) {
              this.titleService.setTitle(`${s.stage_display_name}`)
            }
            this._stateService.changeResultUrl(s.stage_result_uri, i)
          })
        })
      } else if (resp.errors || resp.warnings) {
        this.errorMessages = resp.errors;
        this.warningMessages = resp.warnings;
        this.open(this.alertModal)
        console.log(resp)
      }
    }, error => {
        console.error(error)
        this.errorMessages.push(error)
        this.loading = false
        // this.submitted = false
        // this.tableStateSaving = false
        // this.enableInputs()
      });
  }

  fetchValidSteps() {
    /**
     /api/v1/pipeline/valid_steps
     * */
    const API_URL = `${environment['api_url']}/api/v1/pipeline/valid_steps`;
    this.http.get(API_URL).subscribe(value => {
      this.validSteps = value;
      console.log(value);
    })
  }

  addTCellPredictionFormGroup(
    inputSequenceText = '',
    minPeptideLength = 9,
    maxPeptideLength = 9,
    alleles: Allele[] = [],
    breakPeptides: boolean = false,
    predictions = [this.mhcBindingFormGroup()]
  ) {
    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    const all = sessionStorage.getItem('selectedAlleles') ? JSON.parse(sessionStorage.getItem('selectedAlleles') as string) : alleles
    sessionStorage.removeItem('selectedAlleles')

    const initialControlConfig = {
      inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      peptideLength: new UntypedFormControl([minPeptideLength, maxPeptideLength]),
      alleles: this.fb.array(all),
      breakPeptides: breakPeptides,
      predictionModels: this.fb.array(predictions)
    };
    return this.fb.group(initialControlConfig)
  }

  initPepCompFormGroup(
    inputSequenceText = '',
    // minPeptideLength = 9,
    // maxPeptideLength = 9,
    alleles: Allele[] = [],
    // breakPeptides: boolean = false,
    predictions = [this.mhcBindingFormGroup()]
  ) {
    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    const all = sessionStorage.getItem('selectedAlleles') ? JSON.parse(sessionStorage.getItem('selectedAlleles') as string) : alleles
    sessionStorage.removeItem('selectedAlleles')

    const initialControlConfig = {
      inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      // peptideLength: new UntypedFormControl([minPeptideLength, maxPeptideLength]),
      alleles: this.fb.array(all),
      // breakPeptides: breakPeptides,
      predictionModels: this.fb.array(predictions)
    };
    return this.fb.group(initialControlConfig)
  }

  removeToolFromPipeline(formControlName: string) {
    /**
     * pop - Remove an item from the end of an array (Tools)
     2 situations:
     When there is result
     When there is no result.
     * */
    this._stateService.popStage()
    this._stateService.popTool()
    this._stateService.popStageFromParams()

    this.rootForm.removeControl(formControlName)
  }

  addTCellPrediction(i: number, dataToPipe: string, tableName: string = 'peptide_table' ) {
    const stage = {
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'mhci',
      piped_data: {piped_input: [
        {
          piped_fields: [`${tableName}.${dataToPipe}`],
          piped_stage_number: i,
        }
      ]},
      stage_display_name: 'T Cell Prediction - Class I',
      input_parameters: {},
      table_state: {columns: {}},
      stage_messages: {errors: [], warnings: []},
    }

    this._stateService.addStage(stage)
    this._stateService.addStageToParams(stage)

    this._stateService.insertTool({
      name: 'T-Cell Prediction',
      toolGroup: 'mhci',
      loading: false,
      submitted: false,
      disabled: false
    }, i)
    // this.stages.push({stage_number: 3, stage_type: 'prediction', tool_group: 'cluster', name: 'Cluster', input_parameters: {}});
    const initialControlConfig = {
      inputSequenceText: '',
      // sequences: sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : '',
      peptideLength: new UntypedFormControl([9, 10]),
      alleles: this.fb.array([]),
      // email: null,
      // jobTitle: null,
      breakPeptides: false,
      predictionModels: this.fb.array([
        this.mhcBindingFormGroup(),
        // this.mhcBindingFormGroup('smmpmbec'),
        // this.mhcBindingFormGroup('smm'),
        // this.pmhcImmunogencityFormGroup(),
        // this.mhcIProcessingFormGroup()
      ])
    };

    this.rootForm.addControl('mhci', this.fb.group(initialControlConfig));
  }

  addClusterFormGroup(
    inputSequenceText = '',
    threshold = 0.7,
    minPeptideLength = 4,
    maxPeptideLength = 26,
    method = 'cluster-break'
  ) {

    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    minPeptideLength = minPeptideLength === 0 ? 4 : minPeptideLength
    maxPeptideLength = maxPeptideLength === 0 ? 26 : maxPeptideLength

    const initialControlConfig = {
      inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      threshold,
      peptideLength: new UntypedFormControl([minPeptideLength, maxPeptideLength]),
      method
    };
    return this.fb.group(initialControlConfig)
  }

  addCluster(i: number, dataToPipe: string = 'peptide', tableName: string = 'peptide_table') {
    /**
     i - tools.length
     * */
    console.log('add cluster');
    const stage = {
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'cluster',
      stage_display_name: 'Epitope Cluster Analysis',
      piped_data: {piped_input: [
          {
            piped_fields: [`${tableName}.${dataToPipe}`],
            piped_stage_number: i,
          }
        ]},
      input_parameters: {},
      table_state: {columns: {}},
      stage_messages: {errors: [], warnings: []},
    }

    /** state update*/
    this._stateService.addStage(stage)
    this._stateService.addStageToParams(stage)

    this._stateService.insertTool({
      name: 'Cluster',
      toolGroup: 'cluster',
      loading: false,
      submitted: false,
      appliedFilters: undefined,
      disabled: false
    }, i)

    this._stateService.setToolDisabled(true, i - 1)
    /** rootForm update */

    const initialControlConfig = {
      inputSequenceText: "",
      threshold: 0.7,
      peptideLength: new UntypedFormControl([4, 26]),
      method: 'cluster-break'
    };

    this.rootForm.addControl('cluster', this.fb.group(initialControlConfig));
  }

  /**
   Used in T-Cell Predictions
   1) mhcBindingFormGroup
   2) pmhcImmunogencityFormGroup
   3) mhcIProcessingFormGroup
   * */
  mhcBindingFormGroup(method = 'netmhcpan_el') {
    /**
     * Creates mhcBindingFormGroup using formBuilder
     *
     * Currently defaulted to 'netmhcpan_el'
     * */
    return this.fb.group({
      type: 'binding',
      method: this.fb.control(method),
    })
  }

  mhcNpFormGroup() {
    /**
     * Creates mhcNpFormGroup using formBuilder
     * */
    return this.fb.group({
      type: 'mhcnp'
    })
  }

  pmhcImmunogencityFormGroup(mask_choice = 'default', position_to_mask = '2,5,9') {
    /**
     * */
    return this.fb.group({
      type: 'immunogenicity',
      mask_choice: mask_choice,
      position_to_mask: this.fb.control(position_to_mask),
    })
  }

  mhcIProcessingFormGroup(predictor: any) {
    /**
     * TODO: add the rest of methods in
     * Creates mhcBindingFormGroup using formBuilder
     *
     * Currently defaulted to 'netmhcpan_el'
     * */

    const method = predictor.method

    switch (method) {
      case 'basic_processing': {
        return this.fb.group({
          type: "processing",
          method: this.fb.control(method),
          mhc_binding_method: "netmhcpan_ba",
          proteasome: "immuno",
          tap_precursor: 1,
          tap_alpha: 0.2

        })
      }
      case 'netchop': {
        return this.fb.group({
          type: 'processing',
          method: this.fb.control(method),
          network_method: predictor.network_method,
          threshold: predictor.threshold
        })
      }
      case 'netctl': {
        return this.fb.group({
          type: 'processing',
          method: this.fb.control(method),
          cleavage_weight: predictor.cleavage_weight,
          tap_weight: predictor.tap_weight,
          threshold: predictor.threshold
        })
      }
      case 'netctlpan': {
        return this.fb.group({
          type: 'processing',
          method: this.fb.control(method),
          cleavage_weight: predictor.cleavage_weight,
          tap_weight: predictor.tap_weight,
          epitope_threshold: predictor.epitope_threshold
        })
      }
      default:
        return this.fb.group({
          type: 'processing',
          method: this.fb.control(method),
        })
    }


  }

  onGoToStage(toolGroup: string) {
    /**
     * used in pipeline sidebar to go to specific stage
     * all Tools' labels' ids are set to ${toolGroup}-label
     * */
    const navbarHeight = 81 // same as $navbar-height: 81px;
    window.scroll({
      left: 0,
      top: document.getElementById(`${toolGroup}-label`)!.offsetTop - navbarHeight,
      behavior: 'smooth'
    });
  }

  /* PepMatch Functions */
  addPepmatchFormGroup(
    inputSequenceText = '',
    proteome = 'Human',
    mismatch = 3,
    bestMatch = true,
    include_unmatched_peptides = false
  ) {

    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    const initialControlConfig = {
      inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      proteome,
      mismatch,
      bestMatch,
      include_unmatched_peptides
    };
    return this.fb.group(initialControlConfig)
  }

  addPepmatch(i: number, dataToPipe: string = 'peptide', tableName: string = 'peptide_table') {
    console.log('add pepmatch');
    const stage = {
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'pepmatch',
      stage_display_name: 'Pepmatch',
      piped_data: {piped_input: [
          {
            piped_fields: [`${tableName}.${dataToPipe}`],
            piped_stage_number: i,
          }
        ]},
      input_parameters: {},
      table_state: {columns: {}},
      stage_messages: {errors: [], warnings: []},
    }

    /** state update*/
    this._stateService.addStage(stage)
    this._stateService.addStageToParams(stage)

    this._stateService.insertTool({
      name: 'Pepmatch',
      toolGroup: 'pepmatch',
      loading: false,
      submitted: false,
      disabled: false
    }, i)

    this._stateService.setToolDisabled(true, i - 1)
    /** rootForm update */

    const defaultFormGroup = this.addPepmatchFormGroup()

    this.rootForm.addControl('pepmatch', defaultFormGroup);
  }

  /* PepX Functions */
  addPepXFormGroup(
    inputSequenceText = '',
    qlevel = 'gene',
    datasource = 'Abelin',
    // dataset = 'v1'
    dataset_id = 2
  ) {

    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    const initialControlConfig = {
      inputSequenceText: [seq, Validators.maxLength(this.maxCharacters)],
      qlevel,
      datasource,
      // dataset
      dataset_id
    };
    return this.fb.group(initialControlConfig)
  }

  addPepX(i: number, dataToPipe: string = 'peptide', tableName: string = 'peptide_table') {
    console.log('add pepx');
    const stage = {
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'pepx',
      stage_display_name: 'PepX',
      piped_data: {piped_input: [
          {
            piped_fields: [`${tableName}.${dataToPipe}`],
            piped_stage_number: i,
          }
        ]},
      input_parameters: {},
      table_state: {columns: {}},
      stage_messages: {errors: [], warnings: []},
    }

    /** state update*/
    this._stateService.addStage(stage)
    this._stateService.addStageToParams(stage)

    this._stateService.insertTool({
      name: 'PepX',
      toolGroup: 'pepx',
      loading: false,
      submitted: false,
      disabled: false
    }, i)

    this._stateService.setToolDisabled(true, i - 1)
    /** rootForm update */

      // const defaultFormGroup = this.addPepmatchFormGroup()
    const defaultFormGroup = this.addPepXFormGroup()

    this.rootForm.addControl('pepx', defaultFormGroup);
  }

  /* PepComp Functions */
  addPepCompFormGroup(
    inputSequenceText = '',
    alleles = this.fb.array([]),
    predictionModels = this.fb.array([this.mhcBindingFormGroup()])
  ) {

    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputSequenceText;
    sessionStorage.removeItem('sequences');

    const initialControlConfig = {
      inputSequenceText: seq,
      alleles: alleles,
      predictionModels: predictionModels,
    };

    return this.fb.group(initialControlConfig)
  }

  addPepComp(i: number, tableName: string = 'unique_peptide', dataToPipe1: string = 'reference_peptide', dataToPipe2: string = 'mutant_peptide') {
    console.log('addPepComp');
    const stage = {
      stage_number: this.stages.length + 1,
      stage_type: 'prediction',
      tool_group: 'peptide_variant_comparison',
      stage_display_name: 'Peptide Variant Comparison',
      piped_data: {piped_input: [
          {
            piped_fields: [`${tableName}.${dataToPipe1}`,`${tableName}.${dataToPipe2}`],
            piped_stage_number: i,
          }
        ]},
      input_parameters: {},
      table_state: [{table: 'peptide_table', columns: {}}],
      stage_messages: {errors: [], warnings: []}
    }

    this._stateService.addStage(stage)
    this._stateService.addStageToParams(stage)

    this._stateService.insertTool({
      name: 'Peptide Variant Comparison',
      toolGroup: 'peptide_variant_comparison',
      loading: false,
      submitted: false,
      appliedFilters: undefined,
      disabled: false
    }, i)
    this._stateService.setToolDisabled(true, i - 1)

    const defaultFormGroup = this.addPepCompFormGroup()

    this.rootForm.addControl('pepcomp', defaultFormGroup);
  }

  // isToolGroupSelected(toolGroup: string) {
  //   /**
  //    * Used to display different tools in root form
  //    * filters and finds tool_group name (i.e 'mhci', 'cluster').
  //    * */
  //   const a = _.filter(this.stages, (o) => o.tool_group === toolGroup);
  //   return a.length;
  // }

  /** MutGen Functions */
  addMutGenFormGroup(
    inputVcfText = '',
    peptide_length: number = 21,
    peptide_mutation_position1: number = 11,
    peptide_mutation_position2: number | null = null,
    frameshift_overlap: number = 9,
    min_len_near_start_stop: number = 16,
    maximum_peptide_length: number = 24
  ) {

    const seq = sessionStorage.getItem('sequences') ? sessionStorage.getItem('sequences') : inputVcfText;
    sessionStorage.removeItem('sequences');

    const initialControlConfig = {
      inputVcfText: seq,
      peptideLength: peptide_length,
      peptideMutationPosition1: peptide_mutation_position1,
      peptideMutationPosition2: peptide_mutation_position2,
      frameshiftOverlap: frameshift_overlap,
      minLengthNearStartStop: min_len_near_start_stop,
      maximumPeptideLength: maximum_peptide_length
    };

    return this.fb.group(initialControlConfig)
  }

  /*** Email functions */
  onSubmitEmailModal() {

    this.submittedEmailForm = {...this.emailForm.value}

    // console.log(this.submittedEmailForm)

    if (this.pipelineId) {
      const params = {
        email: this.submittedEmailForm.email,
        pipeline_title: this.submittedEmailForm.name
      }

      this._pipelineService.putPipeline(this.pipelineId, params).subscribe((resp) => {
        // console.log(resp)
      })
    }

    this.modalService.dismissAll()
  }


}
