import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  Input,
  OnDestroy,
  Output,
  EventEmitter, ElementRef,
  QueryList
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

import {NgbModal, NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import { Papa } from 'ngx-papaparse';

import {DataTableDirective} from 'angular-datatables';
import {ADTSettings} from 'angular-datatables/src/models/settings';

import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import Columns from '../_global/Columns';
import Tool from "../_global/Tool";
import Stage from "../_global/Stage";

@Component({
  selector: 'app-result-table',
  templateUrl: './result-table.component.html',
  styleUrls: ['./result-table.component.scss']
})
export class ResultTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false}) dtElement!: DataTableDirective;
  dtOptions: ADTSettings = {};

  @ViewChild('displayColumnModal') displayColumnModal!: ElementRef;

  /** NgbDropdowns */
  @ViewChildren(NgbDropdown) dropdowns!: QueryList<NgbDropdown>;
  private dropdown!: NgbDropdown;

  @Input() inputChange: boolean = false;
  /** API Call Variables */
  @Input() table_data: [(number | string)[]] = [[]]; // peptide_table
  @Input() order: [][] | any = [];
  @Input() columns: Columns[] | any; // peptide_table
  defaultColumnConfig: object = {};
  @Input() warnings: [] = []
  @Input() tool_group!: string;
  @Input() index!: number;
  @Input() tableStateSaving = false;
  @Input() table_name!: string;

  @Output() emitSaveTableState = new EventEmitter<any>();
  @Output() emitFilterApplied = new EventEmitter<any>();
  @Output() emitResetTableState = new EventEmitter<any>();

  prevColumnDisplayFormValue!: object
  columnDisplayForm!: UntypedFormGroup
  uniqSources: string[] = [];
  /** We use this trigger because fetching the list of persons can be quite long,
   thus we ensure the data is fetched before rendering */
  dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();

  unsubscribe$ = new Subject();
  // params$: Observable<PipelineParams> = this._stateService.params$;

  tools$: Observable<Tool[]> = this._stateService.tools$;
  tools: Tool[] = [];

  stages$: Observable<Stage[]> = this._stateService.stages$;
  stages: Stage[] = [];

  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  pipelineId = '';

  dataToPipe: string = '';
  dataToPipe2: string = '';

  currentOrder: [][] | any;

  table_state: any; // used in GET Pipeline
  searchCols: any[] = [] // used in GET Pipeline

  loading = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private papa: Papa,
    private _stateService: PipelineStateService
  ) {
  }

  ngOnInit() {
    this.currentOrder = [...this.order];

    this.dtOptions = {
      dom:
        '<"" ' +
        '<"row p-1" ' +
        '<"col-3 pt-3" l>' +
        '<"col-4 px-5" i>' +
        '<"col-5" p>>> t' +
        '<"" ' +
        '<"row p-1" ' +
        '<"col-3 pt-3" l>' +
        '<"col-4 px-5" i>' +
        '<"col-5" p>>>', // https://datatables.net/examples/basic_init/dom.html
      autoWidth: false,
      pagingType: 'simple_numbers',
      paging: true,
      pageLength: 50,
      ordering: true,
      orderMulti: true,
      stateSave: false,
      language: {
        lengthMenu: "Show _MENU_ rows",
        info: "_START_ to _END_ of _TOTAL_ rows",
        infoFiltered: "(filtered from _MAX_ total rows)"
      },
      data: this.table_data,
      order: this.currentOrder,
      columnDefs: _.map(this.columns, (column, i) => {
        return {
          targets: [i],
          createdCell: (td, cellData, rowData, row, col) => {
            let textAlign = ['int', 'float'].includes(column.type) ? 'right' : 'left';
            $(td).css('text-align', textAlign);
          },
          visible: !column.hidden, // set default visible columns
          render: (data, type) => {
            // console.log(i, data, type);
            if (column.type === 'int' && isNaN(data)) return parseInt(data);
            else if (column.type === 'float' && !isNaN(data) &&
              type === 'display' && column.number_of_digits) return parseFloat(data).toFixed(column.number_of_digits);
            else if (column.type === 'float' && !isNaN(data)) return parseFloat(data);
            else if (column.type === 'float' && isNaN(data)) return null;
            // if (column.name === 'protein_name' && data.length>=column.display_length) return `${data.substring(0, column.display_length)}...`;
            return data;
          }
        }
      }),
      destroy: true,
      deferRender: true,
      orderClasses: false,
      searchCols: []
    };

  //   get tools
    this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      // console.log('tools$');
      // console.log(value);
      this.tools = value;
    })

    this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.stages = value;

      /** set dataToPipe */
      if(this.stages.length>=2) {
        const stage = this.stages[this.index+1]; // get the last stage
        // this.dataToPipe = stage?.piped_input && stage.piped_input[0].piped_fields[0];

        this.dataToPipe = stage?.piped_data && stage!.piped_data?.piped_input && stage.piped_data.piped_input[0]?.piped_fields![0] || '';
        this.dataToPipe2 = stage?.piped_data && stage!.piped_data?.piped_input && stage.piped_data.piped_input[0]?.piped_fields![1] || '';
        console.log(`dataToPipe = ${this.dataToPipe}`)
        console.log(`dataToPipe2 = ${this.dataToPipe2}`)
      } else {
        this.dataToPipe = '';
        this.dataToPipe2 = '';
      }
      // console.log(this.dataToPipe);

      /** set filters from GET Pipeline */
      this.table_state = this.stages[this.index]?.table_state;
      this.searchCols = Array.from({length: this.columns.length}, ()=> {})

      /**
       * Added 'this.table_state' in if statement and
       *
       * this.table_state[0] = peptide_table
       * this.table_state[1] = peptide_gene_summary_table (pepx)
       * */
      let tableIndex: number
      switch (this.table_name) {
        case 'peptide_gene_summary_table':
          tableIndex = 1
          break;
        case 'peptide':
          tableIndex = 1
          break;
        case 'unique_peptide':
          tableIndex = 0
          break;
        default: // peptide_table
          tableIndex = 0
      }

      if(this.table_state && this.table_state[tableIndex] && !_.isEmpty(this.table_state[tableIndex]?.columns)) {
        // console.log(`tableIndex=${tableIndex}`);
        // console.log(this.table_state[tableIndex].columns);
        // console.log(this.columns);
        for (const col in this.table_state[tableIndex].columns) {
          let column = this.table_state[tableIndex].columns[col]
          const{ name, source } = column
          const { min, max, search } = column.search;
          let i = _.findIndex(this.columns, {name, source})

          // console.log(i)
          // console.log({name, source})

          this.columns[i].value_limits.minValue = min || min === 0 ? min : NaN
          this.columns[i].value_limits.maxValue = max || max === 0 ? max : NaN

          this.searchCols[i] = {search: search, regex: true, smart: false}
          this.columns[i].value_limits.selectedValues = search ? search.split('|') : []
          this.columns[i].value_limits.applied = true
        }

      }
      // this.dtOptionsArr[this.index].searchCols = this.searchCols;
      this.dtOptions.searchCols = this.searchCols;
    })

    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`pipelineId=${value}`);
      this.pipelineId = value;
    })

  }

  ngAfterViewInit() {
    this.setColumnDisplayForm();
    this.dtTrigger.next();
    // this.setMinAndMax();

    // console.log(this.filterDropdown.isOpen())
  }

  ngOnDestroy(): void {
    // $.fn.dataTable.ext.search = [];
    this.dtTrigger.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get visibleColumns() {
    return _.filter(this.columns, c => !c.hidden) as Columns[]
  }

  onOpenDisplayColumnModal() {
    this.prevColumnDisplayFormValue = this.columnDisplayForm.value;
    console.log(this.columnDisplayForm.value)
    this.disableSortedColumnInputs()
    this.disableInputColumns()
    this.open(this.displayColumnModal)
  }

  disableSortedColumnInputs() {
    /**
     * Prior to opening modal,
     * disable controls from columnDisplayForm that have sorted columns.
     * */
    this.columnDisplayForm.enable();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const colIndex = [];
      const order = dtInstance.state().order;
      _.forEach(order, (o) => {
        console.log(o);
        colIndex.push(o[0]);
        const formControlName = this.columns[o[0]].filterKey;
        console.log(formControlName);
        this.columnDisplayForm.controls[`${formControlName}`].disable();
      })
    });
  }

  disableInputColumns() {
    /**
     * Prior to opening modal,
     * disable controls from columnDisplayForm that have columns that can be used downstream (i.e. peptide and cluster_concensus).
     * */
    this.columnDisplayForm.controls['core.peptide']?.value && this.columnDisplayForm.controls['core.peptide'].disable()
    this.columnDisplayForm.controls['cluster.peptide']?.value && this.columnDisplayForm.controls['cluster.peptide'].disable()
    this.columnDisplayForm.controls['cluster.cluster_consensus']?.value && this.columnDisplayForm.controls['cluster.cluster_consensus'].disable()

  }

  /** Column Display Modal Functions*/

  open(content: any) {
    /**
     * Open Modal
     * */
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true,
      backdrop: 'static'
    }).result.then((result) => {
      console.log(result)
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log(reason)
    });
  }

  dismissModal() {
    this.columnDisplayForm.patchValue(this.prevColumnDisplayFormValue)

    this.modalService.dismissAll()
  }

  onSetDisplayColumns() {
    /**
     * Display onClick
     * */
    let visibleColumns: number[] = []; // let visibleColumns: string[] = [];
    let invisibleColumns: number[] = []; // let invisibleColumns: string[] = [];

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      console.log(dtInstance);
      _.forEach(this.columns, (o, i: number) => {
        if (this.columnDisplayForm.controls[`${o.filterKey}`].value) {
          visibleColumns.push(i)
          this.columns[i].hidden = false
        }
        else {
          invisibleColumns.push(i);
          this.columns[i].hidden = true
        }
      })
      dtInstance.columns(visibleColumns).visible(true);
      dtInstance.columns(invisibleColumns).visible(false);
      this.modalService.dismissAll();
    });
  }

  setColumnDisplayForm() {
    /**
     * 1) set's this.selectedColumn w/ recommendedColumns, smmColumns, consensusColumns
     * 2) set's this.columnDisplayForm with the proper inputs.
     * 3) Also sets $.fn.dataTable.ext which allows number filtering
     * */
    let controlConfig: any = {}
    this.uniqSources = [];
    _.forEach(this.columns, (col, i: number) => {
      controlConfig[`${col.filterKey}`] = !col.hidden;
      if (!this.uniqSources.includes(col.source)) {
        this.uniqSources.push(col.source);
      }
      // datatables adding min/max filters for each of the table
      $.fn['dataTable'].ext.search.push((settings: any, data: string[], dataIndex: any) => {

        // const table = settings.table()
        if (settings.nTable.id !== `DataTables_${this.tool_group}${this.table_name}`){
          return true;
        }

        // const id = parseFloat(data[i]) || i; // use data for the id column - can't use this because '0' does not get
        const val = parseFloat(data[i]); // 0 does get set properly
        /**
         1. Both min and max are numbers
         * */
        if ((isNaN(this.columns[i].value_limits?.minValue) && isNaN(this.columns[i].value_limits?.maxValue)) ||
          (isNaN(this.columns[i].value_limits?.minValue) && val <= this.columns[i].value_limits?.maxValue) ||
          (this.columns[i].value_limits?.minValue <= val && isNaN(this.columns[i].value_limits?.maxValue)) ||
          (this.columns[i].value_limits?.minValue <= val && val <= this.columns[i].value_limits?.maxValue)) {
          return true;
        }
        return false;

      })
    })
    this.columnDisplayForm = this.formBuilder.group(controlConfig);
    this.defaultColumnConfig = controlConfig
  }

  onDefaultColumns() {
    /**
     uses this.defaultColumnConfig that gets set in this.setColumnDisplayForm()
     * */
    this.columnDisplayForm = this.formBuilder.group(this.defaultColumnConfig);
  }

  stopProp(e: { stopPropagation: () => void; }) {
    /** used in column filters */
    e.stopPropagation();
  }

  onSortColumns(colIndex: number, order: string) {
    /** used in column filters */
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.order([colIndex, order]).draw();
    });
  }

  /** General filter functions */
  onFilterDropdownOpen() {
    $('.mat-drawer-content').removeClass("fixed-position");
    $('.reset-button').prop("disabled", false);
    $('.run-button').prop("disabled", false);
  }

  onFilterDropdownClose() {
    $('.mat-drawer-content').addClass("fixed-position");
    $('.reset-button').prop("disabled", true);
    $('.run-button').prop("disabled", true);
  }

  checkClosedDropdown(e: boolean) {
    if(!e) {this.onFilterDropdownOpen();}
  }

  checkOpenedDropdown(i: number) {
    /**
     * used in ngbDropdown - disable scroll when dropdown filter is applied.
     * */
    // Check which dropdown was clicked
    this.dropdown = this.dropdowns.get(i) as NgbDropdown;
    // Check if the clicked dropdown is open
    if(this.dropdown.isOpen()) {this.onFilterDropdownClose();}
  }


  /** Number Filter Functions*/
  setMinAndMax() {
    let that = this;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const newColumns = dtInstance.columns()
      newColumns.every(function(i) {
        let column = this;
        const columnValue = column.dataSrc();

        let sortFunc;
        if (columnValue !== 'core_peptide') {
          sortFunc = (a: number, b: number) => a - b
        }
        /** Text Filter */
        let distinctArray: any[] = [];
        column.data().sort(sortFunc).each(function (d, j) {
          if (distinctArray.indexOf(d) == -1) {
            distinctArray.push(d);
          }
        });
        /** Set Text/Number Filter Values */
        // console.log(that.columns[i]['type']);
        if (that.columns[i]['type'] === 'text') {
          that.columns[i]['value_limits']['possibleValues'] = distinctArray
        } else {
          that.columns[i]['value_limits']['min'] = distinctArray[0]
          that.columns[i]['value_limits']['max'] = distinctArray[distinctArray.length - 1]
        }
      })
    })
  }

  onClearNumberFilter(i: number) {
    $(`.min${i}${this.tool_group}${this.table_name}, .max${i}${this.tool_group}${this.table_name}`).val(NaN);
    this.columns[i]['value_limits']['applied'] = false;
    this.columns[i]['value_limits']['minValue'] = NaN;
    this.columns[i]['value_limits']['maxValue'] = NaN;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.column(i).search(
        '',
        true, false
      ).draw();
      this.emitFilterApplied.emit()
    });
  }
  onNumberFilter(i: number) {

    this.columns[i]['value_limits']['minValue'] = parseFloat(<string>$(`.min${i}${this.tool_group}${this.table_name}`).val());
    this.columns[i]['value_limits']['maxValue'] = parseFloat(<string>$(`.max${i}${this.tool_group}${this.table_name}`).val());

    const {minValue, maxValue} = this.columns[i]['value_limits'];

    const filterApplied = !!(minValue || maxValue || minValue === 0 || maxValue === 0);
    this.columns[i]['value_limits']['applied'] = filterApplied

    console.log(`index=${i}`)
    console.log(minValue)
    console.log(maxValue)

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
      this.emitFilterApplied.emit()
    });
  }

  /** Text Filter Functions*/
  onFilterPossibleValues(e: any, i: number) {
    /**
     * .toUpperCase() make it case insensitive
     * */

    let value = e.target.value;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      let column = dtInstance.column(i);
      let distinctArray: any[] = [];
      column.data().sort().each(function (d, j) {
        if (distinctArray.indexOf(d) == -1) {
          distinctArray.push(d);
        }
      });
      this.columns[i]['value_limits']['possibleValues'] = value ?
        _.filter(distinctArray, (item) => _.startsWith(item.toUpperCase(), value.toUpperCase())) :
        distinctArray
    });

  }

  onClearTextFilter(i: number) {
    this.columns[i]['value_limits']['applied'] = false;
    $('.column-checkbox-'.concat(i.toString())).prop('checked', false);
    this.columns[i]['value_limits'].selectedValues = []
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.column(i).search(
        '',
        true, false
      ).draw();
      this.emitFilterApplied.emit()
      // this.checkFilterApplied(dtInstance);
    });
  }

  onTextFilter(i: number) {
    /**
     *
     * TODO: Edit this for params
     {
      "search": {
        "search": "^MGQIVTMFE$|^GQIVTMFEA$|^QIVTMFEAL$"
      },
        "tool_group": "mhci",
        "name": "peptide",
        "source": "core"
    },
     * */
    const { name, filters, value_limits, source } = this.columns[i];
    const {selectedValues} = value_limits;
    let searchString = selectedValues.length == 1 ? selectedValues[0] : selectedValues.join('|')
    this.columns[i]['value_limits']['applied'] = !!(selectedValues.length);
    /** create searchObject */

    console.log(searchString)

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.column(i).search(
          searchString,
          true, false
        ).draw();
        this.emitFilterApplied.emit()
      }
    );
  }

  createRegexSafe(searchString: string) {
    /**
     * Helper function - used in onTextValueSelect
     * */
    return '^' + searchString.toString().split('+').join('\\+').split('-').join('\\-').split('[').join('\\[').split(']').join('\\]').split('*').join('\\*') + '$';
  }

  onCheckSelectAllTextValue(e: any, i: number) {
    /**
     * e: $event
     * i: column index
     * */
    const checked = e.target.checked;
    this.columns[i]['value_limits'].selectedValues = [];
    if (checked) { // select all
      _.forEach(this.columns[i]['value_limits']['possibleValues'], (value) => {
        this.columns[i]['value_limits'].selectedValues.push(
          this.createRegexSafe(value)
        );
      });
    }
  }

  onTextValueSelect(e: any, i: number) {
    const checked = e.target.checked, value = e.target.value;
    // console.log(`checked=${checked} || value=${value}`)
    if (checked) {
      this.columns[i]['value_limits'].selectedValues.push(
        this.createRegexSafe(value)
      );
    } else {
      _.remove(this.columns[i]['value_limits'].selectedValues, (possibleValue) => this.createRegexSafe(value) === possibleValue)
    }
    // console.log(this.columns[i]['value_limits']);
  }

  clearTableState(): void {

    this.emitResetTableState.emit()

    this.currentOrder = [...this.order]
    this.dtOptions.order = this.currentOrder

    this.dtTrigger.next(this.dtOptions);

    const initTool = this.tools[this.index];
    const unSubmittedTool = {
      ...initTool,
      appliedFilters: undefined
    }
    this._stateService.insertTool(unSubmittedTool, this.index);
    const table_state = [{table: 'peptide_table', columns: {}}]
    this._stateService.insertTableStateToStage(table_state, this.index);
    this._stateService.applyFiltersToParams(this.index, {});
    this.setColumnDisplayForm();
    this.setMinAndMax();
  }

  onSaveTableState() {
    // Create colObj
    // console.log(this.columns)
    // console.log(this.tool_group)
    // let colObj = columnListToObject(this.columns, this.tool_group)
    //
    // const table_state = [{table: 'peptide_table', columns: colObj}]
    // this._stateService.insertTableStateToStage(table_state, this.index)
    // this._stateService.insertTableStateToStage({columns: colObj}, this.index)

    this.emitSaveTableState.emit()
  }

  downloadResultFiles(data: string, format: string, fileName='result') {
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
    // var blob = new Blob([csvData], { type: contentType });
    const blob = new Blob([data]); // thx: http://stackoverflow.com/a/18925211

    // const blob = new Blob([data]); // thx: http://stackoverflow.com/a/18925211
    a.href = window.URL.createObjectURL(blob);
    switch (format) {
      case 'csv':
        a.download = `${fileName}.csv`;
        break;
      case 'tsv':
        a.download = `${fileName}.tsv`;
        break;
      case 'json':
        a.download = `${fileName}.json`;
        break;
      default:
        a.download = `${fileName}.txt`;
        break;
    }

    a.click();
  }

  // convertCurrentResultToTsv() : string {
  //   // currently csv instead of tsv
  //   let str = '';
  //   let header = ''
  //   _.forEach(this.columns, (col) => {
  //     // header+= `${col.name}\t`
  //     header+= `${col.name},`
  //   })
  //   str += `${header}\n`
  //   _.forEach(this.table_data, (row) => {
  //     console.log(row)
  //     // let newRow = row.toString().replace(/,/g, '\t')
  //     let newRow = row.toString()
  //     console.log(newRow)
  //     str += `${newRow}\n`
  //   })
  //   str += '\n'
  //   return str
  // }

  // convertCurrentResultToTSV2(data: any, visible_columns: any) : any {
  //   // currently csv instead of tsv
  //   let str = '';
  //   let header = ''
  //   _.forEach(this.columns, (col, i) => {
  //     if(visible_columns[i]) {
  //       header+= `${col.name},` // header+= `${col.name}\t`
  //     }
  //   })
  //   str += `${header}\n`
  //   _.forEach(data, (row) => {
  //     const tempRow: any = []
  //     _.forEach(row, (val, i) => {
  //       if(visible_columns[i]) {
  //         // let newRow = row.toString().replace(/,/g, '\t')
  //         tempRow.push(val)
  //       }
  //     })
  //     let newRow = tempRow.toString()
  //     str += `${newRow}\n`
  //   })
  //   str += '\n'
  //   return str
  // }

  convertCurrentResultToJSON() : any {
    return _.map(this.table_data, (row) => {
      let obj: any = {}
      _.forEach(row, (value, i) => {
        obj[this.columns[i].display_name] = value
      })
      return obj
    })
  }

  convertCurrentResultToJSON2(data: any, visible_columns: any) : any {
    return _.map(data, (row) => {
      let obj: any = {}
      _.forEach(row, (value, i) => {
        if(visible_columns[i]) {
          obj[this.columns[i].display_name] = value
        }
      })
      return obj
    })
  }

  downloadAllTsv() {
    /**
     * JSON -> TSV -> File
     * */
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      /** dtInstance.order() -> lodash order */
      const order = dtInstance.order()

      const lodashOrder = this.convertDTOrderToLodash(order)

      /** get and sort data */
      const data = this.convertCurrentResultToJSON();

      const sortedData = _.orderBy(data, ...lodashOrder)

      console.log(sortedData)

      const config = {
        delimiter: "\t",
      }
      /** convert to tsv and download result */
      const csvData = this.papa.unparse(sortedData, config)
      console.log(csvData)
      this.downloadResultFiles(csvData, 'tsv', fileName)
    })
  }

  downloadAllCsv() {
    /**
     * JSON -> CSV -> File
     * */
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      /** dtInstance.order() -> lodash order */
      const order = dtInstance.order()

      const lodashOrder = this.convertDTOrderToLodash(order)

      /** get and sort data */
      const data = this.convertCurrentResultToJSON();

      const sortedData = _.orderBy(data, ...lodashOrder)
      /** download sorted data */
      const csvData = this.papa.unparse(sortedData)
      console.log(csvData)
      this.downloadResultFiles(csvData, 'csv', fileName)
    })
  }

  downloadAllJSON() {
    /**
     * JSON -> File
     * */
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      /** dtInstance.order() -> lodash order */
      const order = dtInstance.order()

      const lodashOrder = this.convertDTOrderToLodash(order)

      /** get and sort data */
      const data = this.convertCurrentResultToJSON();

      const sortedData = _.orderBy(data, ...lodashOrder)

      /** download sorted data */
      this.downloadResultFiles(JSON.stringify(sortedData, null, 2), 'json', fileName)
    })

  }

  downloadTSV() {
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}_displayed`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        const peptide_table = dtInstance.rows({page: 'current', search: 'applied'}).data();
        console.log(peptide_table)
        const d = dtInstance.columns().visible();
        console.log(d)
        // const data = this.convertCurrentResultToTSV2(peptide_table, d)
        const data = this.convertCurrentResultToJSON2(peptide_table, d)
        const config = {
          delimiter: "\t",
        }
        const csvData = this.papa.unparse(data, config)
        console.log(csvData)
        this.downloadResultFiles(csvData, 'tsv', fileName)
      }
    );
  }

  downloadCSV() {
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}_displayed`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        const peptide_table = dtInstance.rows({page: 'current', search: 'applied'}).data();
        console.log(peptide_table)
        const d = dtInstance.columns().visible();
        console.log(d)
        // const data = this.convertCurrentResultToTSV2(peptide_table, d)
        const data = this.convertCurrentResultToJSON2(peptide_table, d)
        const csvData = this.papa.unparse(data)
        console.log(csvData)
        this.downloadResultFiles(csvData, 'csv', fileName)
      }
    );
  }

  downloadJSON() {
    const fileName = `${this.table_name}_${this.pipelineId.substring(0, 8)}_displayed`

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const peptide_table = dtInstance.rows( {page:'current', search: 'applied'} ).data();
      console.log(peptide_table)
      const d = dtInstance.columns().visible();
      console.log(d)
      const data = this.convertCurrentResultToJSON2(peptide_table, d)
      console.log(data)
      this.downloadResultFiles(JSON.stringify(data, null, 2), 'json', fileName)
      }
    );
  }

  convertDTOrderToLodash(order: (string | number)[][]) {
    /**
     Helper function for download all
     convert dt table order -> lodash orderBy

     * https://datatables.net/reference/api/order()
     * https://lodash.com/docs/4.17.15#orderBy

     * */
    // console.log(this.columns)
    const tempVal = _.map(order, (o) => {
      const a = this.columns[o[0]]
      // console.log(a)
      return [a['display_name'], o[1]]
    })

    const temp1: string[] = []
    const temp2: string[] = []
    _.forEach(tempVal, (o) => {
      temp1.push(o[0])
      temp2.push(o[1])
    })

    return [temp1, temp2]
  }
}
