import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

export const environment = {
  name: 'PROD',
  production: true,
  api_url: 'https://api-nextgen-tools.iedb.org',
  api_port: '443',
  auth_endpoint: '/token/',
  auth_refresh_endpoint: '/token/refresh/',
  upload_endpoint: '/import/',
  optionalModules: [
    NgxGoogleAnalyticsModule.forRoot('G-MFNL7MJ8EV'),
    NgxGoogleAnalyticsRouterModule
  ],
};
