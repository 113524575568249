import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import * as d3 from "d3";
import {BehaviorSubject} from "rxjs";
import _ from "lodash";

@Component({
  selector: 'app-d3-plot',
  templateUrl: './d3-plot.component.html',
  styleUrls: ['./d3-plot.component.scss']
})
export class D3PlotComponent implements AfterViewInit {
  @ViewChild("containerScatterPlot") element: ElementRef | undefined

  /** initialize a private variable _data, it's a BehaviorSubject */
    // private _data = new BehaviorSubject<IAData[]>([]);
  private _data = new BehaviorSubject<any>([]);

  @Input() plotData!: any
  /**
   {
   <allele_name>: {
   <peptidea>: float, <peptideb>: float,
   <sequenceA>: string, <sequenceB>: string
   }
   }
   * */
  plottedPoints: any = []
  selectedAllele = ''
  selectedSeries = ''
  uniqueAlleles: string[] = []
  series: string[] = []

  // change data to use getter and setter
  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  private svg: any; // private svg?: d3.Selection<SVGElement, {}, d3.BaseType, any>
  private focus: any;

  private margin = {top: 40, right: 70, bottom: 50, left: 70}
  private width = 520 - this.margin.left - this.margin.right
  private height = 470 - this.margin.top - this.margin.bottom

  private htmlElement?: HTMLElement;

  constructor() {}

  ngOnInit(): void {
    // this.createSvg();
    // this.drawPlot();
    console.log(this.plotData)


    this.series = _.chain(this.plotData)
      .map((series) => series.series_name)
      .uniq()
      .value()

    // console.log(this.series)
    this.selectedSeries = this.series[0]

    this.uniqueAlleles = this.uniqueAlleles = _.chain(this.plotData)
      .map((series) =>series.allele)
      .uniq()
      .value()

    // console.log(this.uniqueAlleles)
    this.selectedAllele = this.uniqueAlleles[0]
  }

  ngAfterViewInit() {

    this.createSvg()
    this.drawPlot()
  }

  private createSvg(): void {
    this.htmlElement = this.element?.nativeElement;

    // const zoom: d3.ZoomBehavior<any, any> = d3.zoom()
    //   .scaleExtent([-1, 3])
    //   .on("zoom", event => {
    //
    //     this.svg?.attr('transform', event.transform)
    //   })

    // @ts-ignore
    this.svg = d3.select(this.htmlElement)
      .append("svg")
      .attr("width", this.width + (this.margin.left + this.margin.right))
      .attr("height", this.height + (this.margin.top + this.margin.bottom))
      .attr("class", "allele-plot")

    this.focus = this.svg.append("g")
      .attr("class", "focus")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")")
    // .call(zoom)

  }

  private drawPlot(
    selectedAllele: string = this.selectedAllele,
    selectedSeries: string = this.selectedSeries,
  ): void {
    /**
     * Set up plottedPoints
     [
     {
     <peptidea>: float, <peptideb>: float,
     <sequenceA>: string, <sequenceB>: string
     }
     ]
     * */
      // _.forEach(_.zip(['a', 'b', 'c'], [1, 2, 3]), _.spread(function(name, score){ /* */ }));

    let selected = _.find(this.plotData, {series_name: selectedSeries, allele: selectedAllele})
    // console.log(selected)
    if (selected) {
      // zip
      this.plottedPoints = []
      _.forEach(
        _.zip(selected!['x_values'], selected!.y_values, selected!.x_labels, selected!.y_labels),
        _.spread((x_val, y_val, x_lab, y_lab) => {
          this.plottedPoints.push({
            peptidea: x_val, peptideb: y_val,
            sequenceA: x_lab, sequenceB: y_lab
          })
        }))

      /**
       * Set up D3 elements
       * */

      // this.htmlElement = this.element?.nativeElement;
      /** TOOLTIPS
       * tooltip - coordinates
       * seqTooltip - sequences
       * */
        // @ts-ignore
      let tooltip = d3.select(this.htmlElement)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("padding", "5px")

      // @ts-ignore
      let seqTooltip = d3.select(this.htmlElement)
        .append("div")
        .style("position", "absolute")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

      // Title
      this.focus.append("text")
        .attr("x", (this.width / 2))
        .attr("y", 0 - (this.margin.top / 2))
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("text-decoration", "underline")
        .text(selected.title);
      // Add X axis

      const x = d3.scaleLinear()
        .domain(selected.x_range)
        .range([0, this.width]);

      const xAxis = this.focus.append("g")
        .attr("transform", "translate(0," + this.height + ")")
        .call(d3.axisBottom(x));

      // Add X axis label:
      this.focus.append("text")
        .attr("text-anchor", "middle")
        .attr("x", (this.width / 2))
        .attr("y", 420)
        .text(selected.x_title); // add here

      // Add Y axis
      const y = d3.scaleLinear()
        .domain(selected.y_range)
        .range([this.height, 0]);

      const yAxis = this.focus.append("g")
        .call(d3.axisLeft(y));

      // Y axis label:
      this.focus.append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", -this.margin.left + 20)
        // .attr("y", (this.height / 2))
        .attr("x", -150)
        .text(selected.y_title) // add here

      /** draw line old */
      // const line = this.focus.append('line')
      //   .style('stroke', 'black')
      //   .style("stroke-dasharray","5,5")//dashed array for line
      //   .style("stroke-width", 1)
      //   .attr("x1", x(0))
      //   .attr("y1", y(0))
      //   .attr("x2", x(1))
      //   .attr("y2", y(1))

      /** draw line */
        // let lineData = {x1: -1, y1: -1, x2: 1, y2: 1, strokeWidth: 1}
      let lineData = {
          x1: selected.x_range[0],
          y1: selected.y_range[0],
          x2: selected.x_range[1],
          y2: selected.y_range[1],
          strokeWidth: 1
        }
      const line = this.focus.append('g')
        .attr("clip-path", "url(#clip)")
        .attr('class', 'line-group')
        .selectAll('line').data([lineData])
        .enter().append("line")
        .attr("x1", (d: any) => (x(d.x1)))
        .attr("y1", (d: any) => (y(d.y1)))
        .attr("x2", (d: any) => (x(d.x2)))
        .attr("y2", (d: any) => (y(d.y2)))
        .style('stroke', 'grey')
        .style("stroke-dasharray", "5,5")//dashed array for line
        .style("stroke-width", (d: any) => d.strokeWidth)

      let lineDataY = {x1: -1e100, y1: 0, x2: 1e100, y2: 0, strokeWidth: 1}
      const lineY = this.focus.append('g')
        .attr("clip-path", "url(#clip)")
        .attr('class', 'line-group')
        .selectAll('line').data([lineDataY])
        .enter().append("line")
        .attr("x1", (d: any) => (x(d.x1)))
        .attr("y1", (d: any) => (y(d.y1)))
        .attr("x2", (d: any) => (x(d.x2)))
        .attr("y2", (d: any) => (y(d.y2)))
        .style('stroke', 'grey')
        // .style("stroke-dasharray","5,5")//dashed array for line
        .style("stroke-width", (d: any) => d.strokeWidth)

      let lineDataX = {x1: 0, y1: -1e100, x2: 0, y2: 1e100, strokeWidth: 1}
      const lineX = this.focus.append('g')
        .attr("clip-path", "url(#clip)")
        .attr('class', 'line-group')
        .selectAll('line').data([lineDataX])
        .enter().append("line")
        .attr("x1", (d: any) => (x(d.x1)))
        .attr("y1", (d: any) => (y(d.y1)))
        .attr("x2", (d: any) => (x(d.x2)))
        .attr("y2", (d: any) => (y(d.y2)))
        .style('stroke', 'grey')
        // .style("stroke-dasharray","5,5")//dashed array for line
        .style("stroke-width", (d: any) => d.strokeWidth)


      // 1) mouseover - insert the data point’s value and place it near the mouse.
      const mouseover = (event: any, d: any, n: any) => {
        tooltip
          .transition()
          .style("opacity", 1)

        seqTooltip
          .transition()
          .style("opacity", 1)
        // d3.select(this)
        //   .style("stroke", "black")
        //   .style("opacity", 1)
      }

      /** 2) mousemove
       * .toLocaleString() - format w/ commas for thousands separator
       *
       * */
      const mousemove = function (event: any, d: any, n: any) {
        tooltip
          .html(`Coordinates: (${d.peptidea}, ${d.peptideb})`)
          .style("left", (event.x) / 2 + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
          .style("top", (event.y) / 2 + "px")
          .style('margin-top', '10px')

        // const [left, top] = d3.pointer(event);

        seqTooltip.html(`peptide A: <span style="font-family: monospace">${d.sequenceA}</span> <br>
                               peptide B: <span style="font-family: monospace">${d.sequenceB}</span>`)
                  .style("left", (event.pageX - 400) + "px")
                  .style("top", (event.pageY - 100) + "px")
        // .style("left", (event.x+70) + "px")
        // .style("top", (event.y) + "px")
      }

      // 3) mouseleave - A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
      const mouseleave = function (event: any, d: any, n: any) {
        tooltip
          .transition()
          .style("opacity", 0)

        seqTooltip
          .transition()
          .style("opacity", 0)
      }

      // Add a clipPath: everything out of this area won't be drawn.
      const clip = this.svg.append("defs").append("SVG:clipPath")
        .attr("id", "clip")
        .append("SVG:rect")
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("x", 0)
        .attr("y", 0)


      // Create the dot/scatter variable: where both the circles and the brush take place
      // const scatter = this.svg.append('g')
      const dots = this.focus.append('g')
        .attr("clip-path", "url(#clip)");
      // add dots

      // .data(this.plotData[selectedAllele])
      dots.selectAll("dot")
        .data(this.plottedPoints)
        .enter()
        .append("circle")
        .attr("r", 4.5)
        .attr("cx", (d: any) => x(d.peptidea))
        .attr("cy", (d: any) => y(d.peptideb))
        .style("opacity", .5)
        .style("fill", "#61a3a9")
        .attr("stroke", "#32CD32")
        .attr("stroke-width", 1.5)
        .on('mouseover', mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)


      // A function that updates the chart when the user zoom and thus new boundaries are available
      function updateChart(event: any, d: any) {

        // recover the new scale
        let newX = event.transform.rescaleX(x);
        let newY = event.transform.rescaleY(y);

        let scaleMultiplier = event.scale

        // update axes with these new boundaries
        xAxis.call(d3.axisBottom(newX))
        yAxis.call(d3.axisLeft(newY))

        // update circle position
        dots
          .selectAll("circle")
          .attr("cx", (d: any) => newX(d.peptidea))
          .attr("cy", (d: any) => newY(d.peptideb))

        // redraw lines
        line
          .attr("x1", (d: any) => (newX(d.x1)))
          .attr("y1", (d: any) => (newY(d.y1)))
          .attr("x2", (d: any) => (newX(d.x2)))
          .attr("y2", (d: any) => (newY(d.y2)))
          .attr('stroke-width', (d: any) => d.strokeWidth * scaleMultiplier)

        lineX
          .attr("x1", (d: any) => (newX(d.x1)))
          .attr("y1", (d: any) => (newY(d.y1)))
          .attr("x2", (d: any) => (newX(d.x2)))
          .attr("y2", (d: any) => (newY(d.y2)))
          .attr('stroke-width', (d: any) => d.strokeWidth * scaleMultiplier)

        lineY
          .attr("x1", (d: any) => (newX(d.x1)))
          .attr("y1", (d: any) => (newY(d.y1)))
          .attr("x2", (d: any) => (newX(d.x2)))
          .attr("y2", (d: any) => (newY(d.y2)))
          .attr('stroke-width', (d: any) => d.strokeWidth * scaleMultiplier)
      }

      // Set the zoom and Pan features: how much you can zoom, on which part, and what to do when there is a zoom
      const zoom = d3.zoom()
        .scaleExtent([1, 100])  // This control how much you can unzoom (x0.5) and zoom (x20)
        .extent([[0, 0], [this.width, this.height]])
        .on("zoom", updateChart);

      // This add an invisible rect on top of the chart area. This rect can recover pointer events: necessary to understand when the user zoom
      this.focus.append("rect")
        .attr("width", this.width)
        .attr("height", this.height)
        .lower()
        .style("fill", "none")
        .style("pointer-events", "all")
        .attr('transform', 'translate(' + 0 + ',' + 0 + ')');

      this.focus.call(zoom)
    }
    else { // if there is no 'selected', then show 'No Data Available' in the plot
      this.svg.append("text")
        .attr("x", (this.width / 2))
        .attr("y", (this.height / 2))
        .text('No Data Available'); // add here
    }
  }

  // filterUniqueAlleles(selectedSeriesName: string): string[] {
  //   return _.chain(this.plotData)
  //     .filter(series => series.series_name == selectedSeriesName)
  //     .map(series => series.allele)
  //     // .uniqBy({series_name: selectedSeriesName})
  //     .value()
  // }

  onAllelePlotChange(e: any) {
    e.preventDefault();
    this.selectedAllele = e.target.value;

    d3.selectAll("svg.allele-plot").remove();
    d3.selectAll(".tooltip").remove();

    this.createSvg()

    this.drawPlot(this.selectedAllele, this.selectedSeries)
  }

  onSeriesPlotChange(e: any) {
    e.preventDefault();
    this.selectedSeries = e.target.value;
    // this.uniqueAlleles = this.filterUniqueAlleles(this.selectedSeries)
    // this.selectedAllele = this.uniqueAlleles[0]

    d3.selectAll("svg.allele-plot").remove();
    d3.selectAll(".tooltip").remove();

    this.createSvg()

    this.drawPlot(this.selectedAllele, this.selectedSeries)
  }
}
