<div class="container-fluid p-2 mb-5 d-flex flex-column">
  <!--  Table buttons -->
  <div class="d-flex flex-row p-2 align-self-center" role="group" aria-label="button-groups">

    <button class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"
            type="button"
            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="animals">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M.5 8a.5.5 0 0 1 .5.5V12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8.5a.5.5 0 0 1 1 0V12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.5A.5.5 0 0 1 .5 8z"/>
        <path fill-rule="evenodd"
              d="M5 7.5a.5.5 0 0 1 .707 0L8 9.793 10.293 7.5a.5.5 0 1 1 .707.707l-2.646 2.647a.5.5 0 0 1-.708 0L5 8.207A.5.5 0 0 1 5 7.5z"/>
        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8A.5.5 0 0 1 8 1z"/>
      </svg>
      Download
    </button>
    <mat-menu #animals="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="vertebrates">All rows</button>
      <button mat-menu-item [matMenuTriggerFor]="invertebrates">Displayed rows</button>
    </mat-menu>

    <mat-menu #vertebrates="matMenu">
      <button mat-menu-item (click)="downloadAllTsv()">TSV</button>
      <button mat-menu-item (click)="downloadAllCsv()">CSV</button>
      <button mat-menu-item (click)="downloadAllJSON()">JSON</button>
    </mat-menu>
    <mat-menu #invertebrates="matMenu">
      <button mat-menu-item (click)="downloadTSV()">TSV</button>
      <button mat-menu-item (click)="downloadCSV()">CSV</button>
      <button mat-menu-item (click)="downloadJSON()">JSON</button>
    </mat-menu>

<!--    <button type="button"-->
<!--            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"-->
<!--            (click)="getTableState()">-->
<!--      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-share" fill="currentColor"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M11.724 3.947l-7 3.5-.448-.894 7-3.5.448.894zm-.448 9l-7-3.5.448-.894 7 3.5-.448.894z"/>-->
<!--        <path fill-rule="evenodd"-->
<!--              d="M13.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-11-6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>-->
<!--      </svg>-->
<!--      Share-->
<!--    </button>-->
    <button type="button"
            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"
            (click)=clearTableState()>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
           class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
      </svg>
      Reset Table
    </button>
    <button type="button"
            class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center"
            (click)="onOpenDisplayColumnModal()">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-table" fill="currentColor"
           xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
              d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
      </svg>
      Display Columns
    </button>
    <button *ngIf="!tableStateSaving; else savingButton"
            type="button"
            [ngbTooltip]="inputChange ? saveTableStateTipContent : null"
            [disabled]="!inputChange"
            [ngClass]="inputChange ? 'btn btn-warning d-flex flex-column justify-content-center align-items-center' : 'btn btn-link text-dark d-flex flex-column justify-content-center align-items-center'"
            (click)="onSaveTableState()">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-save"
           viewBox="0 0 16 16">
        <path
          d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
      </svg>
      Save Table State
    </button>
    <ng-template #saveTableStateTipContent>
      Please save table state in order to pipe to the next tool.
    </ng-template>
    <ng-template #savingButton>
      <button type="button"
              [style.background-color]="'#f0ad4d'"
              class="btn btn-link text-dark d-flex flex-column justify-content-center align-items-center">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden sr-only">Loading...</span>
        </div>
        Saving...
      </button>
    </ng-template>

  </div>
  <!--   Table -->
  <div class="row justify-content-center">
    <div class="col-auto">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
             [style.border-color]="inputChange ? '#f0ad4d' : null"
             class='table table-responsive table-fit table-hover table-bordered px-2 sticky-header'
             [id]="'DataTables_'.concat(tool_group).concat(table_name)">
        <colgroup>
          <col
            *ngFor="let column of visibleColumns;" [id]="'column'.concat(column.name)"
            [ngStyle]="{'background-color': (dataToPipe === 'peptide_table'.concat('.', column.name) && table_name==='peptide_table') ||
                                            (dataToPipe === 'peptide_gene_summary_table'.concat('.', column.name) && table_name==='peptide_gene_summary_table') ||
                                            ([dataToPipe, dataToPipe2].includes( 'unique_peptide'.concat('.', column.name)) && table_name==='unique_peptide') ||
                                            ([dataToPipe, dataToPipe2].includes( 'peptide'.concat('.', column.name)) && table_name==='peptide') ? '#0275d8' : null,}"
          />
        </colgroup>

        <thead>
        <tr>
          <th *ngFor="let column of columns; index as i;" class="header">
            {{column.display_name}}

              <div ngbDropdown
                   #filterDropdown="ngbDropdown"
                   (openChange)="checkClosedDropdown($event)"
                   *ngIf="column['value_limits']"
                   class="d-inline-block"
                   container="body"
                   display="dynamic">
                <button [className]="column['value_limits']!['applied'] ? 'btn btn-sm btn-outline-primary' : 'btn btn-sm bg-light'"
                        ngbDropdownToggle
                        (click)="stopProp($event); checkOpenedDropdown(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel"
                       viewBox="0 0 16 16">
                    <path
                      d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                  </svg>
                </button>
                <div ngbDropdownMenu (click)="stopProp($event)">
                  <button ngbDropdownItem (click)="onSortColumns(i, 'asc')">Sort A&rarr;Z</button>
                  <button ngbDropdownItem (click)="onSortColumns(i, 'desc')">Sort Z&rarr;A</button>
                  <div class="dropdown-divider"></div>
                  <!--      text filter, number filter  form      -->
                  <div>
                    <!--                  <div class="px-1 py-3">-->
                    <ng-container *ngIf="['int', 'float'].includes(column['type']); else stringFilterTemplate;">
                      <form class="number-filter-form p-2">
                        <div class="form-group">
                          <label>Min:</label>
                          <input type="number"
                                 [className]="'min'.concat(i.toString()).concat(tool_group).concat(table_name).concat(' form-control')"
                                 [placeholder]="columns[i]['value_limits']!['min']"
                                 [value]="columns[i]['value_limits']!['minValue']">
                        </div>

                        <div class="form-group">
                          <label>Max: </label>
                          <input type="number" class="form-control"
                                 [className]="'max'.concat(i.toString()).concat(tool_group).concat(table_name).concat(' form-control')"
                                 [placeholder]="columns[i]['value_limits']!['max']"
                                 [value]="columns[i]['value_limits']!['maxValue']">
                        </div>

                        <div class="flex-row d-flex">
                          <button type="button" class="btn btn-secondary"
                                  (click)="onClearNumberFilter(i); filterDropdown.close()">Clear
                          </button>
                          <button type="button" class="btn btn-primary"
                                  (click)="onNumberFilter(i); filterDropdown.close();">
                            OK
                          </button>
                        </div>
                      </form>
                    </ng-container>
                    <ng-template #stringFilterTemplate>

                      <form class="string-filter-form">
                        <div class="form-group row p-2">
                          <label [for]="'textFilter'.concat(i.toString())">Filter by value: </label>
                          <div class="input-group input-group-sm">
                            <input type="text" class="form-control"
                                   [id]="'textFilter'.concat(i.toString())"
                                   (keyup)="onFilterPossibleValues($event, i)">
                            <div class="input-group-append">
                          <span class="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-search" viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                          </span>
                            </div>
                          </div>
                        </div>
                        <ul class="list-group" id="text-filter-list-group">
                          <li *ngIf="column['value_limits']!['possibleValues']?.length" class="list-group-item">
                            <!--                          [className]="'form-check-input select-all-checkbox'"-->
                            <input type="checkbox"
                                   class="form-check-input me-1 select-all-checkbox"
                                   (change)="onCheckSelectAllTextValue($event, i)"
                                   [checked]="columns[i]['value_limits']!['selectedValues'].length === columns[i]['value_limits']!['possibleValues']?.length">
                            (Select All)
                          </li>
                          <li *ngFor="let value of column['value_limits']!['possibleValues'];" class="list-group-item"
                              [ngStyle]="column['name'] === 'peptide' ? {fontFamily: 'courier'} : null">
                            <input type="checkbox"
                                   [className]="'form-check-input me-1 column-checkbox-'.concat(i.toString())"
                                   [id]="value.concat('-column-'.concat(i.toString()))"
                                   [checked]="column['value_limits']!['selectedValues'].includes(createRegexSafe(value))"
                                   [value]="value" (change)="onTextValueSelect($event, i)">
                            {{value}}
                          </li>
                        </ul>


                        <div class="flex-row d-flex">
                          <button type="button" class="btn btn-secondary"
                                  (click)="onClearTextFilter(i); filterDropdown.close()">
                            Clear
                          </button>
                          <button type="button" class="btn btn-primary"
                                  (click)="onTextFilter(i); filterDropdown.close();">OK
                          </button>
                        </div>
                      </form>
                    </ng-template>
                  </div>
                </div>
              </div>

            <span *ngFor="let o of currentOrder; index as priority" class="badge rounded-pill text-bg-secondary mx-1" ngbTooltip="number indicates sort order" placement="bottom-right">
            <span *ngIf="currentOrder.length > 1 && o[0] === i">{{priority + 1}}</span>
          </span>
          </th>
        </tr>
        </thead>

      </table>
    </div>
  </div>

</div>

<!--Display Column Modal-->
<ng-template #displayColumnModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-column-display-title">Select Columns to Display</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="columnDisplayForm">
      <div class="d-flex flex-column" *ngFor="let uniqSrc of uniqSources">
        <h5 class="d-flex justify-content-center">
          {{uniqSrc.split('.')[1] || uniqSrc.split('.')[0] | titlecase}}
          <!-- NOTE: commented out code https://gitlab.lji.org/iedb/tools/tools-redesign/ar-redesign-prototype/-/issues/592-->
          <!--          {{uniqSrc.split('.')[1] | titlecase}} -->
        </h5>
        <ng-container *ngFor="let o of columns" class="form-check">
          <div *ngIf="uniqSrc===o['source']"
               [ngbTooltip]="columnDisplayForm.controls[o.filterKey].disabled ? tipContent : ''"
               placement="bottom-left">
            <input type="checkbox" class="form-check-input" [id]="o.filterKey"
                   [formControlName]=o.filterKey>
            <label class="form-check-label fw-bold px-2"
                   [for]="o.filterKey">{{o.display_name}}</label>
            <small>
              {{o.description}}
            </small>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onDefaultColumns()">Reset</button>
    <button type="button" class="btn btn-primary" (click)="onSetDisplayColumns()">Display</button>
  </div>
</ng-template>
<ng-template #tipContent>
  <p [style]="{whiteSpace: 'pre-line'}">
  Sorted column and columns that can be used as inputs downstream cannot be hidden.
  </p>
</ng-template>
