<div class="container p-3" id="home-component">
  <div class="row">
    <!--    Side Bar -->
    <div class="col-3 d-flex flex-column">

      <div class="bg-secondary bg-opacity-50 m-3 p-3">
        <h5 class="text-white"> Announcements</h5>
<!--        <p *ngFor="let i of topics">-->
<!--          {{i.fancy_title}}-->
<!--        </p>-->
        <ul class="nav">

<!--          <li class="nav-item" *ngFor="let i of announcements">-->
<!--            &lt;!&ndash; OLD  &ndash;&gt;-->
<!--            <a class="nav-link" href="https://help.iedb.org/hc/en-us/articles/27040672746907" target="_blank">-->
<!--            {{i}}-->
<!--            </a>-->
<!--          </li>-->

          <li class="nav-item" *ngFor="let i of releases">
            <!-- use 'releases' instead of 'announcements'-->
            <a class="nav-link" [href]="i.url" target="_blank">
              {{i.title}}
            </a>
          </li>
        </ul>
      </div>

      <div class="bg-secondary bg-opacity-50 m-3 p-3">
        <h5 class="text-white"> Appearances & Events</h5>

        <table>
          <tbody style="font-size: 0.75rem">
          <tr>
            <td>AIRR Community Meeting</td>
            <td class="date-cell">June 3-6, 2024</td>
          </tr>

<!--          OLD/When there is a IEDB Virtual User Workshop -->
<!--          <tr>-->
<!--            <td>-->
<!--              <a href="http://workshop.iedb.org/" target="_blank">-->
<!--                Virtual User Workshop-->
<!--              </a>-->
<!--            </td>-->
<!--            <td class="date-cell">Nov 1-3, 2023</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td>-->
<!--              * Register-->
<!--              <a href="https://www.surveymonkey.com/r/SW69GB3" target="_blank">-->
<!--                Here-->
<!--              </a>-->
<!--            </td>-->
<!--          </tr>-->

          <tr>
            <td>IEDB Virtual User Workshop</td>
            <td class="date-cell">Nov 5-7, 2024</td>
          </tr>
          <tr>
            <td>Festival of Biologics</td>
            <td class="date-cell">April 23-25, 2025</td>
          </tr>
          <tr>
            <td>AACR 2025</td>
            <td class="date-cell">April 25-30, 2025</td>
          </tr>
          <tr>
            <td>Immunology 2025 </td>
            <td class="date-cell">May 3-7, 2025</td>
          </tr>
          </tbody>
        </table>


<!--        <ul class="nav">-->
<!--        <li class="nav-item" *ngFor="let i of events">-->

<!--&lt;!&ndash;            <a class="nav-link" [href]="'https://discuss.iedb.org/t/'.concat(i.id)" target="_blank">&ndash;&gt;-->
<!--&lt;!&ndash;            {{i.title}}&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--          <a [attr.href]="i.href" [className]="i.class" target="_blank">-->
<!--            {{i.name}}-->
<!--          </a>-->
<!--          <a class="disabled">* HEllo world</a>-->
<!--        </li>-->
<!--        </ul>-->

<!--        <div class="d-flex flex-row">-->
<!--          <div class="flex-column mr-auto">-->
<!--            Antibody Society-->
<!--          </div>-->
<!--          <div class="flex-column">-->
<!--            Dec 9-13-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="d-flex flex-row">-->
<!--          <div class="flex-column mr-auto">-->
<!--            AAAAI 2020-->
<!--          </div>-->
<!--          <div class="flex-column">-->
<!--            Mar 11-15-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="d-flex flex-row">-->
<!--          <div class="flex-column mr-auto">-->
<!--            AAI 2020-->
<!--          </div>-->
<!--          <div class="flex-column">-->
<!--            May 13-16-->
<!--          </div>-->
<!--        </div>-->

      </div>

      <div class="bg-secondary bg-opacity-50 m-3 d-flex flex-column p-3">
        <h5 class="text-white align-self-center">Additional Resources</h5>
        <div class="d-flex flex-column">
          <a type='button' class="btn btn-outline-primary bg-white m-3 text-black-50" [href]="swaggerUrl"
             target="_blank">
            API
          </a>
<!--          <button type='button' class="btn btn-outline-primary bg-white m-3 text-black-50" routerLink="/benchmark">-->
<!--            Benchmarking-->
<!--          </button>-->
          <button type='button' class="btn btn-outline-primary bg-white m-3 text-black-50" routerLink="/download-all">
            Downloads
          </button>
        </div>
      </div>
    </div>

    <!-- Main Component -->
    <div class="col-9 d-flex flex-column">
      <h3 class="align-self-center text-info">Welcome to the Next-Generation IEDB Tools site!</h3>
      <p class="align-self-center">As a companion site to the Immune Epitope Database (IEDB), this site provides a
        collection of tools for the prediction and analysis of immune epitopes.</p>
      <a class="btn btn-success align-self-center text-white mx-auto" style="width: 100%" routerLink="/docs" target="_blank"><b>New User?</b> Learn to
        use the website here!
      </a>

      <!--      T Cell Form -->
      <div class="bg-info bg-opacity-50 d-flex flex-column p-3" appDnd (fileDropped)="onFileDropped($event)">
        <h5>
          <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
          <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'tc1'}"><u>T Cell Prediction - Class I</u></a>
        </h5>
        <div class="row">
          <div class="col-sm-12">
            <label class="fst-italic">MHC class I binding affinity, TAP processing, and Immunogenicity predictions</label>
<!--            <div class="btn-group btn-group-sm float-end class-option-btn-group">-->
<!--              <button [ngClass]="classI ? 'btn btn-primary' : 'btn btn-outline-primary'" type='button'-->
<!--                      (click)="classI=true">I-->
<!--              </button>-->
<!--              <button [ngClass]="classI ? 'btn btn-outline-secondary disabled' : 'btn btn-primary disabled'" type='button'-->
<!--                      (click)="classI=false">II-->
<!--              </button>-->
<!--            </div>-->

          </div>
        </div>
        <form [formGroup]="tCellForm">
          <div class="d-flex form-group row">
            <div class="mt-2 col-sm-12">
              <textarea class="form-control"
                        id="sequenceFormControlTextArea"
                        rows="5"
                        formControlName="sequences"
                        [placeholder]="'Type/paste/drag sequences (max: 1,000,000 characters) into this box or click \'Run\' to use the example sequence:\n >SARS2 spike glycoprotein\nMFVFLVLLPLVSSQCVNLTTRTQLPPAYTNSFTRGVYYPDKVFRSSVLHSTQDLFLPFFSNVTWFHAIHVSGTNGTKRFDNPVLPFNDGVYFASTEKSNIIRGWIFGTTLDSKTQSLLIVNNATNVVIKVCEFQFCNDPFLGVYYHKNNKSWMESEFRVYSSANNCTFEYVSQPFLMDLEGKQGNFKNLREFVFKNIDGYFKIYSKHTPINLVRDLPQGFSALEPLVDLPIGINITRFQTLLALHRSYLTPGDSSSGWTAGAAAYYVGYLQPRTFLLKYNENGTITDAVDCALDPLSETKCTLKSFTVEKGIYQTSNFRVQPTESIVRFPNITNLCPFGEVFNATRFASVYAWNRKRISNCVADYSVLYNSASFSTFKCYGVSPTKLNDLCFTNVYADSFVIRGDEVRQIAPGQTGKIADYNYKLPDDFTGCVIAWNSNNLDSKVGGNYNYLYRLFRKSNLKPFERDISTEIYQAGSTPCNGVEGFNCYFPLQSYGFQPTNGVGYQPYRVVVLSFELLHAPATVCGPKKSTNLVKNKCVNFNFNGLTGTGVLTESNKKFLPFQQFGRDIADTTDAVRDPQTLEILDITPCSFGGVSVITPGTNTSNQVAVLYQDVNCTEVPVAIHADQLTPTWRVYSTGSNVFQTRAGCLIGAEHVNNSYECDIPIGAGICASYQTQTNSPRRARSVASQSIIAYTMSLGAENSVAYSNNSIAIPTNFTISVTTEILPVSMTKTSVDCTMYICGDSTECSNLLLQYGSFCTQLNRALTGIAVEQDKNTQEVFAQVKQIYKTPPIKDFGGFNFSQILPDPSKPSKRSFIEDLLFNKVTLADAGFIKQYGDCLGDIAARDLICAQKFNGLTVLPPLLTDEMIAQYTSALLAGTITSGWTFGAGAALQIPFAMQMAYRFNGIGVTQNVLYENQKLIANQFNSAIGKIQDSLSSTASALGKLQDVVNQNAQALNTLVKQLSSNFGAISSVLNDILSRLDKVEAEVQIDRLITGRLQSLQTYVTQQLIRAAEIRASANLAATKMSECVLGQSKRVDFCGKGYHLMSFPQSAPHGVVFLHVTYVPAQEKNFTTAPAICHDGKAHFPREGVFVSNGTHWFVTQRNFYEPQIITTDNTFVSGNCDVVIGIVNNTVYDPLQPELDSFKEELDKYFKNHTSPDVDLGDISGINASVVNIQKEIDRLNEVAKNLNESLIDLQELGKYEQYIKWPWYIWLGFIAGLIAIVMVTIMLCCMTSCCSCLKGCCSCGSCCKFDEDDSEPVLKGVKLHYT'"></textarea>

            </div>
            <div class="col-sm-3 d-flex flex-column justify-content-start p-2">
              <div class="p-2 d-flex flex-column justify-content-start">
              </div>

            </div>
          </div>
        </form>

        <div class="form-group row" id="alleleRow">
          <div class="col-sm-2 col-form-label">
            <label class="text-left">MHC Allele(s)</label>
          </div>
          <div class="col-sm-10 d-flex flex-row">
            <input #alleleSearch id="allAlleles" type="text" class="form-control"
                   [ngbTypeahead]="search"
                   [inputFormatter]="inputFormatter"
                   [resultFormatter]="resultFormatter"
                   [editable]="false"
                   (selectItem)="onAlleleSelect($event, alleleSearch)"
                   placement="bottom-left"
                   (blur)="onBlurAlleleSelect($event, alleleSearch);"
                   (focus)="alleleSearch.value=''; alleleSearch.placeholder='';"
                   [defaultValue]="selectedAlleles.length ? selectedAlleleLabels : ''"
                   [placeholder]="'Ex: HLA-A*02:01'"
            />
            <div class="button-container">
              <button *ngIf="selectedAlleles.length" id="allele-clear-button" type="button"
                      class="rounded-circle rounded-sm"
                      (click)="onAlleleClear(alleleSearch);"
                      ngbTooltip='Clear allele(s)'
                      placement="right"
                      triggers="hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-x-circle" viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
              <button type="button" id="allele-modal-button" class="btn btn-dark mr-2" placement="right"
                      (click)="onOpenAlleleModal()"
                      [ngbTooltip]="tipContent"
                      [autoClose]="false"
                      #t="ngbTooltip">
                {{selectedAlleles.length}}
              </button>
            </div>
<!--          </div>-->
          <ng-template #tipContent>
              <div>
                Click to open Allele Selector
              </div>
              <ol *ngIf="selectedAlleles.length">
                <li *ngFor="let allele of selectedAlleles;" [ngStyle]="{width: '100%'}">
                  {{allele?.label}}
                </li>
              </ol>
          </ng-template>
          <div class="col-sm-2">
            <div *ngIf="searching" class="d-flex justify-content-center col">
              <div class="spinner-border text-primary align-self-center" role="status">
                <span class="visually-hidden sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="col-sm-3 text-right">
            <ng-template #runTipContent>
                <span [style]="{whiteSpace: 'nowrap'}">
                  Click to run tool
                </span>
            </ng-template>
            <!--            [disabled]="!(tCellForm.value['sequences'] && selectedAlleles.length>0)"-->
            <button type="button" class="btn btn-primary text-white float-end" (click)="runTCellPrediction()"
                    routerLink="/pipeline"
                    [state]="{run: true}"
                    [ngbTooltip]="runTipContent"
                    placement="top">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-play-fill" viewBox="0 0 16 16">
                <path
                  d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

      <a class="btn btn-success align-self-center text-white mx-auto mt-4" style="width: 100%" routerLink="/all-tools-list" target="_blank">
        <b>Additional Tools</b>
      </a>
      <!--      Tools List -->
      <div class="bg-info bg-opacity-50 d-flex flex-column p-3" appDnd (fileDropped)="onFileDropped($event)">


        <div class="row my-2">
          <div class="col-sm-6">
            <h5>
              <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
              <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'cluster'}"><u>Cluster</u></a>
            </h5>
          </div>
          <div class="col-sm-6">
            <label class="fst-italic">Group peptides by sequence identity</label>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <h5>
              <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
              <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'mutpepgen'}"><u>Mutated Peptide Generator</u></a>
            </h5>
          </div>
          <div class="col-sm-6">
            <label class="fst-italic">Generate neoepitopes from a VCF</label>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <h5>
              <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
              <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepvcomp'}"><u>Peptide Variant Comparison</u></a>
            </h5>
          </div>
          <div class="col-sm-6">
            <label class="fst-italic"> Compare MHC binding / elution / immunogenicity
              metrics between wild-type and mutant peptides
            </label>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <h5>
              <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
              <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepmatch'}"><u>PEPMatch</u></a>
            </h5>
          </div>
          <div class="col-sm-6">
            <label class="fst-italic">Search for closely related peptides in a reference proteome</label>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-sm-6">
            <h5>
              <!--   href="javascript:void(0)" used to make it look like a hyperlink -->
              <a [routerLink]="'/pipeline'" [queryParams]="{tool: 'pepx'}"><u>PepX</u></a>
            </h5>
          </div>
          <div class="col-sm-6">
            <label class="fst-italic">Estimate peptide abundance from RNA-Seq expression datasets</label>
          </div>
        </div>

      </div>

      <!--      Coming Soon Banner - commented out when adding Tools List up top (7/25/2024)-->
<!--      <div class="bg-warning bg-opacity-50 d-flex flex-column p-3 my-3">-->
<!--        <label class="fst-italic">More tools coming soon.</label>-->
<!--      </div>-->

      <!--      B Cell Form -->
<!--      <div class="bg-warning bg-opacity-50 d-flex flex-column p-3 mt-3 mb-3" [style]="{opacity: 1}">-->
<!--        <h5><u>B Cell Prediction</u></h5>-->
<!--        <p class="fst-italic">Predicts antibody epitopes from protein sequences</p>-->
<!--        <form [formGroup]="bCellForm">-->
<!--          <div class="d-flex form-group row">-->
<!--            <div class="mt-2 col-sm-12">-->
<!--              <textarea class="form-control"-->
<!--                        id="bCellSequenceFormControlTextArea"-->
<!--                        rows="5"-->
<!--                        formControlName="sequences"></textarea>-->

<!--            </div>-->
<!--            <div class="col-sm-3 d-flex flex-column justify-content-start p-2">-->
<!--              <div class="p-2 d-flex flex-column justify-content-start">-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </form>-->
<!--        <div class="row" id="alleleRow-2">-->

<!--          <div class="col-sm-10 d-flex flex-row">-->
<!--              <p class="fst-italic text-danger">-->
<!--                Coming Soon-->
<!--              </p>-->
<!--          </div>-->
<!--          <div class="col-sm-2 text-right">-->
<!--            <button type="button" class="btn btn-primary text-white float-end" (click)="null"-->
<!--                    routerLink="/pipeline"-->
<!--                    [state]="{run: true}"-->
<!--                    [ngbTooltip]="runTipContent"-->
<!--                    placement="top"-->
<!--                    disabled>-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"-->
<!--                   class="bi bi-play-fill" viewBox="0 0 16 16">-->
<!--                <path-->
<!--                  d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>-->
<!--              </svg>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;        <div class="d-flex form-group row">&ndash;&gt;-->
<!--        &lt;!&ndash;          <div class="mt-2 col-sm-12">&ndash;&gt;-->
<!--        &lt;!&ndash;            <label>Enter antingen </label>&ndash;&gt;-->
<!--        &lt;!&ndash;          <textarea class="form-control"&ndash;&gt;-->
<!--        &lt;!&ndash;                    id="sequenceFormControlTextArea"&ndash;&gt;-->
<!--        &lt;!&ndash;                    rows="10"&ndash;&gt;-->
<!--        &lt;!&ndash;                    formControlName="sequences"></textarea>&ndash;&gt;-->

<!--        &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        &lt;!&ndash;          <div class="col-sm-3 d-flex flex-column justify-content-start p-2">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="p-2 d-flex flex-column justify-content-start">&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->

<!--        &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
  </div>
</div>
</div>

<!--Allele Select Modal -->
<ng-template #selectedAlleleModal let-modal2>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-allele-title">
      Number of Selected Alleles: {{selectedAlleles.length}}
    </h4>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="dismissAlleleModal();"
    >
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group row p-2">
      <div class="col-sm-3 col-form-label">
        <label [className]="'fw-bold'">
          <!--        <span *ngIf="inputChange.alleles">(Edited) </span>-->
          MHC Allele(s)
        </label>
      </div>
      <div class="col-sm-7 d-flex flex-row">
        <!--        <input class="form-control" formControlName="allele" type="text" placeholder="HLA-A*02:01,HLA-A*01:01">-->
        <input #alleleSearchModal type="text" class="form-control"
               [ngbTypeahead]="search"
               [inputFormatter]="inputFormatter"
               [resultFormatter]="resultFormatter"
               [editable]="false"
               (selectItem)="onAlleleSelectModal({e : $event, input : alleleSearchModal})"
               placement="bottom-left"
               (blur)="onBlurAlleleSelect($event, alleleSearchModal)"
               (focus)="alleleSearchModal.value=''; alleleSearchModal.placeholder=''"
               [defaultValue]="selectedAlleles.length ? selectedModalAlleleLabels : ''"
               [placeholder]="'Ex: HLA-A*02:01'"
        />
      </div>
      <div class="col-sm-2">
        <div *ngIf="searching" class="d-flex justify-content-center col">
          <div class="spinner-border text-primary align-self-center" role="status">
            <span class="visually-hidden sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="modalAlleles.length > 0" class="p-2 d-flex flex-column justify-content-start">

      <!--            <app-alert-component></app-alert-component>-->

      <ul class="list-group">
        <!--<li *ngFor="let i of selectedAssayProtocols;" class="list-group-item list-group-item-success">{{i['allele']['allele']}} {{i['assayCondition'] && '+'}} {{i['assayCondition']}}</li>-->
        <li *ngFor="let i of modalAlleles; let j=index;" class="list-group-item list-group-item-primary list-group-item-action">{{i['label']}}
          <button class="btn-close float-end" type="button" (click)="onAlleleDeselectModal(j, alleleSearchModal)"
                  [disabled]="searching">
          </button>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <fieldset class="w-100">
        <button type="button" class="btn btn-secondary" (click)="generate27Alleles(alleleSearchModal)"
                ngbTooltip="Covers ~97% of the human population"
                placement="bottom">27 Allele Panel
        </button>
        <button type="button" class="btn btn-sm" placement="right"
                [ngbPopover]="popOverContent"
                popoverTitle="27 Allele Panel Source">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle"
               viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </button>
        <ng-template #popOverContent>
          <a target="_blank"
             href="https://help.iedb.org/hc/en-us/articles/114094151851-HLA-allele-frequencies-and-reference-sets-with-maximal-population-coverage">
            HLA allele frequencies and reference sets with maximal population coverage
          </a>
        </ng-template>
        <div class="float-end">
          <button type="button" class="btn btn-secondary mx-1" (click)="onAlleleClearModal(alleleSearchModal);">
            Clear Selected Allele(s)
          </button>
          <button type="button" class="btn btn-primary mx-1" (click)="onSubmitModalAlleles(); alleleSearch.focus()">
            Submit
          </button>
        </div>
      </fieldset>
    </div>
<!--    <div class="modal-footer">-->
<!--      <fieldset class="w-100">-->
<!--        <button type="button" class="btn btn-outline-dark" (click)="generate27Alleles(alleleSearchModal)"-->
<!--                ngbTooltip="Covers ~97% of the human population"-->
<!--                placement="bottom">27 Allele Panel</button>-->
<!--        <button type="button" class="btn btn-sm" placement="right"-->
<!--                [ngbPopover]="popOverContent"-->
<!--                popoverTitle="27 Allele Panel Source">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">-->
<!--            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>-->
<!--            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>-->
<!--          </svg>-->
<!--        </button>-->
<!--        <ng-template #popOverContent>-->
<!--          <a target="_blank" href="https://help.iedb.org/hc/en-us/articles/114094151851-HLA-allele-frequencies-and-reference-sets-with-maximal-population-coverage">-->
<!--            HLA allele frequencies and reference sets with maximal population coverage-->
<!--          </a>-->
<!--        </ng-template>-->
<!--&lt;!&ndash;        ngbAutoFocus&ndash;&gt;-->
<!--        <button type="button" class="btn btn-outline-dark float-end"-->
<!--                (click)="onAlleleClear(alleleSearchModal)">Clear Selected Allele(s)</button>-->
<!--      </fieldset>-->
<!--    </div>-->


  </div>

</ng-template>

<!--Alert Modal -->
<ng-template #alertModal let-modal4>
  <div class="modal-header">
    <h4 class="modal-title" id="alert-modal-title">Validation Error(s)</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal4.dismiss('Cross click');">
    </button>
  </div>
  <div class="modal-body">
    <app-alert-component></app-alert-component>
  </div>
  <div class="modal-footer">
    <!--    <button type="button" class="btn btn-secondary" (click)="null">Reset</button>-->
    <!--    <button type="button" class="btn btn-primary" (click)="null">Display</button>-->
  </div>
</ng-template>
