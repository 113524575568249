import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import _ from 'lodash';
import {environment} from "../../environments/environment";

import Citation from "../_global/Citation"

@Component({
  selector: 'app-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss']
})
export class CitationComponent implements OnInit {

  loading: boolean = false;
  citations: any[] = [];
  uniq: string[] = []
  groupedCitations: {"T cell - class I": Citation[]} = {"T cell - class I": []}

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loading = true;
    const CITATIONS_API_URL = `${environment['api_url']}/api/v1/citations`;
    this.http.get<any>(CITATIONS_API_URL).subscribe(value => {
      this.citations = value.data;
      // this.citations =  _.orderBy(value.data, ['grouping_title', 'key']) /** uncomment later */
      this.uniq = []
      _.forEach(this.citations, (c) => {
        if (!this.uniq.includes(c.grouping_title)) {
          this.uniq.push(c.grouping_title)
          console.log(c.grouping_title)
          // @ts-ignore
          this.groupedCitations[c.grouping_title] = [c]
        } else {
          // @ts-ignore
          this.groupedCitations[c.grouping_title].push(c)
        }
      })
      console.log(this.uniq)
      console.log(this.groupedCitations)
      this.loading = false;
    })
  }



}
