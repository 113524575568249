<div *ngIf="loading" class="d-flex justify-content-center col" style="margin-top: 81px">
  <div class="spinner-border text-primary align-self-center" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<mat-drawer-container *ngIf="!loading" class="pipeline-container">
  <mat-drawer mode="side" [(opened)]="sidebarOpened">
    <div class="d-flex flex-row p-2 align-content-center">
      <button class="btn btn-primary" (click)="sidebarOpened=!sidebarOpened"> <<</button>
      <h3 class="px-2">Pipeline Map</h3>
      <!--Dropdown Links-->
      <div class="dropdown text-center">
        <button
          [disabled]="!pipelineId"
          [ngbTooltip]="!pipelineId ? 'Available after `Run`' : null"
          placement="bottom"
          class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton-pipeline-input-links"
          data-bs-toggle="dropdown"
          data-toggle="dropdown"
          aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard"
               viewBox="0 0 16 16">
            <path
              d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
            <path
              d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
          </svg>
        </button>
        <!--      Available tools will be listed here, depending on what the previous tool is.-->
        <ul class="dropdown-menu dropdown-menu-right">
          <li class="dropdown-item">
            <div class="form-group">
              <label for="pipeline-url-input">Link to this pipeline</label>
              <div class="input-group">
                <input type="text" class="form-control" id="pipeline-url-input" [value]="pipelineUrl">
                <button class="btn btn-secondary" [cdkCopyToClipboard]="pipelineUrl" ngbTooltip="copy url"
                        placement="top">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-clipboard" viewBox="0 0 16 16">
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>
              </div>
            </div>
          </li>
          <li class="dropdown-item">
            <div class="form-group">
              <label for="pipeline-spec-url-inpput">Link to pipeline configuration</label>
              <div class="input-group">
                <input type="text" class="form-control" id="pipeline-spec-url-inpput" [value]="pipelineSpecUrl">
                <button class="btn btn-secondary" [cdkCopyToClipboard]="pipelineSpecUrl" ngbTooltip="copy url"
                        placement="top">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-clipboard" viewBox="0 0 16 16">
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>
              </div>

            </div>
          </li>
          <li class="dropdown-item d-flex justify-content-center">
            <button class="btn btn-secondary" [cdkCopyToClipboard]="paramsString" ngbTooltip="copy json"
                    placement="top"> Copy API POST request
            </button>
          </li>
        </ul>
      </div>
    </div>

    <!--   Pipeline Map Accordions -->
    <div ngbAccordion>
      <!--      Info -->
      <div ngbAccordionItem [collapsed]="true">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>
            <h5 class="d-flex flex-row">
            <span class="stage-name-link me-2">
              Info
            </span>
            </h5>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <table class="table table-bordered tools-parameter-table">
                <tr>
                  <td><a href="javascript:void(0)" (click)="open(emailModal)" ngbTooltip="Click to edit Pipeline Name"
                         placement="top">Pipeline Name</a></td>
                  <td>
                    {{ submittedEmailForm['name'] }}
                  </td>
                </tr>
                <tr>
                  <td><a href="javascript:void(0)" (click)="open(emailModal)" ngbTooltip="Click to edit Email"
                         placement="top">Email</a></td>
                  <td>
                    {{ submittedEmailForm['email'] }}
                  </td>
                </tr>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- Steps -->
      <div ngbAccordionItem [collapsed]="false">

        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>
            <h5 class="d-flex flex-row">
            <span class="stage-name-link me-2">
              Steps
            </span>
            </h5>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <!-- Nested Accordion -->
              <div ngbAccordion>
                <ng-container *ngFor="let stage of stages$ | async; let idx=index;">
                  <div ngbAccordionItem [collapsed]="true">

                    <div ngbAccordionHeader class="accordion-button custom-accordion-header d-flex flex-row">
                      <div class="flex-grow-1 d-flex flex-row">
                        <h5 class="stage-name-link me-2" (click)="onGoToStage(stage.tool_group)">
                      {{ stage.stage_display_name }}
                      </h5>
                        <!--      loader      -->
                        <div *ngIf="tools[idx].loading && !tools[idx].submitted"
                             class="spinner-border spinner-border-sm text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <!--    applied Filters        -->
                        <div *ngIf="tools[idx].appliedFilters" class="float-right px-2" role="status">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-funnel-fill" viewBox="0 0 16 16">
                            <path
                              d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
                          </svg>
                        </div>
                        <!--    table        -->
                        <div *ngIf="!tools[idx].loading && tools[idx].submitted" class="float-right px-2" role="status">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-table" viewBox="0 0 16 16">
                            <path
                              d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                          </svg>
                        </div>

                      </div>
                      <!--Toggle Button -->
                      <button ngbAccordionToggle class="accordion-button-toggle btn btn-link p-0"></button>
                      <!--Remove tool (X) Button -->
                      <button type="button" class="btn-close ms-1" aria-label="Close"
                              (click)="removeToolFromPipeline(stage.tool_group)"
                              [hidden]="stages[stages.length-1]!==stage || stages.length === 1">
                      </button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>
                          <!-- MHCI -->
                          <div *ngIf="stage.tool_group==='mhci'" class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Predictors</td>
                                <td>
                                  <div *ngFor="let o of predictionModels; let i=index;">
                                    {{ i + 1 }}
                                    . {{ o.type }} {{ ['binding', 'processing'].includes(o.type) ? '(' + o.method + ')' : null }}
                                    <!--                  {{i + 1}}. {{o.type}}{{o.method && '.'}}{{o.method}}-->
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td> length(s)</td>
                                <td>
                          <span *ngIf="tCellForm.controls['breakPeptides'].value; else asIsTrue">
                            None
                          </span>
                                  <ng-template #asIsTrue>
                            <span
                              *ngIf="tCellForm.controls['peptideLength'].value[0] !== tCellForm.controls['peptideLength'].value[1]; else singlePeptideLength">
                              {{ tCellForm.controls['peptideLength'].value[0] }}
                              - {{ tCellForm.controls['peptideLength'].value[1] }}
                            </span>
                                    <ng-template #singlePeptideLength>
                              <span>
                                  {{ tCellForm.controls['peptideLength'].value[0] }}
                              </span>
                                    </ng-template>
                                  </ng-template>
                                </td>
                              </tr>
                              <tr>
                                <td> Alleles</td>
                                <td>
                  <span *ngIf="selectedAlleles.length===1">
                   ({{ selectedAlleles[0].label }})
                  </span>
                                  <span *ngIf="selectedAlleles.length===2">
                     ({{ selectedAlleles[0].label }}, {{ selectedAlleles[1].label }})
                  </span>
                                  <span *ngIf="selectedAlleles.length > 2">
                    ({{ selectedAlleles[0].label }},... {{ selectedAlleles[selectedAlleles.length - 1].label }})
                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- Cluster -->
                          <div *ngIf="stage.tool_group==='cluster'" class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Threshold</td>
                                <td>
                                  {{ clusterForm.controls['threshold'].value * 100 }}%
                                </td>
                              </tr>
                              <tr>
                                <td> length(s)</td>
                                <td>
                  <span>
                  {{ clusterMinPeptideLength }}-{{ clusterMaxPeptideLength }}
                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Method</td>
                                <td>
                  <span>
                    {{ clusterForm.controls['method'].value }}
                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- Pepmatch -->
                          <div *ngIf="stage.tool_group==='pepmatch'" class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Proteome</td>
                                <td>
                                  {{ pepmatchForm.controls['proteome'].value }}
                                </td>
                              </tr>
                              <tr>
                                <td> Max mismatches</td>
                                <td>
                  <span>
                  {{ pepmatchForm.controls['mismatch'].value }}
                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Best match per peptide</td>
                                <td>
                  <span>
                    {{ pepmatchForm.controls['bestMatch'].value }}
                  </span>
                                </td>
                              </tr>
                            </table>

                          </div>
                          <!-- Pepx -->
                          <div *ngIf="stage.tool_group==='pepx'" class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Quantitation Level</td>
                                <td>
                                  {{ pepxForm.controls['qlevel'].value }}
                                </td>
                              </tr>
                              <tr>
                                <td> Data Source</td>
                                <td>
                                  {{ pepxForm.controls['datasource'].value }}
                                </td>
                              </tr>
                              <tr>
                                <td> Dataset ID</td>
                                <td>
                  <span>
                  {{ pepxForm.controls['dataset_id'].value }}
                  </span>
                                </td>
                              </tr>

                            </table>
                            <!--              <table *ngIf="!tools[idx/2].loading && tools[idx/2].submitted" class="table table-bordered filters-table">-->
                            <!--                <thead>-->
                            <!--                <tr>-->
                            <!--                  <th class="text-center" colspan="2">-->
                            <!--                    Column Filters-->
                            <!--                  </th>-->
                            <!--                </tr>-->
                            <!--                </thead>-->
                            <!--                <tbody>-->
                            <!--                <tr>-->
                            <!--                  <td> Col A</td>-->
                            <!--                  <td>-->
                            <!--                    Min: x-->
                            <!--                    Max: y-->
                            <!--                  </td>-->
                            <!--                </tr>-->
                            <!--                <tr>-->
                            <!--                  <td> Col C</td>-->
                            <!--                  <td>-->
                            <!--                  <span>-->
                            <!--                  FVEHIHVLENSLAFK, FVEHIHVLENSLAFK, ...-->
                            <!--                  </span>-->
                            <!--                  </td>-->
                            <!--                </tr>-->
                            <!--                <tr>-->
                            <!--                  <td> Col B</td>-->
                            <!--                  <td>-->
                            <!--                  <span>-->
                            <!--                    Selected x value(s)-->
                            <!--                  </span>-->
                            <!--                  </td>-->
                            <!--                </tr>-->
                            <!--                </tbody>-->
                            <!--              </table>-->
                          </div>
                          <!-- Prediction Binding Comparison -->
                          <div *ngIf="stage.tool_group==='peptide_variant_comparison'"
                               class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Alleles</td>
                                <td>
                  <span *ngIf="selectedAlleles2.length===1">
                   ({{ selectedAlleles2[0].label }})
                  </span>
                                  <span *ngIf="selectedAlleles2.length===2">
                     ({{ selectedAlleles2[0].label }}, {{ selectedAlleles2[1].label }})
                  </span>
                                  <span *ngIf="selectedAlleles2.length > 2">
                    ({{ selectedAlleles2[0].label }},... {{ selectedAlleles2[selectedAlleles2.length - 1].label }})
                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Predictors</td>
                                <td>
                                  <div *ngFor="let o of predictionModels2; let i=index;">
                                    {{ i + 1 }}
                                    . {{ o.type }} {{ ['binding', 'processing'].includes(o.type) ? '(' + o.method + ')' : null }}
                                    <!--                  {{i + 1}}. {{o.type}}{{o.method && '.'}}{{o.method}}-->
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- Mut Gen -->
                          <div *ngIf="stage.tool_group==='mutgen'" class="d-flex flex-column justify-content-start">

                            <table class="table-sm table-bordered tools-parameter-table">
                              <thead>
                              <tr>
                                <th class="text-center" colspan="2">
                                  Tool Parameters
                                </th>
                              </tr>
                              </thead>
                              <tr>
                                <td> Peptide Length</td>
                                <td>
                                <span>
                                {{ mutgenForm.controls['peptideLength'].value }}
                                </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Peptide Mutation Position 1</td>
                                <td>
                                <span>
                                {{ mutgenForm.controls['peptideMutationPosition1'].value }}
                                </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Peptide Mutation Position 2</td>
                                <td>
                                <span>
                                {{ mutgenForm.controls['peptideMutationPosition2'].value }}
                                </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Frameshift Overlap</td>
                                <td>
                                <span>
                                {{ mutgenForm.controls['frameshiftOverlap'].value }}
                                </span>
                                </td>
                              </tr>
                              <tr>
                                <td> Minimum Peptide Length</td>
                                <td>
                                <span>
                                {{ mutgenForm.controls['minLengthNearStartStop'].value }}
                                </span>
                                </td>
                              </tr>
<!--                              TODO took this out when taking out max peptide length input-->
<!--                              <tr>-->
<!--                                <td> Maximum Peptide Length</td>-->
<!--                                <td>-->
<!--                                <span>-->
<!--                                {{ mutgenForm.controls['maximumPeptideLength'].value }}-->
<!--                                </span>-->
<!--                                </td>-->
<!--                              </tr>-->
                            </table>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>


              <!-- + Button -->
              <div id="add-tools-button" class="pt-2 dropdown d-flex justify-content-between">
                <div>
                  <!--  MHCI    -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'mhci'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted"
                          [matMenuTriggerFor]="mhci_valid_steps">
                    +
                  </button>
                  <!-- # sub-menu -->
                  <mat-menu #mhci_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="mhci_cluster_inputs"
                            [disabled]="addedToolGroups.includes('cluster')">Cluster
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mhci_pepmatch_inputs"
                            [disabled]="addedToolGroups.includes('pepmatch')">Pepmatch
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mhci_pepx_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">PepX
                    </button>
                  </mat-menu>
                  <mat-menu #mhci_cluster_inputs="matMenu">
                    <button mat-menu-item (click)="addCluster(this.tools.length)">Peptide Table - Peptide</button>
                  </mat-menu>
                  <mat-menu #mhci_pepmatch_inputs="matMenu">
                    <button mat-menu-item (click)="addPepmatch(this.tools.length)">Peptide Table - Peptide</button>
                  </mat-menu>
                  <mat-menu #mhci_pepx_inputs="matMenu">
                    <button mat-menu-item (click)="addPepX(this.tools.length)">Peptide Table - Peptide</button>
                  </mat-menu>

                  <!--  Cluster -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'cluster'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted" [matMenuTriggerFor]="cluster_valid_steps"
                          ngbTooltip="add a step to the pipeline">
                    +
                  </button>
                  <!-- # sub-menu -->
                  <mat-menu #cluster_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="cluster_mhci_inputs"
                            [disabled]="addedToolGroups.includes('mhci')">T Cell Prediction - Class I
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="cluster_pepmatch_inputs"
                            [disabled]="addedToolGroups.includes('pepmatch')">Pepmatch
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="cluster_pepx_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">PepX
                    </button>
                  </mat-menu>
                  <mat-menu #cluster_mhci_inputs="matMenu">
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'peptide')">Peptide Table -
                      Peptide
                    </button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'cluster_consensus')">Peptide
                      Table - Cluster Consensus
                    </button>
                  </mat-menu>
                  <mat-menu #cluster_pepmatch_inputs="matMenu">
                    <button mat-menu-item (click)="addPepmatch(this.tools.length)">Peptide Table - Peptide</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'cluster_consensus')">Peptide Table -
                      Cluster Consensus
                    </button>
                  </mat-menu>
                  <mat-menu #cluster_pepx_inputs="matMenu">
                    <button mat-menu-item (click)="addPepX(this.tools.length)">Peptide Table - Peptide</button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'cluster_consensus')">Peptide Table -
                      Cluster Consensus
                    </button>
                  </mat-menu>

                  <!--  Pepmatch -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'pepmatch'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted" [matMenuTriggerFor]="pepmatch_valid_steps"
                          ngbTooltip="add a step to the pipeline">
                    +
                  </button>
                  <mat-menu #pepmatch_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="pepmatch_mhci_inputs"
                            [disabled]="addedToolGroups.includes('mhci')">T Cell Prediction - Class I
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepmatch_cluster_inputs"
                            [disabled]="addedToolGroups.includes('cluster')">Cluster
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepmatch_pepx_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">PepX
                    </button>
                  </mat-menu>
                  <mat-menu #pepmatch_mhci_inputs="matMenu">
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'peptide')">Peptide Table -
                      Peptide
                    </button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'matched_sequence')">Peptide
                      Table - Matched
                      Sequence
                    </button>
                  </mat-menu>
                  <mat-menu #pepmatch_cluster_inputs="matMenu">
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'peptide')">Peptide Table - Peptide
                    </button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'matched_sequence')">Peptide Table -
                      Matched Sequence
                    </button>
                  </mat-menu>
                  <mat-menu #pepmatch_pepx_inputs="matMenu">
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'peptide')">Peptide Table - Peptide
                    </button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'matched_sequence')">Peptide Table -
                      Matched Sequence
                    </button>
                  </mat-menu>

                  <!--  Pepx -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'pepx'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted"
                          [matMenuTriggerFor]="pepx_valid_steps">
                    +
                  </button>
                  <!-- # sub-menu -->
                  <mat-menu #pepx_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="pepx_mhci_inputs"
                            [disabled]="addedToolGroups.includes('mhci')">T Cell Prediction - Class I
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepx_cluster_inputs"
                            [disabled]="addedToolGroups.includes('cluster')">Cluster
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepx_pepmatch_inputs"
                            [disabled]="addedToolGroups.includes('pepmatch')">Pepmatch
                    </button>
                  </mat-menu>
                  <mat-menu #pepx_mhci_inputs="matMenu">
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'peptide', 'peptide_table')">
                      Peptide Table - Peptide
                    </button>
                    <button mat-menu-item
                            (click)="addTCellPrediction(this.tools.length, 'peptide', 'peptide_gene_summary_table')">
                      Peptide Gene Summary Table - Peptide
                    </button>
                  </mat-menu>
                  <mat-menu #pepx_cluster_inputs="matMenu">
                    <button mat-menu-item (click)="addCluster(this.tools.length)">Peptide Table - Peptide</button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'peptide', 'peptide_gene_summary_table')">Peptide Gene Summary
                      Table - Peptide
                    </button>
                  </mat-menu>
                  <mat-menu #pepx_pepmatch_inputs="matMenu">
                    <button mat-menu-item (click)="addPepmatch(this.tools.length)">Peptide Table - Peptide</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'peptide', 'peptide_gene_summary_table')">Peptide Gene
                      Summary Table - Peptide
                    </button>
                  </mat-menu>

                  <!--  pepcomp    -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'peptide_variant_comparison'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted"
                          [matMenuTriggerFor]="pepcomp_valid_steps">
                    +
                  </button>
                  <!-- # sub-menu -->
                  <mat-menu #pepcomp_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="pepcomp_mhci_inputs"
                            [disabled]="addedToolGroups.includes('mhci')">T Cell Prediction - Class I
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepcomp_cluster_inputs"
                            [disabled]="addedToolGroups.includes('cluster')">Cluster
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepcomp_pepmatch_inputs"
                            [disabled]="addedToolGroups.includes('pepmatch')">Pepmatch
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="pepcomp_pepx_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">PepX
                    </button>
                  </mat-menu>

                  <mat-menu #pepcomp_mhci_inputs="matMenu">
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'peptide-peptidea')">Peptide Table - Peptide A</button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'peptide-peptideb')">Peptide Table - Peptide B</button>
                  </mat-menu>
                  <mat-menu #pepcomp_cluster_inputs="matMenu">
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'peptide-peptidea')">Peptide Table - Peptide A</button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'peptide-peptideb')">Peptide Table - Peptide B</button>
                  </mat-menu>
                  <mat-menu #pepcomp_pepmatch_inputs="matMenu">
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'peptide-peptidea')">Peptide Table - Peptide A</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'peptide-peptideb')">Peptide Table - Peptide B</button>
                  </mat-menu>
                  <mat-menu #pepcomp_pepx_inputs="matMenu">
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'peptide-peptidea')">Peptide Table - Peptide A</button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'peptide-peptideb')">Peptide Table - Peptide B</button>
                  </mat-menu>

                  <!--  mutgen    -->
                  <button *ngIf="stages[stages.length-1].tool_group === 'mutgen'" class='btn btn-lg btn-primary'
                          [disabled]="!tools[tools.length-1].submitted"
                          [matMenuTriggerFor]="mutgen_valid_steps">
                    +
                  </button>
                  <!-- # sub-menu -->
                  <mat-menu #mutgen_valid_steps="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="mutgen_mhci_inputs"
                            [disabled]="addedToolGroups.includes('mhci')">T Cell Prediction - Class I
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mutgen_cluster_inputs"
                            [disabled]="addedToolGroups.includes('cluster')">Cluster
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mutgen_pepmatch_inputs"
                            [disabled]="addedToolGroups.includes('pepmatch')">Pepmatch
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mutgen_pepx_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">PepX
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="mutgen_peptide_variant_comparison_inputs"
                            [disabled]="addedToolGroups.includes('pepx')">Peptide Variant Comparison
                    </button>
                  </mat-menu>

                  <mat-menu #mutgen_mhci_inputs="matMenu">
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'reference_peptide', 'unique_peptide')">Unique Peptides - Reference Peptide</button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'mutant_peptide', 'unique_peptide')">Unique Peptides - Mutant Peptide</button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'reference_peptide', 'peptide')">All Peptide Table - Reference Peptide</button>
                    <button mat-menu-item (click)="addTCellPrediction(this.tools.length, 'mutant_peptide', 'peptide')">All Peptide Table - Mutant Peptide</button>
                  </mat-menu>
                  <mat-menu #mutgen_cluster_inputs="matMenu">
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'reference_peptide', 'unique_peptide')">Unique Peptides - Reference Peptide</button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'mutant_peptide', 'unique_peptide')">Unique Peptides - Mutant Peptide</button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'reference_peptide', 'peptide')">All Peptide Table - Reference Peptide</button>
                    <button mat-menu-item (click)="addCluster(this.tools.length, 'mutant_peptide', 'peptide')">All Peptide Table - Mutant Peptide</button>
                  </mat-menu>
                  <mat-menu #mutgen_pepmatch_inputs="matMenu">
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'reference_peptide', 'unique_peptide')">Unique Peptides - Reference Peptide</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'mutant_peptide', 'unique_peptide')">Unique Peptides - Mutant Peptide</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'reference_peptide', 'peptide')">All Peptide Table - Reference Peptide</button>
                    <button mat-menu-item (click)="addPepmatch(this.tools.length, 'mutant_peptide', 'peptide')">All Peptide Table - Mutant Peptide</button>
                  </mat-menu>
                  <mat-menu #mutgen_pepx_inputs="matMenu">
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'reference_peptide', 'unique_peptide')">Unique Peptides - Reference Peptide</button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'mutant_peptide', 'unique_peptide')">Unique Peptides - Mutant Peptide</button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'reference_peptide', 'peptide')">All Peptide Table - Reference Peptide</button>
                    <button mat-menu-item (click)="addPepX(this.tools.length, 'mutant_peptide', 'peptide')">All Peptide Table - Mutant Peptide</button>
                  </mat-menu>
                  <mat-menu #mutgen_peptide_variant_comparison_inputs="matMenu">
                    <button mat-menu-item (click)="addPepComp(this.tools.length, 'unique_peptide', 'reference_peptide', 'mutant_peptide')">Unique Peptides - Reference Peptide + Mutant Peptide</button>
                    <button mat-menu-item (click)="addPepComp(this.tools.length, 'peptide', 'reference_peptide', 'mutant_peptide')">All Peptide Table - Reference Peptide + Mutant Peptide</button>
                  </mat-menu>

                  <button type="button" class="btn btn-sm" placement="bottom-left"
                          [ngbPopover]="popOverContent"
                          popoverTitle="add a step to the pipeline">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-info-circle"
                         viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <ng-template #popOverContent>
                    <a target="_blank"
                       href="https://nextgen-tools.iedb.org/docs/pipelines/index.html#building-a-pipeline">
                      Documentation
                    </a>
                  </ng-template>
                </div>
                <!--Run Button -->
                <!--            tools[tools.length-1].-->
                <span *ngIf="pipelineLoading; else elseBlock">
              <button type="button" class="btn btn-danger btn-lg" (click)="onCancelRuns()"> Cancel Run </button>
            </span>
                <ng-template #elseBlock>
                  <div *ngIf="tools.length-1 !==0 ; else runButtonBlock" class="btn-group">
                    <div class="btn-group">
                      <!--                <button type="submit" class="run-button btn btn-primary btn-lg text-white"-->
                      <!--                        [disabled]="tools[tools.length-1].loading"-->
                      <!--                  >-->
                      <!--                  &lt;!&ndash;                        (index>0 && !tools[index-1].submitted)&ndash;&gt;-->
                      <!--                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">-->
                      <!--                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>-->
                      <!--                  </svg>-->
                      <!--                </button>-->

                      <button class="btn btn-lg btn-primary" (click)="emitEventToChild()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-play-fill" viewBox="0 0 16 16">
                          <path
                            d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                        </svg>
                      </button>

                      <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-toggle="dropdown"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="emitRunAllToChild()">Run All Above</a>
                      </div>
                    </div>
                  </div>
                  <ng-template #runButtonBlock>
                    <!--              (index>0 && !tools[index-1].submitted) ||-->
                    <!--              <button type="submit" class="run-button btn btn-primary btn-lg text-white"-->
                    <!--                      [disabled]="tools[tools.length-1].loading"-->
                    <!--              >-->
                    <!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">-->
                    <!--                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>-->
                    <!--                </svg>-->
                    <!--              </button>-->

                    <button class="btn btn-lg btn-primary" [disabled]="tools[tools.length-1].loading"
                            (click)="emitEventToChild()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-play-fill" viewBox="0 0 16 16">
                        <path
                          d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                      </svg>
                    </button>
                  </ng-template>
                </ng-template>


                <!--            <button class="btn btn-lg btn-primary" [disabled]="tools[tools.length-1].loading" (click)="emitEventToChild()">-->
                <!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">-->
                <!--                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>-->
                <!--              </svg>-->
                <!--            </button>-->
              </div>

            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <!--    <div> &lt;!&ndash; onRun()&ndash;&gt;-->
    <!--      <button class="btn btn-danger" (click)="debug()"> Debug</button>-->
    <!--    </div>-->

  </mat-drawer>
  <mat-drawer-content>
    <div class="row fixed-top" style="margin-top: 91px; margin-left: 15px">
      <div class="d-flex">
        <button class="btn btn-primary" *ngIf="!sidebarOpened" (click)="sidebarOpened=!sidebarOpened"> >></button>
      </div>
    </div>

    <ng-container [formGroup]="rootForm">
      <ng-container *ngFor="let stage of stages$ | async; let i=index;">
        <app-t-cell-prediction
          formGroupName="mhci"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='mhci'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runTCellSubject.asObservable()"
          [runAllEvent]="runAllTCellSubject.asObservable()"></app-t-cell-prediction>
        <!--        [showEmailModal]="showEmailModal"-->
        <!--        (shoeEmailModalChange)="onShowEmailChange($event)"-->
        <app-cluster
          formGroupName="cluster"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='cluster'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runClusterSubject.asObservable()"
          [runAllEvent]="runAllClusterSubject.asObservable()"></app-cluster>
        <!--        [showEmailModal]="showEmailModal"-->
        <!--        (shoeEmailModalChange)="onShowEmailChange($event)"-->
        <app-pepmatch
          formGroupName="pepmatch"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='pepmatch'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runPepmatchSubject.asObservable()"
          [runAllEvent]="runAllPepmatchSubject.asObservable()"></app-pepmatch>
        <app-pepx
          formGroupName="pepx"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='pepx'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runPepxSubject.asObservable()"
          [runAllEvent]="runAllPepxSubject.asObservable()"></app-pepx>
        <app-peptide-variant-comparison
          formGroupName="pepcomp"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='peptide_variant_comparison'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runPepcompSubject.asObservable()"
          [runAllEvent]="runAllPepcompSubject.asObservable()"></app-peptide-variant-comparison>
        <app-mutated-peptide-generator
          formGroupName="mutgen"
          [resultUrl]="stage.result_url"
          [index]="i"
          (emitIndex)="onRunAll(i)"
          (emitOnCancel)="onCancelRuns()"
          *ngIf="stage.tool_group==='mutgen'"
          (openEmailModal)=onOpenEmailModal()
          [emailForm]="emailForm"
          [events]="runMutgenSubject.asObservable()"
          [runAllEvent]="runAllMutgenSubject.asObservable()"></app-mutated-peptide-generator>

      </ng-container>
    </ng-container>

    <!--  DEBUGGER -->
    <!--    <pre style="margin-top:50px; border: 1px solid red; width: fit-content">-->
    <!--      {{rootForm.value | json}}-->
    <!--    </pre>-->
  </mat-drawer-content>
</mat-drawer-container>

<!--Alert Modal -->
<ng-template #alertModal let-modal4>
  <div class="modal-header">
    <h4 class="modal-title" id="alert-modal-title">Missing/Invalid Parameters</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal4.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <p> Please address the following issues in order for the pipeline to run:</p>
    <ngb-accordion #acc="ngbAccordion">
      <!--  Error -->
      <ngb-panel *ngIf="errorMessages.length" id="error-accordion">
        <!--    Header -->
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center justify-content-center">
            <span class="text-danger">Errors ({{ errorMessages.length }})</span>
            <button ngbPanelToggle class="btn btn-link" style="color: #dc3545;">
              <div *ngIf="!opened">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                </svg>
              </div>
              <svg *ngIf="opened" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>
            </button>
          </div>
        </ng-template>
        <!--    Content -->
        <ng-template ngbPanelContent>
          <ol>
            <li *ngFor="let message of errorMessages; let i=index;">{{ message }}</li>
          </ol>
        </ng-template>
      </ngb-panel>
      <!--  Warning -->
      <ngb-panel *ngIf="warningMessages.length" id="warning-accordion">
        <!--    Header -->
        <ng-template ngbPanelHeader let-opened="opened" [class.collapsed]="!opened">
          <div class="d-flex align-items-center justify-content-center">
            <span class="text-warning">Warnings ({{ warningMessages.length }}) </span>
            <button ngbPanelToggle class="btn btn-link" style="color: #f0ad4d;">
              <div *ngIf="!opened">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                </svg>
              </div>
              <svg *ngIf="opened" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
              </svg>
            </button>
          </div>
        </ng-template>
        <!--    Content -->
        <ng-template ngbPanelContent>
          <ol>
            <li *ngFor="let message of warningMessages; let i=index;">{{ message }}</li>
          </ol>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>

<!--Email Modal-->
<ng-template #emailModal let-modal3>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-email-title">
      Pipeline Name and Email
    </h4>
    <button type="button" id="emailModalCloseButton" class="btn-close" aria-label="Close"
            (click)="modal3.dismiss('Cross click');">
    </button>
  </div>
  <form [formGroup]="emailForm" (ngSubmit)="onSubmitEmailModal()">
    <div class="modal-body">
      <p>
        Add or change the pipeline title and email address and we will send you an email when your job has finished.
      </p>
      <div class="clearfix"></div>
      <div class="form-group row">
        <label for="pipelineName" class="col-sm-2 col-form-label font-weight-bold">Pipeline Name</label>
        <div class="col-sm-10">
          <input id="pipelineName" type="text" class="form-control" formControlName="name"/>
        </div>
      </div>
      <div class="form-group row">
        <label for="email" class="col-sm-2 col-form-label font-weight-bold">Email</label>
        <div class="col-sm-10">
          <input type="email"
                 id="email"
                 class="form-control"
                 formControlName="email"
                 placeholder="Ex: abc@lji.org"/>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</ng-template>

