import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import _ from 'lodash';

@Component({
  selector: 'app-tools-list',
  templateUrl: './tools-list.component.html',
  styleUrls: ['./tools-list.component.scss']
})
export class ToolsListComponent implements OnInit {

  initialTags: any = {
    structure3D: false,
    allele7: false,
    alleleFrequency: false,
    antibody: false,
    bCell: false,
    BCR: false,
    bindingAffinity: false,
    cancer: false,
    CD4: false,
    CD8: false,
    conservation: false,
    dataVisualization: false,
    deimmunization: false,
    elutedLigand: false,
    epitope: false,
    geneExpression: false,
    hlaAssociation: false,
    humoralImmunity: false,
    immunogenicity: false,
    mhcI: false,
    mhcII: false,
    modeling: false,
    molecularDocking: false,
    neoepitope: false,
    nonPeptidic: false,
    PDB: false,
    peptideSimilarity: false,
    population: false,
    proteinStructure: false,
    proteomeSearch: false,
    protrusion: false,
    responseFrequency: false,
    rnaExpression: false,
    smallMolecule: false,
    synthesis: false,
    tCell: false,
    tapProcessing: false,
    TCR: false,
    transcription: false,
    unwantedImmuneResponse: false,
    VCF: false
  }

  tools = [
    {queryParams: {tool: 'tc1'}, name: 'T Cell Prediction - Class I', tags: ["CD8", "bindingAffinity", "elutedLigand", "epitope", "hlaAssociation", "immunogenicity", "mhcI", "tCell", "tapProcessing"], count: 0, routerLink: 't-cell-prediction'},
    {queryParams: {tool: 'cluster'}, name: 'Cluster', tags: ["conservation", "dataVisualization", "hlaAssociation", "peptideSimilarity"], count: 0, routerLink: 'cluster'},
    {queryParams: {tool: 'pepmatch'}, name: 'PEPMatch', tags: ['conservation', 'peptideSimilarity', 'proteomeSearch'], count: 0, routerLink: 'pepmatch'},
    {queryParams: {tool: 'pepx'}, name: 'PepX', tags: ['geneExpression', 'rnaExpression', 'transcription'], count: 0, routerLink: '_blank'},
    {queryParams: {tool: 'pepvcomp'}, name: 'Peptide Variant Comparison', tags: ['bindingAffinity', 'cancer', 'CD8', 'dataVisualization', 'elutedLigand', 'epitope', 'hlaAssociation', 'immunogenicity', 'mhcI', 'neoepitope', 'tCell'], count: 0, routerLink: 'pepcomp'},
    {queryParams: {tool: 'mutpepgen'}, name: 'Mutated Peptide Generator', tags: ['cancer','neoepitope', 'VCF'], count: 0, routerLink: 'pepcomp'},
    {url: 'http://tools.iedb.org/mhcii/', name: 'MHC-II Binding Predictions*', tags: ["allele7", "bindingAffinity", "CD4", "elutedLigand", "epitope", "immunogenicity", "mhcII", "tCell"], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/bcell/', name: 'B Cell Linear Epitope Prediction*', tags: ['antibody', 'bCell', 'epitope', 'humoralImmunity','immunogenicity'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/conservancy/', name: 'Epitope Conservancy Analysis*', tags: ['conservation'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/discotope/', name: 'Discotope*', tags: [ "structure3D", "antibody", "bCell", "dataVisualization", "epitope", "humoralImmunity", "immunogenicity", "PDB",], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/population/', name: 'Population Coverage*', tags:  ["alleleFrequency", "hlaAssociation", "population", "responseFrequency"], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/ellipro/', name: 'Ellipro*', tags: [ "structure3D", "antibody", "bCell", "dataVisualization", "epitope", "humoralImmunity", "immunogenicity", "PDB"], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/tepitool/', name: 'TepiTool*', tags: [ 'bindingAffinity', 'CD4', 'CD8', 'epitope', 'hlaAssociation', 'mhcI', 'mhcII', 'protrusion', 'tCell'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/deimmunization/', name: 'Deimmunization*', tags: ['bindingAffinity', 'deimmunization',  'immunogenicity', 'mhcI', 'mhcII', 'tCell',  'unwantedImmuneResponse'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/docktope/', name: 'Docktope*', tags: ["structure3D", "hlaAssociation", "mhcI", "modeling", "molecularDocking", "proteinStructure","PDB", "TCR", "tCell"], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/lyra/', name: 'LYRA*', tags: ["structure3D", "antibody", "BCR", "bCell", "dataVisualization", "modeling", "PDB", "TCR","tCell"], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/immunomebrowser/', name: 'ImmunomeBrowser*', tags: [ 'dataVisualization', 'epitope', 'immunogenicity', 'modeling', 'responseFrequency'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/rate/', name: 'RATE*', tags: ['antibody', 'hlaAssociation', 'mhcI', 'mhcII', 'responseFrequency', 'tCell'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/axelf/', name: 'Axel-F*', tags: ['cancer', 'CD8', 'epitope', 'mhcI', 'tCell', 'rnaExpression', 'TCR', 'transcription'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/tcrmatch/', name: 'TCRMatch*', tags: ['CD4', 'CD8', 'tCell', 'TCR'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/np_epitope_predictor/', name: 'NP Epitope Predictor*', tags: ['bCell', 'dataVisualization', 'epitope', 'nonPeptidic', 'smallMolecule', 'tCell'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/sceptre/', name: 'SCEptRe*', tags: ['structure3D','bCell', 'BCR', 'mhcI', 'mhcII', 'PDB', 'tCell', 'TCR'], count: 0, routerLink: '_blank'},
    {url: 'http://tools.iedb.org/pepsysco/', name: 'PepSySco* (Peptide Synthesis)', tags: ['synthesis'], count: 0, routerLink: '_blank'},
  ]

  sortedTools: any = []

  selectedTools: any[] = [] // used for counts
  selectedTags: string[] = [] // used for counts
  horizontalDivider: string[] = [];
  countsForSelectedTools: any[] = [];

  toolsListForm!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.toolsListForm = this.formBuilder.group(this.initialTags);

    const firstTools = this.tools.slice(0, 6)
    const legacyTools = this.tools.slice(6, )

    this.sortedTools = firstTools.concat(_.orderBy(legacyTools, 'name'));

    this.countsForSelectedTools = _.reverse(_.values(_.groupBy(this.sortedTools, 'name')));
    console.log(this.countsForSelectedTools);
    this.onChange();
  }

  onChange(): void {
    /**
     *
     * */
    this.toolsListForm.valueChanges.subscribe(val => {
      console.log(val);

      this.selectedTools = [];
      this.sortedTools = [];
      this.horizontalDivider = [];

      this.selectedTags = _.keys(_.pickBy(val, (v: any, k:string) => {
        if (v === true) {
          return k
        }
        return null
      }));
      console.log(this.selectedTags);

      if(this.selectedTags.length === 0) {
        const firstTools = this.tools.slice(0, 3)
        const legacyTools = this.tools.slice(3, 21)

        this.sortedTools = firstTools.concat(_.orderBy(legacyTools, 'name'));
        return
      }

      const tempSelectedTools: string[] = [];

      _.forEach(this.selectedTags, (tag) => {
        console.log(tag);
        _.forEach(this.tools, (tool) => {
          if (tool.tags.includes(tag)) {
            tempSelectedTools.push(tool.name);
          }
        })
      })

      console.log(tempSelectedTools);

      this.selectedTools = _.values(_.groupBy(tempSelectedTools)).map(d => ({name: d[0], count: d.length}));

      _.forEach(this.tools, (tool) => {
        _.forEach(this.selectedTools, (fool) => {
          if (tool.name === fool.name) {
            tool.count = fool.count;
            this.sortedTools.push(tool);
          }
        })
      })
      this.sortedTools = _.orderBy(this.sortedTools, 'count', 'desc');
      let mostcount = this.sortedTools[0].count - 1;
      this.horizontalDivider.push(this.sortedTools[0].name);
       _.forEach(this.sortedTools, (tool) => {
        if(tool.count === mostcount) {
          mostcount -= 1;
          this.horizontalDivider.push(tool.name);
        }
      });

      this.countsForSelectedTools = _.reverse(_.values(_.groupBy(this.sortedTools, 'count')));

      /** DEBUGGER */
      // console.log(this.selectedTools);
      // console.log(this.sortedTools);
      // console.log(this.selectedTags);
      // console.log(this.horizontalDivider);
      // console.log(this.countsForSelectedTools);
    })
  }

  deSelectAllTags(e: any) {
    /**
     *
     * */
    this.toolsListForm.reset();
    this.selectedTags = [];
    this.horizontalDivider = [];
    this.selectedTools = [];

    const firstTools = this.tools.slice(0, 3)
    const legacyTools = this.tools.slice(3, 21)
    this.sortedTools = firstTools.concat(_.orderBy(legacyTools, 'name'));
  }
}
