<div *ngIf="alertService.displayMessage()" class="d-flex flex-row">
  <div [ngClass]="[alertService.colors, 'alert', 'p-2']" role="alert" style="width:100%">
<!--    <button type="button" class="close" aria-label="Close"-->
<!--            (click)="alertService.clear()" [ngStyle]="{float: 'right'}">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
<!--    <h5> Validation Error(s) </h5>-->
    <pre>{{alertService.message}}</pre>
  </div>
</div>

