import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {BenchmarkComponent} from "./benchmark/benchmark.component";
import {CitationComponent} from './citation/citation.component';
import {ToolsListComponent} from './tools-list/tools-list.component';
import {ToolContributionComponent} from "./tool-contribution/tool-contribution.component";
import {HomeComponent} from "./home/home.component";
import {DownloadAllComponent} from "./download-all/download-all.component";
import {PipelineNewComponent} from "./pipeline-new/pipeline-new.component";
import {VersionComponent} from "./version/version.component";
import {PipelineErrorComponent} from "./pipeline-error/pipeline-error.component";

const routes: Routes = [
  {
    path: 'all-tools-list',
    component: ToolsListComponent,
    data: {title: 'IEDB NG Tools List'}
  },
  {
    path: 'benchmark',
    component: BenchmarkComponent,
    data: {title: 'IEDB NG Tools Benchmarks'}
  },
  {
    path: 'citation',
    component: CitationComponent,
    data: {title: 'IEDB NG Tools Citations'}
  },
  {
    path: 'contributing-tools',
    component: ToolContributionComponent,
    data: {title: 'Contributing to IEDB NG Tools'}
  },
  {
    path: 'download-all',
    component: DownloadAllComponent,
    data: {title: 'IEDB NG Tools Downloads'}
  },
  {
    path: 'error',
    component: PipelineErrorComponent,
    data: {title: 'IEDB NG Tools Error'}
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {title: 'IEDB Next-Generation Tools'}
  },
  {
    path: 'pipeline',
    component: PipelineNewComponent
  },
  {
    path: 'pipeline/:id',
    component: PipelineNewComponent,
  },
  {
    path: 'pipeline_spec/:pipelineSpecId',
    component: PipelineNewComponent,
  },
  {
    path: '_version',
    component: VersionComponent,
    data: {title: 'IEDB Version Info'},
  },
  {
    path: '',
    component: HomeComponent,
    data: {title: 'IEDB Next-Generation Tools'},
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
