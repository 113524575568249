import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
// import {tap} from "rxjs/operators";

import Stage from "../../../_global/Stage";
import Tool from "../../../_global/Tool";
import PipelineParams from "../../../_global/PipelineParams";
import {StateService} from "../state.service";
import {PipelineService} from "../../pipeline-service/pipeline.service";
import {
  initTCellPredictionState, initTCellPredictionTool,
  initClusterState, initClusterTool, initPepmatchTool, initPepMatchState
} from "../default-stage-states";


/**
 {
  "pipeline_id": "",
  "run_stage_range": [
    1,
    1
  ],
  "stages": [
    {
      "stage_number": 1,
      "stage_type": "prediction",
      "tool_group": "mhci",
      "input_sequence_text": ">LCMV Armstrong, Protein GP\nMGQIVTMFEALPHIIDEVINI\n",
      "input_parameters": {
        "alleles": "H2-Kb,H2-Db",
        "peptide_length_range": [
          9,
          10
        ],
        "predictors": [
          {
            "type": "binding",
            "method": "netmhcpan_el"
          }
        ]
      }
    }
  ]
 * */

interface PipelineState {
  pipeline_id: string,
  pipeline_spec_id: string,
  run_stage_range: [number, number],
  inputSequenceText?: string,
  inputVcfText?: string,
  stages: Stage[],
  tools: Tool[],
  // table_states: TableState[],
  params: PipelineParams
}

const initialState: PipelineState = {
  pipeline_id: '',
  pipeline_spec_id: '',
  inputSequenceText: '',
  // inputVcfText: '',
  run_stage_range: [1, 1],
  stages: [],
  tools: [],
  params: {
    pipeline_id: '',
    run_stage_range: [1, 1],
    stages: []
  } // last parameter sent to api
};

@Injectable({
  providedIn: 'root'
})
export class PipelineStateService extends StateService<PipelineState> {

  stages$: Observable<Stage[]> = this.select(state => state.stages);
  tools$: Observable<Tool[]> = this.select(state => state.tools);
  pipelineId$: Observable<string> = this.select(state => state.pipeline_id);
  pipelineSpecId$: Observable<string> = this.select(state => state.pipeline_spec_id);
  inputSequenceText$: Observable<string | undefined> = this.select(state => state.inputSequenceText);
  inputVcfText$: Observable<string | undefined> = this.select(state => state.inputVcfText);
  params$: Observable<PipelineParams> = this.select(state => state.params);

  constructor(
    private _pipelineService: PipelineService
  ) {
    super(initialState); // now need to set stages
    this.stages$.subscribe(value => {
      // Default initial state - set to initTCellPredictionState
      console.log(value);
      if (value.length === 0) {
        this.setState({
          stages: [
            initTCellPredictionState
          ]
        })
      }
    })
    this.tools$.subscribe(value => {
      // Default initial state - set to initTCellPredictionState
      console.log(value);
      if (value.length === 0) {
        this.setState({
          tools: [
            initTCellPredictionTool
          ]
        })
      }
    })
  }

  /** Params */
  setParams(params: any) {
    this.setState({params})
  }

  setPipelineId(pipelineId: string) {
    this.setState({pipeline_id: pipelineId});
  }

  setPipelineSpecId(pipelineSpecId: string) {
    this.setState({pipeline_spec_id: pipelineSpecId});
  }

  setPipelineIdToParams(pipelineId: string) {
    const params = {...this.state.params} // hard copy
    console.log(params);
    params.pipeline_id = pipelineId
    this.setState({params})
  }

  setStagesToParams(stages: Stage[]) {
    /**
     Used in GET Pipeline to set all stages at once.
     * */
    const params = {...this.state.params} // hard copy
    console.log(params);
    params.stages = stages;
    this.setState({params})
  }


  addStageToParams(stage: Stage) {
    const params = {...this.state.params} // hard copy
    console.log(params);
    params.stages.push(stage);
    this.setState({params})
  }

  insertStageToParams(stage: Stage, index: number) {
    const params = {...this.state.params} // hard copy
    let stages = params.stages;
    stages[index] = stage;
    params.stages = stages;
    // console.log(params);
    this.setState({params})
  }

  applyFiltersToParams(stageIndex: number, columns: any) {
    /**
     * Adds columns object as table_state.columns in params
     *
     * */
    const params = {...this.state.params} // hard copy
    const stages = params.stages;
    console.log(stages);
    const stageTemp = stages[stageIndex];
    stageTemp.table_state.columns = columns;
    stages[stageIndex] = stageTemp
    params.stages = stages;
    this.setState({params})
  }

  popStageFromParams() {
    const params = {...this.state.params} // hard copy
    let stages = params.stages;

    stages.pop();

    params.stages = stages;
    this.setState({params})
  }

  /**
   * Stage
   * */

  addStage(stage: Stage) {
    /**
     Add T-cell or Cluster (no results)
     * */
    this.setState({
      stages: [...this.state.stages, stage]
    })
  }

  setStages(stages: Stage[]) {
    /**
     Used in GET Pipeline to set all stages at once.
     * */
    this.setState({
      stages: stages
    })
  }

  changeResultUrl(url: string, index: number) {
    let stages = [...this.state.stages]
    stages[index].result_url = url
    stages[index].stage_messages!.warnings = []
    stages[index].stage_messages!.errors = []
    this.setState({
      stages: stages
    })
  }

  setInitialStage(stage: Stage) {
    /**
     Used in navbar component
     * */
    this.setState({
      stages: [stage]
    })
  }

  insertStage(stage: Stage, index: number) {
    let stages = [...this.state.stages]
    stages[index] = stage;
    this.setState({stages})
  }

  insertTableStateToStage(table_state: any, index: number) {
    let stages = [...this.state.stages]
    stages[index].table_state = table_state;
    this.setState({stages})
  }

  insertInputParametersToStage(input_parameters: any, index: number) {
    let stages = [...this.state.stages]
    stages[index].input_parameters = input_parameters;
    this.setState({stages})
  }

  popStage() {
    let stages = [...this.state.stages]
    stages.pop();
    this.setState({stages});
  }

  /**
   * Tool
   * */
  setTools(tools: Tool[]) {
    this.setState({
      tools
    })
  }


  setInitialTool(tool: Tool) {
    const copy = Object.assign({}, tool)
    /**
     Used in navbar component
     * */
    this.setState({
      tools: [copy]
    })
  }

  insertTool(tool: Tool, index: number) {
    let tools = [...this.state.tools]
    tools[index] = tool;
    this.setState({tools})
  }

  popTool() {
    let tools = [...this.state.tools]
    tools.pop();
    this.setState({tools})
  }

  setToolDisabled(disabled: boolean, index: number) {
    let tools = [...this.state.tools]
    const tempTool = tools[index];
    tempTool.disabled = disabled;
    tools[index] = tempTool;
    this.setState({tools})
  }

  setToolLoading(loading: boolean, submitted: boolean, index: number) {
    let tools = [...this.state.tools]
    const tempTool = tools[index];
    tempTool.loading = loading;
    tempTool.submitted = submitted;
    tools[index] = tempTool;
    this.setState({tools})
  }

  setToolAppliedFilters(appliedFilters: boolean, index: number) {
    let tools = [...this.state.tools]
    const tempTool = tools[index];
    tempTool.appliedFilters = appliedFilters;
    tools[index] = tempTool;
    this.setState({tools})
  }

  insertInputDataUriToTool(input_data_uri: any, index: number) {
    let tools = [...this.state.tools]
    tools[index].input_data_uri = input_data_uri;
    this.setState({tools})
  }

  setInputSequenceText(value: string) {
    /**
     updates inputSequenceText: string
     * */
    this.setState({inputSequenceText: value});
  }
  setInputVcfText(value: string) {
    /**
     updates InputVcfText: string
     * */
    this.setState({inputVcfText: value});
  }
}

/**
 * state.service.ts
 export class StateService<T> {

  private state$: BehaviorSubject<T>;
  protected get state(): T {
    return this.state$.getValue();
  }

  constructor(initialState: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  protected select<K>(mapFn: (state: T) => K): Observable<K> {
    return this.state$.asObservable().pipe(
      map((state: T) => mapFn(state)),
      distinctUntilChanged()
    );
  }

  protected setState(newState: Partial<T>) {
    this.state$.next({
      ...this.state,
      ...newState,
    });
  }
}
 */
