<div class="container" id="tool-contribution-component">
  <div class="d-flex flex-column">
    <h3 class="p-3 text-info"> CONTRIBUTING TO THE IEDB NG TOOLS</h3>
    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <p>
        An overarching goal of the IEDB is to be the central repository for tools that are of general use to the
        immunology and immunoinformatics community.
        We encourage developers of such tools to contact us to inquire about hosting your tool at the IEDB.
        The IEDB team would work with developers to create a web portal and keep it up and running indefinitely.
        We believe this arrangement benefits all parties involved and the immunology community as a whole.
        The process for submitting your tool for inclusion at the IEDB NG Tools is outlined below.
      </p>
    </div>
    <h3 class="p-3 text-info"> TOOL CONTRIBUTION PROCESS</h3>
    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <h3 class="">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-envelope"
               viewBox="0 0 16 16">
            <path
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
          </svg>
          1. Submission
        </h3>
      </div>
      Email help@iedb.org and include:
      <ul>
        <li>
          A summary of the problem that your tool addresses and what it is of general interest
        </li>
        <li>
          The publication status of your tool
        </li>
        <li>
          If there’s a web server that hosts the tool, please provide URL
        </li>
        <li>
          Time frame in which you will be ready to hand off your tool to IEDB developers
        </li>
      </ul>
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
        <h3 class="mx-3">
          2. Evaluation
        </h3>
      </div>
      Submissions will be evaluated by IEDB staff to determine whether the tool fits within the scope of the IEDB and we
      have the capability (hardware, personnel, etc) to implement it.
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
             class="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
        </svg>
        <h3 class="mx-3">
          3. Decision
        </h3>
      </div>
      You will receive a reply within 4 weeks with either a decision or a request for further information. If your tool
      is approved for inclusion, you will work with IEDB developers to hand off code and create a web portal at the
      IEDB.
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-bug-fill"
             viewBox="0 0 16 16">
          <path
            d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z"/>
          <path
            d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z"/>
        </svg>
        <h3 class="mx-3">
          4. Testing
        </h3>
      </div>
      The tool will be thoroughly tested for bugs and the load it exerts on the IEDB servers.
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-upload"
             viewBox="0 0 16 16">
          <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path
            d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
        </svg>
        <h3 class="mx-3">
          5. Tools Go Live
        </h3>
      </div>
      After you give the go-ahead, links will be made public and it will be officially announced under 'New and Noteworthy'
      and the 'Release Notes' on the IEDB support pages, and incorporated into the IEDB's next submission to the annual NAR webserver issue.
    </div>

    <div class="p-3 mb-2 bg-info bg-opacity-50">
      <div class="d-flex flex-row">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-clock"
             viewBox="0 0 16 16">
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
        </svg>
        <h3 class="mx-3">
          6. Updates
        </h3>
      </div>
      Any updates you make to the tool can be applied, tested, and released in our 6-month development cycle.
    </div>
  </div>
</div>
