<footer class="footer container-fluid p-2 mt-5" style="height: 50px">
  <div class="d-sm-flex justify-content-center">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
      © 2005-2024 | <a href="https://www.iedb.org/" target="_blank">IEDB Home</a> |
      <a href="mailto: help@iedb.org">Contact</a> |
      <a [routerLink]="[]" (click)="showCookieDialog()">Privacy settings</a>
<!--      <a [routerLink]="[]" (click)="open(cookieModal)">Cookie settings</a>-->

<!--      <a href="https://www.lji.org/" target="_blank">La Jolla Institute for Immunology</a>. All rights reserved.-->
    </span>
  </div>
</footer>

<!--Cookie Modal-->
<ng-template #cookieModal let-cookieModal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-email-title">
      Cookies
    </h4>
<!--    <button type="button" id="CookieModalCloseButton" class="btn-close" aria-label="Close"-->
<!--            (click)="modal3.dismiss('Cross click');">-->
<!--    </button>-->
  </div>

  <div class="modal-body">
    <p class="smallerText text-start">
      We use cookies to provide you with an optimal website experience. By continuing to use the website, you agree to the use of cookies.
      You can learn more about the cookies we use here: <a href="/datenschutz">Privacy Policy</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-outline-primary" (click)="cookieModal.dismiss()">Accept</button>
  </div>

</ng-template>
