import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  isDevMode
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UntypedFormBuilder, UntypedFormGroup, FormControl, UntypedFormArray, FormGroupDirective, Validators} from "@angular/forms";
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Location} from '@angular/common';
import {Title} from "@angular/platform-browser";

import {Observable, timer, of, from, Subject, Subscription} from "rxjs";
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {catchError, debounce, debounceTime, distinctUntilChanged, tap, switchMap, takeUntil} from 'rxjs/operators';

// import {Options, ChangeContext} from '@angular-slider/ngx-slider';
import {Options, ChangeContext} from 'ngx-slider-v2';
import {isASCII, IsJsonString, ltrim}from "../../util/string-helpers";
import {isMaxLength} from "../../util/is-max-length";
import {SequenceParserService} from "../../services/sequence-parser/sequence-parser.service";
import {AlleleSearchService} from "../../services/allele-search/allele-search.service";
import {PipelineService} from "../../services/pipeline-service/pipeline.service";
import {AlertServiceService} from "../../services/alert-service/alert-service.service";
import Columns from "../../_global/Columns";
import Allele from "../../_global/Allele";

import onCsvDownloadAllClickedNew from '../../util/on-csv-download';
import * as sarsSampleData from "../../services/data/t-cell-sample.json";
import * as pipelineData from "../../services/data/pipeline-example.json";
import * as alleleData from "../../services/data/27-alleles.json";
import _ from "lodash";

import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";
import {PipelineStateService} from "../../services/state-services/pipeline-state-service/pipeline-state.service";
import Stage from "../../_global/Stage";
import PipelineParams from "../../_global/PipelineParams";
import Tool from "../../_global/Tool";
import {
  initTCellPredictionTool
} from "../../services/state-services/default-stage-states";

import {columnListToObject} from "../../util/convert-columns";

const API_URL = `${environment['api_url']}/api/v1/mhci`;

@Component({
  selector: 'app-t-cell-prediction',
  templateUrl: './t-cell-prediction.component.html',
  styleUrls: ['./t-cell-prediction.component.scss']
})
export class TCellPredictionComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  /** Pipeline form */
  @Input() formGroupName!: string
  @Input() index!: number;
  @Input() resultUrl!: string | undefined;
  @Input() emailForm!: UntypedFormGroup;

  active = 'processing_plots'

  @Output() emitIndex = new EventEmitter<number>(); // used for run all

  @Output() openEmailModal = new EventEmitter<any>();

  @Output() emitOnCancel = new EventEmitter<any>();

  @ViewChild('alleleSearch') alleleSearch!: ElementRef;
  @ViewChild('alertModal') alertModal!: ElementRef;
  @ViewChild('confirmModal') confirmModal!: ElementRef;
  @ViewChild('alleleModal') alleleModal!: ElementRef;

  unsubscribe$ = new Subject();

  stages$: Observable<Stage[]> = this._stateService.stages$;
  tools$: Observable<Tool[]> = this._stateService.tools$;
  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  params$: Observable<PipelineParams> = this._stateService.params$;

  stages: Stage[] = [];
  tools: Tool[] = [];
  pipelineId = '';
  params!: PipelineParams;

  sequence_table_result_link = ''

  /** Form variables */
  tCellForm!: UntypedFormGroup;
  // emailForm!: FormGroup;

  inputChange = {
    /** Keeps track of inputChanges to highlight selected parameter table row*/
    input_sequence_text: false,
    alleles: false,
    lengths: false,
    predictors: false,
    table_state: false,
  }
  peptideLengthSliderOptions: Options = { // ngx-slider option
    showTicksValues: true,
    disabled: false,
    stepsArray: [
      {value: 8},
      {value: 9},
      {value: 10},
      {value: 11},
      {value: 12},
      {value: 13},
      {value: 14}
    ]
  };

  predictionOptions: {display_name: string, short_name: string, group: string, source: string, has_ic50: boolean}[] = [];
  basicProcessingPredictionOption: {display_name: string, short_name: string, group: string, source: string, has_ic50: boolean}[] = []
  processingOptions: {display_name: string, short_name: string, group: string, source: string, has_ic50: boolean}[] = [];

  mhciLoading: boolean = false;
  mhciPredictors: any[] = [];
  // bindingPredictors: object[] = [];

  /** sequence variables */
  synthesisDownloadLinks = {
    sequence_list_json_uri: '',
    sequence_list_fasta_uri: '',
    download_sequences: ''
  };

  swaggerUrl = `${environment['api_url']}/apidocs`

  filteredSequences: { name: string, sequences: string }[] = [];
  numOfAA: number = 0;
  numOfCharacters: number = 0;
  seqFormat: string = 'Unknown';

  /** API Call variables */
  runSetInterval: any;
  submitted = false;
  loading = false;
  getResultsClicked = true;
  pipelineLoading = false;
  tableStateSaving = false;
  saveState = false;

  resultId: number = 0;

  plotLoading = false;
  processingPlots: {method: string, url: string, thresholdInput: number}[] = []
  selectedSeqNumPlots = '';
  selectedAllelePlots = '';
  selectedLengthPlots = ''
  sequenceNumbers = []

  sequence_table_order: any[] = [];
  sequence_table_columns: Columns[] | any;
  sequence_table: [(string | number)[]] = [[]]

  residue_table_order: any[] = [];
  residue_table_columns: Columns[] | any;
  residue_table: [(string | number)[]] = [[]]

  allele_distances_table_order: any[] = [];
  allele_distances_table_columns: Columns[] | any;
  allele_distances_table: [(string | number)[]] = [[]]

  peptide_table_order: any[] = [];
  peptide_table_columns: Columns[] | any;
  peptide_table: [(string | number)[]] = [[]]

  defaultOrder: any[] = [];

  // apiStatus: string;
  errorMessages: string[] = [];
  warningMessages: string[] = [];
  closeResult = '';

  /** Alleles */
  searching = false;
  searchFailed = false;

  modalAlleles: Allele[] = [];
  modalOpen = false;

  /** sequence files */
  maxCharacters = 1000000;
  files: any[] = [];
  fileName = '';

  pipelineUrl: string = ''

  data: any

  @Input() events: Observable<void> = of();
  private eventsSubscription: Subscription  = new Subscription();

  @Input() runAllEvent: Observable<void> = of();
  private runAllSubscription: Subscription  = new Subscription();

  constructor(
    public alertService: AlertServiceService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private _alleleSearchService: AlleleSearchService,
    private _pipelineService: PipelineService,
    private _stateService: PipelineStateService,
    private rootFormGroup: FormGroupDirective, // pipeline component
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private location: Location,
    private sequenceParserService: SequenceParserService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    if (isDevMode()) {
      console.log('Development!');
    } else {
      console.log('Production!');
    }

    this.getMHCIList();
    // this.tCellForm = this.fb.group(this.initialControlConfig);
    this.tCellForm = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup;
    this.peptideLengthSliderOptions = Object.assign({}, this.peptideLengthSliderOptions, {disabled: this.breakPeptides});

    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`pipelineId=${value}`);
      this.pipelineId = value;
      this.pipelineUrl = `${window.location.origin}/pipeline/${value}`;
    })
    this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(value);
      this.stages = value;
      this.errorMessages = this.stages[this.index]?.stage_messages!.errors
      this.warningMessages = this.stages[this.index]?.stage_messages!.warnings
    })
    this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('tools$');
      console.log(value);
      this.tools = value;
      this.submitted = this.tools[this.index]?.submitted

      const inputSequenceTextId = this.tools[this.index]?.input_data_uri?.input_sequence_text_id

      this.synthesisDownloadLinks.sequence_list_json_uri = `${environment['api_url']}/api/v1/sequence_list_json/${inputSequenceTextId}`
      this.synthesisDownloadLinks.sequence_list_fasta_uri = `${environment['api_url']}/api/v1/sequence_list_fasta/${inputSequenceTextId}`
      this.synthesisDownloadLinks.download_sequences = `${environment['api_url']}/api/v1/download_sequences/${inputSequenceTextId}`

      // this.loading = this.tools[this.index]?.loading
      if(!this.tools[this.index]?.loading) {
        this.loading = false;
        clearInterval(this.runSetInterval)
      }

      /** pipeline Loading */
      this.pipelineLoading = _.some(this.tools, {loading: true})
      // if(this.pipelineLoading) {
      //   this.disableInputs();
      // } else {
      //   this.enableInputs();
      // }
      console.log('pipelineLoading')
      console.log(this.pipelineLoading)

    })

    this.params$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`params`);
      console.log(value);
      this.params = value;
    })

    this.eventsSubscription = this.events.subscribe(() => this.onRunMain());
    this.runAllSubscription = this.runAllEvent.subscribe(() => this.onRunAll())

    this.onChanges();

    if(this.tCellForm.get('inputSequenceText')?.value) {
      this.sequenceParser(this.tCellForm.get('inputSequenceText')?.value)
    }

    // if(this.loading) {
    //   this.disableInputs()
    // }

    // if(this.resultUrl) {
    //   this.loading = true
    //   this.getResultsSetInterval(this.resultUrl)
    // }
  }

  ngOnDestroy() {
    clearInterval(this.runSetInterval);
    clearInterval(this._pipelineService.runSetInterval)
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.eventsSubscription.unsubscribe();
    this.runAllSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const run = history.state?.run;
      // console.log(run);
      run && this.onRunMain();
    }, 0)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.resultUrl?.currentValue) {
      console.log(changes.resultUrl.currentValue);
      console.log('ngOnChanges t-cell')
      this.loading = true;
      this.getResultsSetInterval(changes.resultUrl.currentValue);
    }
  }

  get selectedAlleleLabels() {
    return this.alleles.value.map(((o: { label: string; }) => o.label)).join(', ');
  }

  get selectedModalAlleleLabels() {
    return this.modalAlleles.map(((o: { label: string; }) => o.label)).join(', ');
  }

  get predictionModels() {
    return this.tCellForm.get('predictionModels') as UntypedFormArray;
  }

  get alleles() {
    return this.tCellForm.get('alleles') as UntypedFormArray;
  }

  get breakPeptides() : boolean {
    return this.tCellForm.get('breakPeptides')?.value;
  }

  get inputSequenceText(){
    return this.tCellForm.get('inputSequenceText')?.value
  }

  get peptideLength() {
    return this.tCellForm.get('peptideLength')?.value;
  }

  /** processing plot filters **/

  // get sequenceNumberRange() {
  //   const seq_num_arr =_.map(this.peptide_table, (o) => o[0])
  //   return [_.min(seq_num_arr), _.max(seq_num_arr)] as number[]
  // }

  get stageAlleles() {
    return this.stages[this.index]?.input_parameters?.alleles?.split(',');
  }

  get stagePeptideLengthRange() {
    return this.stages[this.index]?.input_parameters?.peptide_length_range;
  }

  range(start: number, end: number) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
  }

  getMHCIList() {
    this.mhciLoading = true;
    this.http.get<any[]>(API_URL)
      .pipe().subscribe(
      resp => {
        // @ts-ignore
        this.mhciPredictors = resp['predictors'];
        // console.log(this.mhciPredictors);
        this.predictionOptions = _.filter(this.mhciPredictors, {'group': 'binding'});
        this.basicProcessingPredictionOption = _.filter(this.mhciPredictors, {'group': 'binding', 'has_ic50': true})
        this.processingOptions = _.filter(this.mhciPredictors, {'group': 'processing'});
        console.log(this.processingOptions)
        this.mhciLoading = false;
      }
    )
  }

  onRunAll() {
    this.setDefaultFormValues()
    this.emitIndex.emit(this.index);
  }

  onRunMain() {
    if(this.tCellForm.invalid){

      this.alertService.add(`Input sequence text is too long. The maximum number of characters allowed is ${this.maxCharacters.toLocaleString()}\nYour sequence contains ${this.inputSequenceText.length.toLocaleString()}`, 'alert-danger');
      this.open(this.alertModal)
      return;
    }

    const hasResult = _.find(this.tools.slice(this.index+1), (o) => o.submitted || o.loading)
    if(this.stages.length > 1 && hasResult) {
      this.open(this.confirmModal);
    } else {
      this.getResultsClicked = true;
      if(this.index===0) {
        this.onRun();
      } else {
        this.onRun2()
      }
    }
  }


  setDefaultFormValues() {
    /** defaults - use Sars placeholders*/
    let seq: string = ''
    let all: string = ''
    if(environment.production || environment['name'] === 'DEV') { // prod or dev
      const {input_sequence_text, alleles } = sarsSampleData; // correct examples
      seq = input_sequence_text
      all = alleles
    } else {
      const {input_sequence_text, alleles } = pipelineData;
      seq = input_sequence_text
      all = alleles
    }

    /** Sequences */
    let sequences = this.tCellForm.value.inputSequenceText
    if(!sequences && this.index===0) {
      this._stateService.setInputSequenceText(seq);
      this.tCellForm.controls.inputSequenceText.setValue(seq);
    }

    /** Alleles */
    const patchValues = (allele: string, synonym = '', score = null) => {
      /** patchValues - helper function */
      return this.fb.group({
        label: [allele],
        synonym: [synonym],
        score: [score]
      })
    }
    if(this.alleles.length===0) {
      _.forEach(all.split(','), (allele) => {
        this.alleles.push(patchValues(allele));
      })
      // this.alleleSearch!.nativeElement.value = alleles;
    }
  }

  onRunConfirm() {
      let indicies = _.range(this.index + 1, this.stages.length)
      let initTool: Tool;
      console.log(indicies);
      _.forEach(indicies, i => {
        initTool = this.tools[i];
        const unSubmittedTool = {
          ...initTool,
          loading: false,
          submitted: false,
        }
        this._stateService.insertTool(unSubmittedTool, i);
      })
      this.saveState ? this.onSaveTableState() : this.onRunMain();
  }

  onRun() {
    /**
     Update pipeline State
     *
     * */
    /** Reset global datatables search ext */
    $.fn.dataTable.ext.search = [];

    const stage_number = this.index + 1;
    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    this.setDefaultFormValues()
    /** Sequences */
    let sequences = this.tCellForm.value.inputSequenceText

    /** input parameters */
    const inputParameters = this._pipelineService.convertTCellForm(this.tCellForm)

    /** Table State */
    const { table_state } = this.stages[this.index];

    const newStage = {
      "stage_display_name": "T-Cell Prediction",
      "stage_number": stage_number,
      "stage_type": "prediction",
      "tool_group": "mhci",
      "input_sequence_text": sequences,
      "input_parameters": inputParameters,
      "table_state": table_state
    }

    const params = {
      "pipeline_id": '',
      "pipeline_title": this.emailForm.controls['name'].value,
      "email": this.emailForm.controls['email'].value,
      "run_stage_range": run_stage_range,
      "stages": [
        newStage
      ]
    }

    /** set params */
    const loadingTCellPredictionTool = Object.assign(initTCellPredictionTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })
    this._stateService.setParams(params);
    this._stateService.insertTool(loadingTCellPredictionTool, this.index);
    this._stateService.insertInputParametersToStage(inputParameters, this.index);

    // this._stateService.insertTableStateToStage({columns: {}}, this.index);

    this.postPipelineAPI(params);
  }


  onRun2() {

    const params = this.params;
    const stage_number = this.index + 1;
    const input_stage_number = this.index; // previous index

    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    this.setDefaultFormValues()

    /** input parameters */
    const inputParameters = this._pipelineService.convertTCellForm(this.tCellForm)
    const initialStage = this.stages[this.index];

    /** Table State */
    const { table_state } = this.stages[this.index];

    const stage: Stage = {
      ...initialStage,
      result_url: undefined,
      stage_number: stage_number,
      input_stage_number: input_stage_number,
      input_parameters: inputParameters,
      table_state
    }

    // this.params.stages.push(stage);
    params.stages[this.index] = stage;
    params.run_stage_range = run_stage_range;
    params.pipeline_title = this.emailForm.controls['name'].value;
    params.email = this.emailForm.controls['email'].value;

    // console.log(params)

    /** set params */
    const initToolLoading = Object.assign(initTCellPredictionTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })

    this._stateService.setParams(params);
    this._stateService.insertTool(initToolLoading, this.index);
    this._stateService.insertInputParametersToStage(inputParameters, this.index);

    this.postPipelineAPI(params);
  }

  getResultsSetInterval(result_url: string) {
    /** */
    this.getResultsClicked = true

    this.residue_table_order = [];
    this.residue_table_columns = null;
    this.residue_table = [[]]

    this.sequence_table = [[]]
    this.allele_distances_table = [[]]
    this.residue_table = [[]]
    this.peptide_table = [[]]
    this.processingPlots = []

    this.resultUrl = result_url;
    console.log(result_url)
    this.inputChange = _.mapValues(this.inputChange, () => false);
    let increment = 0;
    const milliseconds = 3000;
    this.runSetInterval = setInterval(() => {
      this._pipelineService.getResults(`${result_url}?statusOnly=true`).subscribe(
        {
          next: resp => {
        console.log(resp);
        if (resp['status'] === 'error' || resp['errors']) {
          // this.errorMessages = resp['errors'];
          // this.warningMessages = resp['warnings'];
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          const submittedTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTool, this.index);
          clearInterval(this.runSetInterval);
          this.enableInputs();
        }
        if (resp['data']['warnings']?.length) {
          // console.log(resp['warnings']);
          this.warningMessages = resp['data']['warnings'];
        }
        if (resp['data']['errors']?.length) {
          // console.log(resp['warnings']);
          this.errorMessages = resp['data']['errors'];
        }
        if (resp['status'] === 'done') {
          clearInterval(this.runSetInterval);

          this._pipelineService.getResults(result_url).subscribe(resp => {
            // clearInterval(this.runSetInterval);
            console.log(resp)
            this.defaultOrder = [[]]
            /** 1) Create Column */
            const data = resp['data']['results'];
            // check for residue_table
            this.active = data[0].type
            _.forEach(data, table => {


              if (table.type === 'residue_table') {
                this.residue_table_columns = _.map(table.table_columns, (column, i) => {
                  if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                    this.residue_table_order[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                  }
                  column['title'] = `${column['display_name']}`; // required
                  column['data'] = `${column['name']}`;
                  let source = column['source'];

                  column['filterKey'] = `${source}.${column.name}`

                  // console.log(`source=${source}`);
                  let tempSource = source.split('.')
                  // console.log(tempSource);
                  source = tempSource[tempSource.length - 1];
                  // console.log(`source=${source}`);
                  column['data'] = `${source}_${column['name']}`;
                  /** remove `core` from name */
                  // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                  column['value_limits'] = column['type'] === 'int' ? {
                    minValue: NaN,
                    maxValue: NaN,
                    applied: false
                  } : {
                    possibleValues: [],
                    textValue: null,
                    selectedValues: [],
                    applied: false
                  }
                  return column
                });
                // this.currentOrder = [...this.defaultOrder];
                if (this.residue_table_columns) {
                  this.residue_table = table.table_data;
                }
                // console.log(this.residue_table_order);
                // console.log(this.residue_table_columns);
                // console.log(this.residue_table);
              }
              if (table.type === 'netmhcpan_allele_distance') {
                this.allele_distances_table_columns = _.map(table.table_columns, (column, i) => {
                  if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                    this.allele_distances_table[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                  }
                  column['title'] = `${column['display_name']}`; // required
                  column['data'] = `${column['name']}`;
                  let source = column['source'];

                  column['filterKey'] = `${source}.${column.name}`

                  // console.log(`source=${source}`);
                  let tempSource = source.split('.')
                  // console.log(tempSource);
                  source = tempSource[tempSource.length - 1];
                  // console.log(`source=${source}`);
                  column['data'] = `${source}_${column['name']}`;
                  /** remove `core` from name */
                  // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                  // column['value_limits'] = column['type'] === 'int' ? {
                  //   minValue: NaN,
                  //   maxValue: NaN,
                  //   applied: false
                  // } : {
                  //   possibleValues: [],
                  //   textValue: null,
                  //   selectedValues: [],
                  //   applied: false
                  // }
                  column['value_limits'] = column['value_limits'] ? this.setValueLimits(column) : undefined
                  return column
                });
                // this.currentOrder = [...this.defaultOrder];
                if (this.allele_distances_table_columns) {
                  this.allele_distances_table = table.table_data;
                }
              }
              if (table.type === 'peptide_table') {
                this.peptide_table_columns = _.map(table.table_columns, (column, i) => {
                  if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                    this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                  }
                  column['title'] = `${column['display_name']}`; // required
                  column['data'] = `${column['name']}`;
                  let source = column['source'];

                  column['filterKey'] = `${source}.${column.name}`

                  // console.log(`source=${source}`);
                  let tempSource = source.split('.')
                  // console.log(tempSource);
                  source = tempSource[tempSource.length - 1];
                  // console.log(`source=${source}`);
                  column['data'] = `${source}_${column['name']}`;
                  /** remove `core` from name */
                  // column['name'] = column['display_name'] ? column['display_name'] : column['name']

                  column['value_limits'] = column['value_limits'] &&  this.setValueLimits(column)

                  // console.log(column['value_limits'])
                  // column['value_limits'] = column['value_limits'] ? {...column['value_limits'], applied: false, possibleValues: []} : undefined

                  return column
                });
                this.peptide_table_order = [...this.defaultOrder];
                if (this.peptide_table_columns) {
                  this.peptide_table = table.table_data;
                }
                // console.log(this.peptide_table_order);
                // console.log(this.peptide_table_columns);
                // console.log(this.peptide_table);
              }
              if (table.type === 'input_sequence_table') {
                this.sequence_table_columns = _.map(table.table_columns, (column, i) => {
                  // if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                  //   this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                  // }
                  column['title'] = `${column['display_name']}`; // required
                  column['data'] = `${column['name']}`;
                  let source = column['source'];

                  column['filterKey'] = `${source}.${column.name}`

                  // console.log(`source=${source}`);
                  let tempSource = source.split('.')
                  // console.log(tempSource);
                  source = tempSource[tempSource.length - 1];
                  // console.log(`source=${source}`);
                  column['data'] = `${source}_${column['name']}`;
                  /** remove `core` from name */
                  // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                  // column['value_limits'] = column['type'] === 'int' ? {
                  //   minValue: NaN,
                  //   maxValue: NaN,
                  //   applied: false
                  // } : {
                  //   possibleValues: [],
                  //   textValue: null,
                  //   selectedValues: [],
                  //   applied: false
                  // }

                  column['value_limits'] = column['value_limits'] ? this.setValueLimits(column) : undefined
                  // column['value_limits'] = column['value_limits'] && this.setValueLimits(column)
                  return column



                });
                this.sequence_table_order = []
                if (this.sequence_table_columns) {

                  this.sequence_table = table.table_data
                }
              }
              if (table.type === 'processing_plots') {
                const all_plots_uri = new URL(table.all_plots_uri)

                this.selectedSeqNumPlots = all_plots_uri.searchParams.get('sequence_number') || ''
                this.selectedLengthPlots = all_plots_uri.searchParams.get('length') || ''
                this.selectedAllelePlots = all_plots_uri.searchParams.get('allele') || ''

                this.sequenceNumbers = table.sequence_numbers

                this.processingPlots = _.map(table.urls, (plot) => {

                  const url = new URL(plot.url)
                  const thresholdInput = url.searchParams.get('threshold')

                  return {
                    thresholdInput,
                    ...plot
                  }
                })
                // this.processingPlots = table.urls
                console.log(this.processingPlots)
              }

            })

            this.submitted = true; this.loading = false;
            const prevTool = this.tools[this.index];
            console.log(prevTool)
            const submittedTCellPredictionTool = Object.assign(prevTool, {
              submitted: this.submitted,
              loading: this.loading,
            })
            this._stateService.insertTool(submittedTCellPredictionTool, this.index);
            // clearInterval(this.runSetInterval); // remove later
            console.log(data);
            console.log(resp.pipeline_id)
            this.enableInputs();
          })
        }
        if (resp['status'] === 'canceled') {
          /** if status is canceled, then just stop loader **/
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTCellPredictionTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTCellPredictionTool, this.index);
          clearInterval(this.runSetInterval);
          // console.log(data);
          this.enableInputs();
          // set error message
          // this.errorMessages = [`PIPELINE_ID=${this.pipelineId} w/ STATUS=${resp['status']}`]
        }
        else {
          this.getResultsClicked = false;
          console.log(`current status: ${resp['status']} - ${increment}`);
          increment += 1;
        }
      },
          error: error => {
            console.error(error);
            this.enableInputs();
            clearInterval(this.runSetInterval);
            this.loading = false;
          }
        }
      )
    }, milliseconds);
  }

  setValueLimits(column: Columns) : any {
    /* set value_limits (i.e. filters) for each column based on if type is a number/string */
    return column['type'] === 'int' || column['type'] === 'float' ? {
      ...column['value_limits'],
      minValue: NaN,
      maxValue: NaN,
      applied: false
    } : {
      // ...column['value_limits'],
      possibleValues: column['value_limits']['unique_values'],
      textValue: null,
      selectedValues: [],
      applied: false
    }
  }

  postPipelineAPI(params: PipelineParams, getResults: boolean = true) {
    /**
     * params
     * getResults: boolean
     *  false - used in 'Save Table State'
     * */
    /** initial set up */
    this.disableInputs();
    if (getResults) {
      this.errorMessages = [];
      this.warningMessages = [];
    }
    this.loading = getResults; // true - defaults
    this.submitted = !getResults; // false - defaults
    this.tableStateSaving = !getResults
    // this.residue_table_order = [];
    // this.residue_table_columns = null;
    // this.residue_table = [[]]
    //
    // this.sequence_table = [[]]
    // this.allele_distances_table = [[]]
    // this.residue_table = [[]]
    // this.peptide_table = [[]]
    // this.processingPlots = []

    this._pipelineService.postPipeline(params).subscribe(
      (resp) => {
      // Errors and Warnings
      if (resp['errors'] && resp['errors'].length) {
        console.log(resp);
        console.error(resp['errors']);
        this.errorMessages = resp['errors'];
        if (resp['warnings'].length) {
          console.log(resp['warnings']);
          this.warningMessages = resp['warnings'];
        }
        this.loading = false;
        this.submitted = true;
        const submittedTCellPredictionTool = Object.assign(initTCellPredictionTool, {
          submitted: false,
          loading: false,
        })
        this._stateService.insertTool(submittedTCellPredictionTool, this.index);
        this.enableInputs();
        return;
      }
      // Warnings
      if (resp['warnings'].length) {
        console.log(resp['warnings']);
        this.warningMessages = resp['warnings'];
      }

      const inputDataUri = {input_sequence_text_id: resp.input_sequence_text_id}
      this._stateService.insertInputDataUriToTool(inputDataUri, this.index)

      this._stateService.setPipelineId(resp.pipeline_id);
      this._stateService.setPipelineIdToParams(resp.pipeline_id);
      this._stateService.setPipelineSpecId(resp.pipeline_spec_id);
      /** Display email Modal if emailModalShow=true */
      this.location.go(`pipeline/${resp.pipeline_id}`)
      // this.titleService.setTitle(`IEDB NG Tools Pipeline`);

      // this.location.replaceState(`pipeline/${resp.pipeline_id}`)
      // this.router.createUrlTree([`pipeline/${resp.pipeline_id}`])
      if (getResults) {
        const result_url = `${resp.results_uri}`;
        console.log(result_url);
        this.getResultsSetInterval(result_url);
      } else {
        this.inputChange.table_state = false;
        this.submitted = true;
        this.loading = false;
        this.tableStateSaving = false;
        this.enableInputs();
      }
    },
      (error) => {
        console.error(error);
        this.errorMessages.push(error.error);
        this.submitted = false;
        this.loading = false;
        const prevTool = this.tools[this.index];
        const submittedTool = Object.assign(prevTool, {
          submitted: this.submitted,
          loading: this.loading,
        })
        this._stateService.insertTool(submittedTool, this.index);
        clearInterval(this.runSetInterval);
        this.enableInputs();
      }
      )
  }


  onSaveTableStateMain() {
    const hasResult = _.find(this.tools.slice(this.index+1), {submitted: true})
    if (hasResult) {
      this.saveState = true;
      this.open(this.confirmModal);
    } else {
      this.onSaveTableState();
    }
  }

  onSaveTableState() {
    const tool_group = 'mhci'

    const params = this.params
    // create colObj and params
    let colObj = columnListToObject(this.peptide_table_columns, tool_group)
    const stages = params.stages;
    const stageTemp = stages[this.index];

    stageTemp.table_state = [{table: 'peptide_table', columns: colObj}];
    stages[this.index] = stageTemp
    params.stages = stages;
    // run_stage_range
    const stage_number = this.index + 1;
    params.run_stage_range = [stage_number, stage_number];
    this._stateService.setParams(params);
    // appliedFilters
    let appliedFilters = !_.isEmpty(colObj);
    this._stateService.setToolAppliedFilters(appliedFilters, this.index)
    this.postPipelineAPI(params, false);
    this.saveState = false;
  }

  onFilterApplied() {
    this.inputChange.table_state = this.submitted;
  }

  onClearTableState() {
    this.peptide_table_columns = _.map(this.peptide_table_columns, (c) => {
      c.value_limits = c.type === 'int'? {
        minValue: NaN,
        maxValue: NaN,
        applied: false
      } :
        {
          possibleValues: [],
          textValue: null,
          selectedValues: [],
          applied: false
        }
      return c
    })
    this.inputChange.table_state = this.submitted;
  }

  onCancel() {
    console.log(this.runSetInterval)
    clearInterval(this.runSetInterval);
    this.emitOnCancel.emit()

    this.inputChange.table_state = false;
    // this.submitted = false;
    const currentTool = this.tools[this.index];
    const unSubmittedTool = {
      ...currentTool,
      loading: false,
      submitted: false,
      appliedFilters: undefined
    }
    this._stateService.insertTool(unSubmittedTool, this.index);
    this.warningMessages = [];
    this.loading = false;
    this.tableStateSaving = false;
    this.enableInputs();
  }

  onReset() {
    this.alertService.clear();
    this.errorMessages = [];
    this.warningMessages = [];

    const oldSequence = this.stages[this.index].input_sequence_text
    const input_parameters = this.params.stages[this.index].input_parameters

    console.log(input_parameters)

    // sequences
    this.filteredSequences = [];
    this.numOfAA = 0;
    // order
    this.peptide_table_order = [];
    this.defaultOrder = [];
    // tCellForm values
    const peptideLength = [9, 10];
    this.tCellForm.patchValue({
      inputSequenceText: '',
      peptideLength,
    }, {emitEvent: false});

    // selected alleles
    this.alleles.clear()

    // predictors
    const initPredictor = {
      type: 'binding',
      method: 'netmhcpan_el',
    }
    const pm = this.tCellForm.controls.predictionModels as UntypedFormArray
    pm.clear({emitEvent: false})
    pm.push(this.fb.group(initPredictor), {emitEvent: false});

    this.enableInputs();

    if(this.submitted){
      this.inputChange =  {
        ...this.inputChange,
        /** Keeps track of inputChanges to highlight selected parameter table row*/
        input_sequence_text: oldSequence !== '',
        alleles: !_.isEqual([], input_parameters.alleles),
        lengths: !_.isEqual(peptideLength, input_parameters.peptide_length_range),
        predictors: !_.isEqual([initPredictor], input_parameters.predictors),
      }
    }
  }

  onFileDropped($event: any[]) {
    console.log($event);
    const file = $event[0];
    const fileSize = file.size / 1024; // in kB
    console.log(file.type);
    if (fileSize > 20000) {
      this.alertService.add('File Size is too big! (20mB max)', 'alert-danger');
      this.open(this.alertModal);
    }
    else {
      this.prepareSequenceFileList($event);
    }
  }

  prepareSequenceFileList(files: Array<any>) {
    console.log(files);
    this.fileName = files[0].name;
    const reader = new FileReader();
    const myForm = this.tCellForm;
    for (const item of files) {
      // console.log(item);
      reader.readAsText(item);
      reader.onload = () => {
        const val = reader.result?.toString() || '';
        console.log(val);
        const v = isASCII(val);
        console.log(v);
        if (v) {
          // myForm.patchValue({inputSequenceText: val.substring(0, this.maxCharacters)});
          const w = isMaxLength(val, this.maxCharacters)
          if (w) {
            myForm.patchValue({inputSequenceText: val});
          } else {
            this.alertService.add(`Sequence is too long - max Sequence length is ${this.maxCharacters.toLocaleString("en-US")} characters`, 'alert-danger');
            this.open(this.alertModal);
          }
          // this.sequenceParser(val);  // auto parser
        } else {
          this.alertService.add('Invalid file type.\nValid file types are .txt, .fa, .fasta, .json, .faa,', 'alert-danger');
          this.open(this.alertModal);
        }
      };

      reader.onerror = () => {
        console.log(reader.error);
      };
      item.progress = 0;
      this.files.push(item);
    }
  }

  /** Add Prediction Model FormGroups */

  mhcBindingFormGroup(method = 'netmhcpan_el') {
    /**
     * Creates mhcBindingFormGroup using formBuilder
     *
     * Currently defaulted to 'netmhcpan_el'
     * */
    return this.fb.group({
      type: 'binding',
      method: this.fb.control(method),
    })
  }

  pmhcImmunogencityFormGroup(mask_choice = 'default', position_to_mask = '2,5,9') {
    /**
     * */
    return this.fb.group({
      type: 'immunogenicity',
      mask_choice: mask_choice,
      position_to_mask: this.fb.control(position_to_mask),
    })
  }

  mhcIProcessingFormGroup(proteasome = 'immuno', tap_precursor = 1, tap_alpha = 0.2) {
    /**
     *
     * Defaults:
     * {
     *   type: 'processing'
     *   proteasome: 'immuno'
     *   tap_precursor: 1,
     *   tap_alpha: 0.2
     * }
     * */
    return this.fb.group({
      type: 'processing',
      proteasome: this.fb.control(proteasome),
      tap_precursor: this.fb.control(tap_precursor),
      tap_alpha: this.fb.control(tap_alpha)
    })
  }

  mhcIProcessingNewFormGroup(method='basic_processing') {
    /**
     *
     * Defaults:
     * {
     *   type: 'processing'
     *   proteasome: 'immuno'
     *   tap_precursor: 1,
     *   tap_alpha: 0.2
     * }
     * */
    return this.fb.group({
      type: 'processing',
      method: this.fb.control(method),
      mhc_binding_method: "netmhcpan_ba",
      proteasome: "immuno",
      tap_precursor: 1,
      tap_alpha: 0.2
    })
  }

  mhcINPNewFormGroup() {
    /**
     *
     * Defaults:
     * {
     *   type: 'processing'
     *   proteasome: 'immuno'
     *   tap_precursor: 1,
     *   tap_alpha: 0.2
     * }
     * */
    return this.fb.group({
      type: 'mhcnp'
    })
  }

  /** Download Sequence File API calls
   * onDownloadJson()
   * onDownloadFasta()
   * onDownloadOriginal()
   * */

  onDownloadJson() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_json_uri, this.http, 'json');
  }

  onDownloadFasta() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_fasta_uri, this.http, 'fasta');
  }

  onDownloadOriginal() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.download_sequences, this.http, 'txt');
  }

  /** Allele Search Functions */
  onAlleleSelect({e, input}: { e: any, input: any }) {
    e.preventDefault();
    const selectedAllele = e.item;
    let labels = _.map(this.alleles.value, (allele) => allele.label)
    if (!labels.includes(selectedAllele.label)) {
      this.alleles.push(this.fb.group(selectedAllele));
    }
    // sessionStorage.setItem('selectedAlleles', JSON.stringify(this.selectedAlleles));
    input.value = '';
    this.inputChange.alleles = this.submitted;
  }

  onAlleleSelectModal({e, input}: { e: any, input: any }) {
    e.preventDefault();
    const selectedAllele = e.item;
    let labels = _.map(this.modalAlleles, (allele) => allele.label)
    if (!labels.includes(selectedAllele.label)) {
      this.modalAlleles.push(selectedAllele);
    }
    input.value = '';
  }

  onBlurAlleleSelect(e: { preventDefault: () => void; }, input: { value: string; placeholder: string; }) {
    /**
     * Displays alleles in the text box when out of focus.
     * */
    e.preventDefault();
    input.value = '';
    input.placeholder = 'Ex. HLA-A*02:01'
    if (this.alleles.length) {
      const labels = _.map(this.alleles.value, (o) => {
        return o.label;
      });
      input.value = labels.join(', ');
    }
  }

  onBlurAlleleSelectModal(e: { preventDefault: () => void; }, input: { value: string; placeholder: string; }) {
    /**
     * Displays alleles in the text box when out of focus.
     * */
    e.preventDefault();
    input.placeholder = 'Ex. HLA-A*02:01'
    input.value = this.modalAlleles.length ? this.selectedModalAlleleLabels : ''
  }

  onAlleleClear(input: { value: string; }) {
    this.inputChange.alleles = this.submitted;
    this.alleles.clear()
    input.value = '';
  }

  onAlleleClearModal(input: { value: string; }) {
    this.modalAlleles = []
    input.value = ''
  }

  onAlleleDeselect(index: number) {
    this.alleles.removeAt(index)
    this.inputChange.alleles = this.submitted;
    // input.value = '';
    // if (this.alleles.length) {
    //   input.value = this.selectedAlleleLabels
    // }
  }


  onAlleleDeselect2(index: number, input: { value: string }) {
    console.log(index)
    _.pullAt(this.modalAlleles, [index])
    const labels = this.modalAlleles.map(((o: { label: string; }) => o.label)).join(', ')
    input.value = this.modalAlleles.length ? labels : ''
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => {
          // console.log(term);
          return this._alleleSearchService.search(term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        }
      ),
      tap(() => this.searching = false)
    )

  resultFormatter = (result: any) => {
    /**
     *  The function that converts an item from the result list to a string to display in the popup.
     * */
    return result.synonyms.length > 0 ? `${result.label} - (${result.synonyms})` : result.label;
  }

  inputFormatter(value: any): any {
    /**
     * The function that converts an item from the result list to a string to display in the <input> field.
     * */
    if (value.label) {
      return `${value.label}`;
    }
  }

  /** Predictors Drag and Drop */

  dropPredictionModel(event: CdkDragDrop<string[]>) {
    const {previousIndex, currentIndex} = event;

    const control = this.predictionModels.at(previousIndex);
    this.predictionModels.removeAt(previousIndex);
    this.predictionModels.insert(currentIndex, control);

    if (previousIndex !== currentIndex) {
      this.inputChange.predictors = this.submitted;
    }
  }

  setInputChangePredictors(value: boolean) {
    this.inputChange.predictors = this.submitted;
  }

  removePredictorByIndex(index: number) {
    this.predictionModels.removeAt(index);
    this.inputChange.predictors = this.submitted;
  }

  addpredictionModel() {
    this.predictionModels.push(this.mhcBindingFormGroup());
    this.inputChange.predictors = this.submitted;
  }

  addPmhcImmungencityFormGroup() {
    this.predictionModels.push(this.pmhcImmunogencityFormGroup());
    this.inputChange.predictors = this.submitted;
  }

  // addMhcIProcessingFormGroup() {
  //   this.predictionModels.push(this.mhcIProcessingFormGroup());
  //   this.inputChange.predictors = this.submitted;
  // }

  addMhcIProcessingNewFormGroup() {
    this.predictionModels.push(this.mhcIProcessingNewFormGroup());
    this.inputChange.predictors = this.submitted;
  }

  addMhcNPFormGroup() {
    this.predictionModels.push(this.mhcINPNewFormGroup());
    this.inputChange.predictors = this.submitted;
  }

  get styleResultContainer(){
    return !this.loading && this.submitted ? 'none' : 'dotted';
  }

  /** Prediction Parameter Functions */

  formUrl() {
    /**
     http://localhost:4200/t-cell-prediction?params=
     * */
  }

  onDownloadParameter() {
    const data = this._pipelineService.convertTCellForm(this.tCellForm)

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
    // var blob = new Blob([csvData], { type: contentType });
    const blob = new Blob([JSON.stringify(data, null, 2)], ); // thx: http://stackoverflow.com/a/18925211
    a.href = window.URL.createObjectURL(blob);
    a.download = 'parameter.json';

    a.click();
  }

  /** File Download/Upload*/
  uploadSequenceFile($event: any) {
    $event.preventDefault();
    /**
     * Used for 'Upload' button
     * */
    const {files} = $event.target;
    files && this.onFileDropped(files);
  }

  uploadParameterFile($event: any, input: any) {
    console.log($event);
    $event.preventDefault();
    /**
     * Used for 'Upload' button to upload parameters
     * */
    // const {files} = $event.target;
    // files && this.onParameterFileDropped(files, input);
  }

  sequenceParser(val: string) {

    const firstChar = ltrim(val)[0];
    const firstLine = val.split('\n')[0]
    console.log(firstLine)

    /** Named white space */
    let firstLineNumOfElements = 0
    let separator = ' '
    if(firstLine.includes(' ')){
      separator = ' '
    } else if (firstLine.includes('\t')){
      separator = '\t'
    } else if (firstLine.includes('\s')){
      separator = '\s'
    }
    firstLineNumOfElements = firstLine.split(separator).length
    console.log(firstLineNumOfElements)
    console.log(separator)

    const slicedVal = val.slice(0, this.maxCharacters)

    if(val.length > this.maxCharacters) {
      //  Skip parsing
    } else if (firstChar === '>' && this.sequenceParserService.fastaValidate(val)) { // fasta
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if ((firstChar === '{' || firstChar === '[') && IsJsonString(val)) { // json
      this.seqFormat = 'JSON'
      this.numOfCharacters = slicedVal.length
    } else if (firstLineNumOfElements === 2 && this.sequenceParserService.namedWhiteSpaceValidate(val, separator)) {
      // named white space
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val.length > 3 && !val.includes('>') && this.sequenceParserService.unnamedWhiteSpaceValidate(val)) {
      // Unnamed
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val === '') {  // delete
      /**
       * Takes care of it up top - still need this if statement
       * */
      this.seqFormat = 'Unknown';
      this.numOfCharacters = 0
    } else { // error
      this.seqFormat = 'Error';
      this.numOfCharacters = 0
    }
  }

  onLengthChange(changeContext: ChangeContext): void {
    /**
     * used in (userChangeEnd)
     * Saves minPeptideLength and maxPeptideLength in session storage
     * */
    // sessionStorage.setItem('minPeptideLength', JSON.stringify(changeContext.value));
    // sessionStorage.setItem('maxPeptideLength', JSON.stringify(changeContext.highValue));
    this.inputChange.lengths = this.submitted;
  }

  onChanges(): void {
    /** PredictionModel sessionStorage */
    this.tCellForm.get('predictionModels')?.valueChanges.subscribe(val => {
      // sessionStorage.setItem('predictionModels', JSON.stringify(val));
      this.inputChange.predictors = this.submitted
    });

    const sequenceFieldChange = this.tCellForm.get('inputSequenceText')?.valueChanges.pipe(
      debounce(v => timer(1000)),
    );

    this.tCellForm.get('breakPeptides')?.valueChanges.subscribe(val => {
      this.peptideLengthSliderOptions = Object.assign({}, this.peptideLengthSliderOptions, {disabled: val});
    })

    sequenceFieldChange?.subscribe(val => {
      console.log('sequenceFieldChange')
      // sessionStorage.setItem('sequences', val);
      this.sequenceParser(val);
      this.inputChange.input_sequence_text = this.submitted;
      if (val === '') {  // delete
        this.filteredSequences = [];
        this.numOfAA = 0;
        // this.alertService.clear();
        // this.myForm.patchValue({
        //   inputFormat: 0
        // });
      }
    });
  }

  /** Allele Modal Functions */
  open(content: any) {
    this.modalOpen = true;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true,
      backdrop: 'static'
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
      this.saveState = false;
      this.modalOpen = false;
    }, (reason) => {
      this.saveState = false;
      this.alleleSearch.nativeElement.focus();
      // this.closeResult = `Dismissed ${TCellPredictionComponent.getDismissReason(reason)}`;
    });
  }

  onOpenAlleleModal() {
    this.open(this.alleleModal)
    this.modalAlleles = [...this.alleles.value]
    console.log(this.modalAlleles)
  }

  dismissAlleleModal() {
    this.modalOpen = false;
    this.modalService.dismissAll()
  }

  onSubmitModalAlleles() {
    this.alleles.clear()
    _.forEach(this.modalAlleles, (selectedAllele) => {
      this.alleles.push(this.fb.group(selectedAllele));
    })
    this.inputChange.alleles = this.submitted;
    this.modalService.dismissAll()
    this.modalOpen = false;
  }

  generate27Alleles(input: any) {
    this.modalAlleles = []
    // this.alleles.clear()
    const { alleles } = alleleData;
    _.forEach(alleles.split(','), (allele) => {this.modalAlleles.push({label: allele, synonym: null, score: null})})
    input.value = this.selectedModalAlleleLabels;
  }

  enableInputs() {
    // this.tCellForm.enable({emitEvent: false});
    this.peptideLengthSliderOptions = Object.assign({}, this.peptideLengthSliderOptions, {disabled: this.breakPeptides});
  }

  disableInputs() {
    // this.tCellForm.disable({emitEvent: false});
    this.peptideLengthSliderOptions = Object.assign({}, this.peptideLengthSliderOptions, {disabled: true});
  }

  onErrorMessageDeselect(message: string, index: number) {
    if (index > -1) {
      this.errorMessages.splice(index, 1);
    }
    this.errorMessages = _.filter(this.errorMessages, (em) => em !== message);
  }

  onWarningMessageDeselect(message: string, index: number) {
    if (index > -1) {
      this.warningMessages.splice(index, 1);
    }
    this.warningMessages = _.filter(this.warningMessages, (wm) => wm !== message);
  }

  changePlotThreshold(url: string, i: number) {
    const value = this.processingPlots[i].thresholdInput
    const tempUrl = url.split('?')[0]
    const params = new URL(url).searchParams
    params.set('threshold', `${value}`)
    this.processingPlots[i].url = `${tempUrl}?${params}`
  }



  changePlotsBySequence(e: any) {
    const newUrl = `${this.resultUrl}/plots?sequence_number=${e.target.value}&length=${this.selectedLengthPlots}&allele=${this.selectedAllelePlots}`
    console.log(newUrl)
    this.plotLoading = true;
    this.http.get(newUrl).subscribe((value: any) => {
      console.log(value)
      this.processingPlots = _.map(value.urls, (plot) => {

        const url = new URL(plot.url)
        const thresholdInput = url.searchParams.get('threshold')

        return {
          thresholdInput,
          ...plot
        }
      })
      this.plotLoading = false;
    })
  }

  changePlotsByAllele(e: any) {
    const newUrl = `${this.resultUrl}/plots?sequence_number=${this.selectedSeqNumPlots}&length=${this.selectedLengthPlots}&allele=${e.target.value}`
    console.log(newUrl)
    this.plotLoading = true;
    this.http.get(newUrl).subscribe((value: any) => {
      console.log(value)
      this.processingPlots = _.map(value.urls, (plot) => {

        const url = new URL(plot.url)
        const thresholdInput = url.searchParams.get('threshold')

        return {
          thresholdInput,
          ...plot
        }
      })
      this.plotLoading = false;
    })
  }

  changePlotsByLength(e: any) {
    const newUrl = `${this.resultUrl}/plots?sequence_number=${this.selectedSeqNumPlots}&length=${e.target.value}&allele=${this.selectedAllelePlots}`
    console.log(newUrl)
    this.plotLoading = true;
    this.http.get(newUrl).subscribe((value: any) => {
      console.log(value)
      this.processingPlots = _.map(value.urls, (plot) => {

        const url = new URL(plot.url)
        const thresholdInput = url.searchParams.get('threshold')

        return {
          thresholdInput,
          ...plot
        }
      })
      this.plotLoading = false;
    })
  }

  // sendNotification() {
  //   const param = {
  //     request_id: this.resultId,
  //     email: this.emailForm.get('email')?.value,
  //     job_title: this.emailForm.get('jobTitle')?.value
  //   }
  //   this.http.post<any[]>(EMAIL_URL, param)
  //     .subscribe(resp => {
  //       console.log(resp);
  //       this.modalService.dismissAll('sendNotification()');
  //     })
  // }

}
