import { Component, OnInit } from '@angular/core';
import {NgModel} from "@angular/forms";
import onCsvDownloadAllClickedNew from "../util/on-csv-download";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
// import * as data from "./"

@Component({
  selector: 'app-download-all',
  templateUrl: './download-all.component.html',
  styleUrls: ['./download-all.component.scss']
})
export class DownloadAllComponent implements OnInit {

  selectedTool = 'mhc-class-i-binding'

  constructor(
    private http: HttpClient,
    private router: Router, private activatedRoute: ActivatedRoute, private location: Location,
  ) {
    this.selectedTool = this.router.getCurrentNavigation()?.extras?.state ? this.router.getCurrentNavigation()!.extras.state?.tool : 'mhc-class-i-binding'
  }

  ngOnInit(): void {}

  onAgreeAndDownload() {
    /**
     T Cell, Class I
     http://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=tcell_mhci

     Cluster
     http://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=cluster

     PepMatch (will be uploaded soon)
     http://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=pepmatch
     * */
    switch (this.selectedTool){
      case 'mhc-class-i-binding':
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=tcell_mhci', '_self')
        return
      case 'pepmatch':
        // window.open('https://github.com/IEDB/PEPMatch', "_blank")
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=pepmatch', "_self")
        return
      case 'cluster':
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=cluster', '_self')
        return
      case 'pepx':
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=pepx', '_self')
        return
      case 'pepcomp':
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=peptide_variant_comparison', '_self')
        return
      case 'mutgen':
        window.open('https://downloads.iedb.org/nextgen-tools/retrieve_package.php?tool=mutated_peptide_generator', '_self')
        return
      default:
        return
    }
  }

  downloadNonProfit() {
    const name = 'LICENSE-NonProfitOSL3'

    function downLoadFile(data: any, type: string) {
      const blob = new Blob([data], {type: type}); // thx: http://stackoverflow.com/a/18925211
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
      // var blob = new Blob([csvData], { type: contentType });
      a.href = window.URL.createObjectURL(blob);
      a.download = `${name}`
      a.click();
    }

    this.http.get(`assets/LICENSES/${name}`, { responseType: 'text' })
      .subscribe(data => {
        downLoadFile(data, 'txt')
        // console.log(data)
      });
  }

  downloadDTU() {
    const name = 'LICENSE-DTU'

    function downLoadFile(data: any, type: string) {
      const blob = new Blob([data], {type: type}); // thx: http://stackoverflow.com/a/18925211
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
      // var blob = new Blob([csvData], { type: contentType });
      a.href = window.URL.createObjectURL(blob);
      a.download = `${name}`
      a.click();
    }

    this.http.get(`assets/LICENSES/${name}`, { responseType: 'text' })
      .subscribe(data => {
        downLoadFile(data, 'txt')
        // console.log(data)
      });
  }

}
