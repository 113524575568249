export function isASCII(str: string)
{
  return /^[\x00-\x7F]*$/.test(str);
}

export function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function ltrim(str:string) {
  /**
   * https://stackoverflow.com/a/24282245/8794234
   * */
  if(!str) return str;
  return str.replace(/^\s+/g, '');
}
