import {Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, FormGroupDirective} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {takeUntil, debounce} from "rxjs/operators";
import {Observable, timer, of, from, Subject, concat, Subscription} from "rxjs";
import _ from 'lodash';

// import {ChangeContext, Options} from '@angular-slider/ngx-slider';
import {ChangeContext, Options, CustomStepDefinition} from 'ngx-slider-v2'

import Columns from "../_global/Columns";
import {PipelineService} from "../services/pipeline-service/pipeline.service";
import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import {SequenceParserService} from "../services/sequence-parser/sequence-parser.service";

import Stage from "../_global/Stage";
import PipelineParams from "../_global/PipelineParams";
import * as pipelineData from "../services/data/cluster-sample.json";
import {
  initClusterTool,
} from "../services/state-services/default-stage-states";
import {isASCII, IsJsonString, ltrim} from "../util/string-helpers";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Location} from "@angular/common";
import Tool from "../_global/Tool";
import {AlertServiceService} from "../services/alert-service/alert-service.service";
import {columnListToObject} from "../util/convert-columns";
import {environment} from "../../environments/environment";
import onCsvDownloadAllClickedNew from "../util/on-csv-download";
import {HttpClient} from "@angular/common/http";
import {isMaxLength} from "../util/is-max-length";

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss']
})
export class ClusterComponent implements OnInit, OnDestroy, OnChanges {

  swaggerUrl = `${environment['api_url']}/apidocs`

  unsubscribe$ = new Subject();
  tools$: Observable<Tool[]> = this._stateService.tools$;
  stages$: Observable<Stage[]> = this._stateService.stages$;
  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  params$: Observable<PipelineParams> = this._stateService.params$;

  active = 1

  pipelineUrl: string = ''

  pipelineId = '';
  tools: Tool[] = [];
  stages: Stage[] = [];
  params!: PipelineParams;

  /** Form variables */
  clusterForm!: UntypedFormGroup;
  @Input() emailForm!: UntypedFormGroup;

  inputChange = {
    /** Keeps track of inputChanges to highlight selected parameter table row*/
    input_sequence_text: false,
    threshold: false,
    lengths: false,
    predictors: false,
    table_state: false,
  }

  @Output() emitIndex = new EventEmitter<number>(); // used for run all
  @Output() emitOnCancel = new EventEmitter<any>();

  @Input() formGroupName!: string
  @Input() index!: number
  // @Input() index!: number
  @Input() resultUrl!: string | undefined;


  @Input() showEmailModal: boolean = false;
  @Output() shoeEmailModalChange = new EventEmitter<boolean>(); // used for run all
  @Output() openEmailModal = new EventEmitter<any>();

  @ViewChild('confirmModal') confirmModal!: ElementRef;
  @ViewChild('alertModal') alertModal!: ElementRef;
  @ViewChild('emailModal') emailModal! : ElementRef;
  // showEmailModal: boolean = false;

  @Input() events: Observable<void> = of();
  private eventsSubscription: Subscription  = new Subscription();

  @Input() runAllEvent: Observable<void> = of();
  private runAllSubscription: Subscription  = new Subscription();

  /** sequence files */
  maxCharacters = 1000000;
  files: any[] = [];
  fileName = '';


  thresholdSliderOptions: Options = {
    floor: 0,
    ceil: 100,
    showTicksValues: true,
    translate: (value) => `${value * 100}%`,
    stepsArray: [
      {value: 0.1},
      {value: 0.2},
      {value: 0.3},
      {value: 0.4},
      {value: 0.5},
      {value: 0.6},
      {value: 0.7},
      {value: 0.8},
      {value: 0.9},
      {value: 1},
    ]
  }

  // minValue: number = 5;
  // maxValue: number = 26;
  peptideLengthOptions: Options = {
    showTicks: true,
    stepsArray: _.map(_.range(4, 27), (num): CustomStepDefinition => {return {value: num, legend: num % 5 === 0 ? `${num}` : undefined}}),
    minRange: 1,
    noSwitching: true,
    translate: (value) => {
      if (value === 4) return 'No Min';
      if (value === 26) return 'No Max';
      else return `${value}`;
    }
  }
  /** sequence variables */
  synthesisDownloadLinks = {
    sequence_list_json_uri: '',
    sequence_list_fasta_uri: '',
    download_sequences: ''
  };

  filteredSequences: { name: string, sequences: string }[] = [];
  numOfAA: number = 0;
  numOfCharacters: number = 0;
  seqFormat: string = 'Unknown';

  pipelineLoading = false;
  /** API Call variables */
  runSetInterval: any;
  submitted = false;
  loading = false;
  getResultsClicked = true;

  tableStateSaving = false;
  saveState = false;

  resultId: number = 0;

  peptide_table_columns: Columns[] | any;
  peptide_table: [(string | number)[]] = [[]]
  peptide_table_order: any[] = [];

  sequence_table_order: any[] = [];
  sequence_table_columns: Columns[] | any;
  sequence_table: [(string | number)[]] = [[]]

  defaultOrder: any[] = [];

  // apiStatus: string;
  errorMessages: string[] = [];
  warningMessages: string[] = [];
  closeResult = '';

  // d3-force variable
  links: any[] = [];
  uniqueClusters: string[] = [];
  constructor(
    public alertService: AlertServiceService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private location: Location,
    private titleService: Title,
    private modalService: NgbModal,
    private _pipelineService: PipelineService,
    private _stateService: PipelineStateService,
    private rootFormGroup: FormGroupDirective, // pipeline component
    private sequenceParserService: SequenceParserService
  ) { }

  ngOnInit(): void {
    this.clusterForm = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup;

    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`pipelineId=${value}`);
      this.pipelineId = value;
      this.pipelineUrl = `${window.location.origin}/pipeline/${value}`;
    })

    this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('tools$');
      console.log(value);
      this.tools = value;
      this.submitted = this.tools[this.index]?.submitted

      const inputSequenceTextId = this.tools[this.index]?.input_data_uri?.input_sequence_text_id

      this.synthesisDownloadLinks.sequence_list_json_uri = `${environment['api_url']}/api/v1/sequence_list_json/${inputSequenceTextId}`
      this.synthesisDownloadLinks.sequence_list_fasta_uri = `${environment['api_url']}/api/v1/sequence_list_fasta/${inputSequenceTextId}`
      this.synthesisDownloadLinks.download_sequences = `${environment['api_url']}/api/v1/download_sequences/${inputSequenceTextId}`

      /** pipeline Loading */
      this.pipelineLoading = _.some(this.tools, {loading: true})

      if(!this.tools[this.index]?.loading) {
        this.loading = false;
        clearInterval(this.runSetInterval)
      }
    })

    this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(value);
      this.stages = value;
      this.errorMessages = this.stages[this.index]?.stage_messages!.errors
      this.warningMessages = this.stages[this.index]?.stage_messages!.warnings
    })

    this.params$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`params`);
      console.log(value);
      this.params = value;
      // if (this.tools[this.index]?.submitted){this.submitted = true}
    })

    this.eventsSubscription = this.events.subscribe(() => this.onRunMain());
    this.runAllSubscription = this.runAllEvent.subscribe(() => this.onRunAll())

    this.onChanges();
    if(this.clusterForm.get('inputSequenceText')?.value) {
      this.sequenceParser(this.clusterForm.get('inputSequenceText')?.value)
    }

    // if(this.loading) {
    //   this.disableInputs()
    // }

    // if (this.resultUrl) {
    //   this.loading = true;
    //   this.getResultsSetInterval(this.resultUrl);
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.resultUrl?.currentValue) {
      console.log(changes.resultUrl.currentValue);
      console.log('ngOnChanges cluster')
      this.loading = true;
      this.getResultsSetInterval(changes.resultUrl.currentValue);
    }
  }

  getResultsSetInterval(result_url: string) {
    /** */
    this.getResultsClicked = true

    this.sequence_table = [[]]
    this.peptide_table = [[]]
    this.uniqueClusters = []


    this.inputChange = _.mapValues(this.inputChange, () => false);
    let increment = 0;
    const milliseconds = 3000;
    this.runSetInterval = setInterval(() => {
      this._pipelineService.getResults(result_url).subscribe(resp => {
        if (resp['status'] === 'error' || resp['errors']) {
          this.errorMessages = resp['data']['errors'];
          this.warningMessages = resp['data']['warnings'];
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTCellPredictionTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTCellPredictionTool, this.index);
          clearInterval(this.runSetInterval);
          this.enableInputs();
        }
        if (resp['data']['warnings']?.length) {
          // console.log(resp['warnings']);
          this.warningMessages = resp['data']['warnings'];
        }
        if (resp['data']['errors']?.length) {
          // console.log(resp['warnings']);
          this.errorMessages = resp['data']['errors'];
        }
        if (resp['status'] === 'done') {
          /** 1) Create Column */
          const data = resp['data']['results'];
          console.log(data);

          this.active = data[0].type

          _.forEach(data, table => {
            if (table.type === 'peptide_table') {
              this.peptide_table_columns = _.map(table.table_columns, (column, i) => {
                if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                  // console.log(column);
                  this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                }
                column['title'] = `${column['display_name']}`; // required
                column['data'] = `${column['name']}`;
                let source = column['source'];

                column['filterKey'] = `${source}.${column.name}`
                // console.log(`source=${source}`);
                let tempSource = source.split('.')
                // console.log(tempSource);
                source = tempSource[tempSource.length - 1];
                // console.log(`source=${source}`);
                column['data'] = `${source}_${column['name']}`;
                /** remove `core` from name */
                // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                // column['name'] = source === 'core' ? display_name: `${source} ${display_name}`;
                // column['filters'] = column['type'] === 'int' ? {
                //   minValue: NaN,
                //   maxValue: NaN,
                //   applied: false
                // } : {
                //   possibleValues: [],
                //   textValue: null,
                //   selectedValues: [],
                //   applied: false
                // }
                column['value_limits'] = column['value_limits'] &&  this.setValueLimits(column)
                return column
              });
              this.peptide_table_order = [...this.defaultOrder];
              if (this.peptide_table_columns) {
                this.peptide_table = table.table_data;
              }
            }
            if (table.type === 'network_graph') {
              // set d3-force inputs
              this.links = data[1].graph_data;
              this.uniqueClusters = [];
              _.forEach(this.links, (link) => {
                if(!this.uniqueClusters.includes(link.cluster[0])) {
                  this.uniqueClusters.push(link.cluster[0]);
                }
              });
            }
            if (table.type === 'input_sequence_table') {
              this.sequence_table_columns = _.map(table.table_columns, (column, i) => {
                // if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                //   this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                // }
                column['title'] = `${column['display_name']}`; // required
                column['data'] = `${column['name']}`;
                let source = column['source'];

                column['filterKey'] = `${source}.${column.name}`

                // console.log(`source=${source}`);
                let tempSource = source.split('.')
                // console.log(tempSource);
                source = tempSource[tempSource.length - 1];
                // console.log(`source=${source}`);
                column['data'] = `${source}_${column['name']}`;
                /** remove `core` from name */
                // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                // column['filters'] = column['type'] === 'int' ? {
                //   minValue: NaN,
                //   maxValue: NaN,
                //   applied: false
                // } : {
                //   possibleValues: [],
                //   textValue: null,
                //   selectedValues: [],
                //   applied: false
                // }
                column['value_limits'] = column['value_limits'] ? this.setValueLimits(column) : undefined
                return column
              });
              this.sequence_table_order = []
              if (this.sequence_table_columns) {

                this.sequence_table = table.table_data
              }
            }
          })

          this.submitted = true; this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTCellPredictionTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTCellPredictionTool, this.index);
          clearInterval(this.runSetInterval);
          console.log(data);
          this.enableInputs();

        }
        if (resp['status'] === 'canceled') {
          /** if status is canceled, then just stop loader **/
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTCellPredictionTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTCellPredictionTool, this.index);
          clearInterval(this.runSetInterval);
          // console.log(data);
          this.enableInputs();
          // set error message
          // this.errorMessages = [`PIPELINE_ID=${this.pipelineId} w/ STATUS=${resp['status']}`]
        }
        else {
          this.getResultsClicked = false;
          // this.apiStatus = resp['status'];
          console.log(`current status: ${resp['status']} - ${increment}`);
          increment += 1;
        }
      }, error => {
        console.error(error);
        this.enableInputs();
        clearInterval(this.runSetInterval);
        this.submitted = true;
        this.loading = false;
      })
    }, milliseconds);
  }

  ngOnDestroy() {
    clearInterval(this.runSetInterval);
    clearInterval(this._pipelineService.runSetInterval)
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.eventsSubscription.unsubscribe();
    this.runAllSubscription.unsubscribe();
  }

  get inputSequenceText(){
    return this.clusterForm.get('inputSequenceText')?.value
  }

  onLengthChange(changeContext: ChangeContext): void {
    this.inputChange.lengths = this.submitted;
  }

  onThresholdChange(changeContext: ChangeContext): void {
    this.inputChange.threshold = this.submitted;
  }

  onChanges(): void {
    /** Immediate - Deletion, */
    // this.clusterForm.get('inputSequenceText').valueChanges.subscribe(val => {
    //   if (val === '') {  // delete
    //     this.filteredSequences = [];
    //     this.numOfAA = 0;
    //     // this.alertService.clear();
    //     // this.myForm.patchValue({
    //     //   inputFormat: 0
    //     // });
    //   }
    // })

    /** PredictionModel sessionStorage */
    this.clusterForm.get('method')?.valueChanges.subscribe(() => {
      this.inputChange.predictors = this.submitted
    });

    const sequenceFieldChange = this.clusterForm.get('inputSequenceText')?.valueChanges.pipe(
      debounce(v => timer(1000)),
    );

    sequenceFieldChange?.subscribe((val: string) => {
      sessionStorage.setItem('clusterSequences', val);
      this.sequenceParser(val);
      this.inputChange.input_sequence_text = this.submitted;
      if (val === '') {  // delete
        this.filteredSequences = [];
        this.numOfAA = 0;
        // this.alertService.clear();
        // this.myForm.patchValue({
        //   inputFormat: 0
        // });
      }
    });
  }

  /** Download Sequence File API calls*/

  onDownloadJson() {
    console.log(this.synthesisDownloadLinks.sequence_list_json_uri);
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_json_uri, this.http, 'json');
  }

  onDownloadFasta() {
    console.log(this.synthesisDownloadLinks.sequence_list_fasta_uri);
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_fasta_uri, this.http, 'fasta');
  }
  onDownloadOriginal() {
    console.log(this.synthesisDownloadLinks.download_sequences);
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.download_sequences, this.http, 'txt');
  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true
    }).result.then((result) => {
      this.saveState = false;
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.saveState = false;
      // this.alleleSearch.nativeElement.focus();
      // this.closeResult = `Dismissed ${TCellPredictionComponent.getDismissReason(reason)}`;
    });
  }

  sequenceParser(val: string) {

    const firstChar = ltrim(val)[0];
    const firstLine = val.split('\n')[0]
    console.log(firstLine)

    /** Named white space */
    let firstLineNumOfElements = 0
    let separator = ' '
    if(firstLine.includes(' ')){
      separator = ' '
    } else if (firstLine.includes('\t')){
      separator = '\t'
    } else if (firstLine.includes('\s')){
      separator = '\s'
    }
    firstLineNumOfElements = firstLine.split(separator).length
    console.log(firstLineNumOfElements)
    console.log(separator)

    const slicedVal = val.slice(0, this.maxCharacters)

    if(val.length > this.maxCharacters) {
      //  Skip parsing
    } else if (firstChar === '>' && this.sequenceParserService.fastaValidate(val)) { // fasta
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if ((firstChar === '{' || firstChar === '[') && IsJsonString(val)) { // json
      this.seqFormat = 'JSON'
      this.numOfCharacters = slicedVal.length
    } else if (firstLineNumOfElements === 2 && this.sequenceParserService.namedWhiteSpaceValidate(val, separator)) {
      // named white space
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val.length > 3 && !val.includes('>') && this.sequenceParserService.unnamedWhiteSpaceValidate(val)) {
      // Unnamed
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val === '') {  // delete
      /**
       * Takes care of it up top - still need this if statement
       * */
      this.seqFormat = 'Unknown';
      this.numOfCharacters = 0
    } else { // error
      this.seqFormat = 'Error';
      this.numOfCharacters = 0
    }
  }

  onDownloadParameter() {
    const data = this._pipelineService.convertClusterForm(this.clusterForm)

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
    // var blob = new Blob([csvData], { type: contentType });
    const blob = new Blob([JSON.stringify(data, null, 2)], ); // thx: http://stackoverflow.com/a/18925211
    a.href = window.URL.createObjectURL(blob);
    a.download = 'parameter.json';

    a.click();
  }

  setValueLimits(column: Columns) : any {
    /* set value_limits (i.e. filters) for each column based on if type is a number/string */
    return column['type'] === 'int' || column['type'] === 'float' ? {
      ...column['value_limits'],
      minValue: NaN,
      maxValue: NaN,
      applied: false
    } : {
      // ...column['value_limits'],
      possibleValues: column['value_limits']['unique_values'],
      textValue: null,
      selectedValues: [],
      applied: false
    }
  }

  postPipelineAPI(params: PipelineParams, getResults: boolean = true) {
    this.disableInputs();
    if (getResults) {
      this.errorMessages = [];
      this.warningMessages = [];
    }

    this.loading = getResults; // true - defaults
    this.submitted = !getResults; // false - defaults
    this.tableStateSaving = !getResults

    this._pipelineService.postPipeline(params).subscribe(
      (resp) => {
        if (resp['errors'] && resp['errors'].length) {
          console.error(resp['errors']);
          this.errorMessages = resp['errors'];
          if (resp['warnings'].length) {
            console.log(resp['warnings']);
            this.warningMessages = resp['warnings'];
          }
          this.loading = false;
          const submittedClusterTool = Object.assign(initClusterTool, {
            submitted: false,
            loading: false,
          })
          this._stateService.insertTool(submittedClusterTool, this.index);
          this.enableInputs();
          return;
        }
        if (resp['warnings'].length) {
          console.log(resp['warnings']);
          this.warningMessages = resp['warnings'];
        }

        if (this.showEmailModal) {
          this.open(this.emailModal);
        }

        const inputDataUri = {input_sequence_text_id: resp.input_sequence_text_id}
        this._stateService.insertInputDataUriToTool(inputDataUri, this.index)

        this._stateService.setPipelineId(resp.pipeline_id);
        this._stateService.setPipelineIdToParams(resp.pipeline_id);
        this._stateService.setPipelineSpecId(resp.pipeline_spec_id);
        this.location.go(`pipeline/${resp.pipeline_id}`);
        // this.titleService.setTitle(`IEDB NG Tools Pipeline`);

        if (getResults) {
          const result_url = `${resp.results_uri}`;
          console.log(result_url);
          this.getResultsSetInterval(result_url);
        } else {
          this.inputChange.table_state = false;
          this.enableInputs();
          this.submitted = true;
          this.loading = false;
          this.tableStateSaving = false;
        }
      },
      error => {
        console.error(error);
        this.errorMessages.push(error.error);
        this.submitted = false;
        this.loading = false;
        const prevTool = this.tools[this.index];
        const submittedTool = Object.assign(prevTool, {
          submitted: this.submitted,
          loading: this.loading,
        })
        this._stateService.insertTool(submittedTool, this.index);
        clearInterval(this.runSetInterval);
        this.enableInputs();
      });
  }

  onRunAll() {
    // this.setDefaultFormValues() // not needed because all defaults are already set in formValues
    this.emitIndex.emit(this.index);
  }

  onRunMain() {
    if(this.clusterForm.invalid){
      this.alertService.add(`Input sequence text is too long. The maximum number of characters allowed is ${this.maxCharacters.toLocaleString()}\nYour sequence contains ${this.inputSequenceText.length.toLocaleString()}`, 'alert-danger');
      this.open(this.alertModal)
      return;
    }

    const hasResult = _.find(this.tools.slice(this.index+1), (o) => o.submitted || o.loading)
    if(this.stages.length > 1 && hasResult) {
      this.open(this.confirmModal);
    } else {
      this.getResultsClicked = true;
      if(this.index===0) {
        this.onRun();
      } else {
        this.onRun2()
      }
    }
  }

  onRunConfirm() {
    let indicies = _.range(this.index + 1, this.stages.length)
    let initTool: Tool;
    console.log(indicies);
    _.forEach(indicies, i => {
      initTool = this.tools[i];
      const unSubmittedTool = {
        ...initTool,
        loading: false,
        submitted: false,
      }
      this._stateService.insertTool(unSubmittedTool, i);
    })
    this.saveState ? this.onSaveTableState() : this.onRun();
  }

  setDefaultFormValues() {
    /** defaults - use Sars placeholders*/
    const {input_sequence_text } = pipelineData;
    /** Sequences */
    let sequences = this.clusterForm.value.inputSequenceText
    if(!sequences && this.index===0) {
      this._stateService.setInputSequenceText(input_sequence_text);
      this.clusterForm.controls.inputSequenceText.setValue(input_sequence_text);
    }
  }

  onRun() {
    /** Reset global datatables search ext */
    $.fn.dataTable.ext.search = [];
    const stage_number = this.index + 1;
    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    this.setDefaultFormValues()
    /** Sequences */
    let sequences = this.clusterForm.value.inputSequenceText

    /** input_parameters */
    const input = this._pipelineService.convertClusterForm(this.clusterForm)

    /** Table State */
    const { table_state } = this.stages[this.index];

    const params = {
      "pipeline_id": '',
      "pipeline_title": this.emailForm.controls['name'].value,
      "email": this.emailForm.controls['email'].value,
      "run_stage_range": run_stage_range,
      "stages": [
        {
          "stage_display_name": "Cluster",
          "stage_number": stage_number,
          "stage_type": "prediction",
          "tool_group": "cluster",
          "input_sequence_text": sequences,
          // "input_stage_number": 1,
          "input_parameters": input,
          "table_state": table_state
        }
      ]
    }
    /** set params */
    this._stateService.setParams(params);
    const loadingClusterTool = Object.assign(initClusterTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })
    console.log(loadingClusterTool)
    console.log(`this.index=${this.index}`);
    this._stateService.insertTool(loadingClusterTool, this.index);
    // this._stateService.insertTableStateToStage({columns: {}}, this.index);
    this.loading = true;
    this.postPipelineAPI(params);
  }

  onRun2() {

    const params = this.params;
    const stage_number = this.index + 1;
    const input_stage_number = this.index;
    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    // this.setDefaultFormValues() // no need because no sequences
    /** input_parameters */
    const input = this._pipelineService.convertClusterForm(this.clusterForm)

    const initialStage = this.stages[this.index];

    /** Table State */
    const { table_state } = this.stages[this.index];

    const stage = {
      ...initialStage,
      result_url: undefined,
      "input_stage_number": input_stage_number,
      "input_parameters": input,
      table_state
    }

    params.stages[this.index] = stage;
    params.run_stage_range = run_stage_range;
    params.pipeline_title = this.emailForm.controls['name'].value;
    params.email = this.emailForm.controls['email'].value;

    console.log(params)

    /** set params */
    this._stateService.setParams(params);
    const loadingClusterTool = Object.assign(initClusterTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })
    console.log(loadingClusterTool)
    console.log(`onRun2() - this.index=${this.index}`);
    this._stateService.insertTool(loadingClusterTool, this.index);
    this.loading = true;
    this.postPipelineAPI(params);
  }

  onCancel() {
    console.log(this.runSetInterval)
    clearInterval(this.runSetInterval);
    this.emitOnCancel.emit()

    this.inputChange.table_state = false;
    // this.submitted = false;
    const currentTool = this.tools[this.index];
    const unSubmittedTool = {
      ...currentTool,
      loading: false,
      submitted: false,
      appliedFilters: undefined
    }
    this._stateService.insertTool(unSubmittedTool, this.index);
    this.warningMessages = [];
    this.loading = false;
    this.tableStateSaving = false;
    this.enableInputs();
  }

  onFilterApplied() {
    this.inputChange.table_state = this.submitted;
  }

  onClearTableState() {
    this.peptide_table_columns = _.map(this.peptide_table_columns, (c) => {
      c.filters = ['int', 'float'].includes(c.type) ? {
          minValue: NaN,
          maxValue: NaN,
          applied: false
        } :
        {
          possibleValues: [],
          textValue: null,
          selectedValues: [],
          applied: false
        }
      return c
    })
    this.inputChange.table_state = this.submitted;
  }

  onShowEmailModalChange() {
    this.showEmailModal = !this.showEmailModal
    this.shoeEmailModalChange.emit(this.showEmailModal)
  }

  onSaveTableStateMain() {
    const hasResult = _.find(this.tools.slice(this.index+1), {submitted: true})
    if (hasResult) {
      this.saveState = true;
      this.open(this.confirmModal);
    } else {
      this.onSaveTableState();
    }
  }

  onSaveTableState() {
    const tool_group = 'cluster'

    const params = this.params
    // create colObj and params
    let colObj = columnListToObject(this.peptide_table_columns, tool_group)
    const stages = params.stages;
    const stageTemp = stages[this.index];

    // stageTemp.table_state.columns = colObj;
    stageTemp.table_state = [{table: 'peptide_table', columns: colObj}];

    stages[this.index] = stageTemp
    params.stages = stages;
    // run_stage_range
    const stage_number = this.index + 1;
    params.run_stage_range = [stage_number, stage_number];
    this._stateService.setParams(params);
    // appliedFilters
    let appliedFilters = !_.isEmpty(colObj);
    this._stateService.setToolAppliedFilters(appliedFilters, this.index)
    this.postPipelineAPI(params, false);
    this.saveState = false;
  }

  onFileDropped($event: any[]) {
    console.log($event);
    const file = $event[0];
    const fileSize = file.size / 1024; // in kB
    console.log(file.type);
    if (fileSize > 20000) {
      this.alertService.add('File Size is too big! (20mB max)', 'alert-danger');
      this.open(this.alertModal);
    }
    else {
      this.prepareSequenceFileList($event);
    }
  }

  prepareSequenceFileList(files: Array<any>) {
    console.log(files);
    this.fileName = files[0].name;
    const reader = new FileReader();
    const myForm = this.clusterForm;
    for (const item of files) {
      // console.log(item);
      reader.readAsText(item);
      reader.onload = () => {
        const val = reader.result?.toString() || '';
        console.log(val);
        const v = isASCII(val);
        console.log(v);
        if (v) {
          // myForm.patchValue({inputSequenceText: val.substring(0, this.maxCharacters)});
          const w = isMaxLength(val, this.maxCharacters)
          if (w) {
            myForm.patchValue({inputSequenceText: val});
          } else {
            this.alertService.add(`Sequence is too long - max Sequence length is ${this.maxCharacters.toLocaleString("en-US")} characters`, 'alert-danger');
            this.open(this.alertModal);
          }
        } else {
          this.alertService.add('Invalid file type.\nValid file types are .txt, .fa, .fasta, .json, .faa,', 'alert-danger');
          this.open(this.alertModal);
        }
      };

      reader.onerror = () => {
        console.log(reader.error);
      };
      item.progress = 0;
      this.files.push(item);
    }
  }

  sendNotification() {
  //    TODO - used in email modal
  }

  onReset() {
    this.alertService.clear();
    this.errorMessages = [];
    this.warningMessages = [];

    const oldSequence = this.stages[this.index].input_sequence_text
    const input_parameters = this.params.stages[this.index].input_parameters

    // sequences
    this.filteredSequences = [];
    this.numOfAA = 0;
    // order
    this.peptide_table_order = [];
    this.defaultOrder = [];
    // clusterForm values

    this.clusterForm.patchValue({
      inputSequenceText: '',
      peptideLength: [4, 26],
      threshold: 0.7,
      method: 'cluster-break'
    }, {emitEvent: false});

    this.enableInputs()

    const method = input_parameters.predictors[0].method

    if(this.submitted){
      this.inputChange =  {
        ...this.inputChange,
        /** Keeps track of inputChanges to highlight selected parameter table row*/
        input_sequence_text: oldSequence !== '',
        threshold: input_parameters.cluster_pct_identity !== 0.7,
        lengths: !_.isEqual([0, 0], input_parameters.peptide_length_range),
        predictors: method !== 'cluster-break',
      }
    }

    // this.errorMessages = [];
    // this.warningMessages = [];
    // this.loading = false;
    // this.submitted = false;
    // this.saveState = false;
    // this.tableStateSaving = false;
  }

  get styleResultContainer(){
    return !this.loading && this.submitted ? 'none' : 'dotted';
  }

  /** File Download/Upload*/
  uploadSequenceFile($event: any) {
    $event.preventDefault();
    /**
     * Used for 'Upload' button
     * */
    const {files} = $event.target;
    files && this.onFileDropped(files);
  }

  uploadParameterFile($event: any, input: any) {
    console.log($event);
    $event.preventDefault();
    // /**
    //  * Used for 'Upload' button to upload parameters
    //  * */
    // const {files} = $event.target;
    // files && this.onParameterFileDropped(files, input);
  }

  /** changes peptideLengthSliderOptions */

  enableInputs() {
    // this.clusterForm.enable({emitEvent: false});
    this.clusterForm.controls['method'].enable({emitEvent: false})
    this.thresholdSliderOptions = Object.assign({}, this.thresholdSliderOptions, {disabled: false});
    this.peptideLengthOptions = Object.assign({}, this.peptideLengthOptions, {disabled: false})
  }

  disableInputs() {
    // this.clusterForm.disable({emitEvent: false});
    this.clusterForm.controls['method'].disable({emitEvent: false})
    this.thresholdSliderOptions = Object.assign({}, this.thresholdSliderOptions, {disabled: true});
    this.peptideLengthOptions = Object.assign({}, this.peptideLengthOptions, {disabled: true})
  }

  onErrorMessageDeselect(message: string, index: number) {
    if (index > -1) {
      this.errorMessages.splice(index, 1);
    }
    this.errorMessages = _.filter(this.errorMessages, (em) => em !== message);
  }

  onWarningMessageDeselect(message: string, index: number) {
    if (index > -1) {
      this.warningMessages.splice(index, 1);
    }
    this.warningMessages = _.filter(this.warningMessages, (wm) => wm !== message);
  }

}
