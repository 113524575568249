import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges, SimpleChanges
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, UntypedFormGroup, FormControl, FormArray, FormGroupDirective} from "@angular/forms";
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';

import {Observable, timer, of, from, Subject, Subscription} from "rxjs";
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Options, ChangeContext} from '@angular-slider/ngx-slider';

import Columns from "../_global/Columns";
import {PipelineService} from "../services/pipeline-service/pipeline.service";
import {PipelineStateService} from "../services/state-services/pipeline-state-service/pipeline-state.service";
import {SequenceParserService} from "../services/sequence-parser/sequence-parser.service";

import Stage from "../_global/Stage";
import PipelineParams from "../_global/PipelineParams";
import * as pipelineData from "../services/data/cluster-sample.json";
import * as pepmatchData from "../services/data/pepmatch-mismatch-sample.json";
import {
  initClusterTool, initParams, initClusterState, initTCellPredictionTool, initPepmatchTool
} from "../services/state-services/default-stage-states";
import {isASCII, IsJsonString, ltrim} from "../util/string-helpers";
import Tool from "../_global/Tool";
import {AlertServiceService} from "../services/alert-service/alert-service.service";
import {columnListToObject} from "../util/convert-columns";
import {AlleleSearchService} from "../services/allele-search/allele-search.service";
import * as _ from "lodash";
import {environment} from "../../environments/environment";
import {debounce, takeUntil} from "rxjs/operators";
import onCsvDownloadAllClickedNew from '../util/on-csv-download';
import {isMaxLength} from "../util/is-max-length";

const API_URL = `${environment['api_url']}/api/v1/pepmatch`;
const EMAIL_URL = `${environment['api_url']}/api/v1/request`;

@Component({
  selector: 'app-pepmatch',
  templateUrl: './pepmatch.component.html',
  styleUrls: ['./pepmatch.component.scss'],
  styles: [
    `
			.accordion-button::after {
				content: none;
			}
		`,
  ]
})
export class PepmatchComponent implements OnInit, OnDestroy, OnChanges  {

  sequencePlaceHolder = `Type/paste/drag a set of peptides (max: 1,000) into this box or click 'Run' to use the example sequence:
DDEDSKQNIFHFLYR
ADPGPHLMGGGGRAK
KAVELGVKLLHAFHT
QLQNLGINPANIGLS
HEVWFFGLQYVDSKG
`

  /** Pipeline form */
  @Input() formGroupName!: string
  @Input() index!: number;
  @Input() resultUrl!: string | undefined;
  @Input() showEmailModal: boolean = false;

  @Input() emailForm!: UntypedFormGroup;
  @Output() openEmailModal = new EventEmitter<any>();


  @Output() emitIndex = new EventEmitter<number>(); // used for run all
  @Output() emitOnCancel = new EventEmitter<any>();

  @ViewChild('alertModal') alertModal!: ElementRef;
  @ViewChild('confirmModal') confirmModal!: ElementRef;

  @Input() events: Observable<void> = of();
  private eventsSubscription: Subscription  = new Subscription();

  @Input() runAllEvent: Observable<void> = of();
  private runAllSubscription: Subscription  = new Subscription();

  unsubscribe$ = new Subject();

  stages$: Observable<Stage[]> = this._stateService.stages$;
  tools$: Observable<Tool[]> = this._stateService.tools$;
  pipelineId$: Observable<string> = this._stateService.pipelineId$;
  params$: Observable<PipelineParams> = this._stateService.params$;

  stages: Stage[] = [];
  tools: Tool[] = [];
  pipelineId = '';
  params!: PipelineParams;
  pipelineUrl: string = ''

  swaggerUrl = `${environment['api_url']}/apidocs`

  /** sequence variables */
  synthesisDownloadLinks = {
    sequence_list_json_uri: '',
    sequence_list_fasta_uri: '',
    download_sequences: ''
  };

  /** Form variables */
  pepmatchForm!: UntypedFormGroup;

  filteredSequences: { name: string, sequences: string }[] = [];
  numOfAA: number = 0;
  numOfCharacters = 0
  seqFormat: string = 'Unknown';

  inputChange = {
    /** Keeps track of inputChanges to highlight selected parameter table row*/
    input_sequence_text: false,
    proteome: false,
    mismatch: false,
    bestMatch: false,
    table_state: false,
  }

  options: Options = {
    floor: 0,
    ceil: 5,
    step: 1,
    showTicks: true,
    showTicksValues: true
  };

  predictionOptions: {display_name: string, short_name: string, group: string, source: string, has_ic50: boolean}[] = [];
  proteomeOptions: string[] = [];

  pepmatchLoading: boolean = false;

  /** API Call variables */
  runSetInterval: any;
  submitted = false;
  loading = false;
  getResultsClicked = true;

  pipelineLoading = false;
  tableStateSaving = false;
  saveState = false;

  errorMessages: string[] = [];
  warningMessages: string[] = [];

  columns: Columns[] | any;
  peptide_table: [(string | number)[]] = [[]]
  currentOrder: any[] = [];
  defaultOrder: any[] = [];

  sequence_table_order: any[] = [];
  sequence_table_columns: Columns[] | any;
  sequence_table: [(string | number)[]] = [[]]

  /** sequence files */
  maxCharacters = 1000000;
  files: any[] = [];
  fileName = '';

  active = 'peptide_table'

  constructor(
    public alertService: AlertServiceService,
    private http: HttpClient,
    // private fb: FormBuilder,
    private location: Location,
    private titleService: Title,
    private _alleleSearchService: AlleleSearchService,
    private _pipelineService: PipelineService,
    private _stateService: PipelineStateService,
    private rootFormGroup: FormGroupDirective, // pipeline component
    private sequenceParserService: SequenceParserService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getPepmatch()
    this.pepmatchForm = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup;

    this.pipelineId$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.pipelineId = value;
      this.pipelineUrl = `${window.location.origin}/pipeline/${value}`;
    })
    this.stages$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.stages = value;
      this.errorMessages = this.stages[this.index]?.stage_messages!.errors
      this.warningMessages = this.stages[this.index]?.stage_messages!.warnings
    })
    this.tools$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log('tools$');
      console.log(value);
      this.tools = value;
      this.submitted = this.tools[this.index]?.submitted

      // inputSequenceTextURIs
      const inputSequenceTextId = this.tools[this.index]?.input_data_uri?.input_sequence_text_id

      this.synthesisDownloadLinks.sequence_list_json_uri = `${environment['api_url']}/api/v1/sequence_list_json/${inputSequenceTextId}`
      this.synthesisDownloadLinks.sequence_list_fasta_uri = `${environment['api_url']}/api/v1/sequence_list_fasta/${inputSequenceTextId}`
      this.synthesisDownloadLinks.download_sequences = `${environment['api_url']}/api/v1/download_sequences/${inputSequenceTextId}`
      /** pipeline Loading */
      this.pipelineLoading = _.some(this.tools, {loading: true})

      /** cancel run all  */
      if(!this.tools[this.index]?.loading) {
        this.loading = false;
        clearInterval(this.runSetInterval)
      }
      // this.loading = this.tools[this.index]?.loading
      // if (this.tools[this.index]?.submitted){this.submitted = true}
      // if (this.tools[this.index].appliedFilters){this.submitted = true}
      // this.componentDisabled = value[this.index].disabled;
    })

    this.params$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      console.log(`params`);
      console.log(value);
      this.params = value;
    })

    this.eventsSubscription = this.events.subscribe(() => this.onRunMain());
    this.runAllSubscription = this.runAllEvent.subscribe(() => this.onRunAll())

    this.onChanges()

    if(this.pepmatchForm.get('inputSequenceText')?.value) {
      this.sequenceParser(this.pepmatchForm.get('inputSequenceText')?.value)
    }
    // if(this.loading) {
    //   this.disableInputs()
    // }

    // if(this.resultUrl) {
    //   this.loading = true;
    //   this.getResultsSetInterval(this.resultUrl);
    // }

    // filteredSequences
    // numOfAA
  }

  ngOnDestroy() {
    clearInterval(this.runSetInterval);
    clearInterval(this._pipelineService.runSetInterval)
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.eventsSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resultUrl?.currentValue) {
      console.log(changes.resultUrl.currentValue);
      console.log('ngOnChanges pepmatch')
      this.loading = true;
      this.getResultsSetInterval(changes.resultUrl.currentValue);
    }
  }

  get styleResultContainer(){
    return !this.loading && this.submitted ? 'none' : 'dotted';
  }

  get inputSequenceText(){
    return this.pepmatchForm.get('inputSequenceText')?.value
  }

  /** Download Sequence File API calls*/

  onDownloadJson() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_json_uri, this.http, 'json');
  }

  onDownloadFasta() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.sequence_list_fasta_uri, this.http, 'fasta');
  }
  onDownloadOriginal() {
    onCsvDownloadAllClickedNew(this.synthesisDownloadLinks.download_sequences, this.http, 'txt');
  }


  /** File Download/Upload*/
  uploadSequenceFile($event: any) {
    $event.preventDefault();
    /**
     * Used for 'Upload' button
     * */
    const {files} = $event.target;
    files && this.onFileDropped(files);
  }

  uploadParameterFile($event: any, input: any) {
    console.log($event);
    $event.preventDefault();
    /**
     * Used for 'Upload' button to upload parameters
     * */
    // const {files} = $event.target;
    // files && this.onParameterFileDropped(files, input);
  }

  onDownloadParameter() {
    const data = this._pipelineService.convertPepmatchForm(this.pepmatchForm)

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-16';
    // var blob = new Blob([csvData], { type: contentType });
    const blob = new Blob([JSON.stringify(data, null, 2)], ); // thx: http://stackoverflow.com/a/18925211
    a.href = window.URL.createObjectURL(blob);
    a.download = 'parameter.json';

    a.click();
  }

  getPepmatch() {
    this.pepmatchLoading = true;
    this.http.get<any[]>(API_URL)
      .pipe().subscribe(
      resp => {
        // @ts-ignore
        // this.mhciPredictors = resp['predictors'];
        this.proteomeOptions = resp.optional_parameters[0].choice
        // console.log(resp.optional_parameters[0]);

        console.log(this.proteomeOptions)
        this.pepmatchLoading = false;
      }
    )
  }

  onChanges(): void {
    /** PredictionModel sessionStorage */
    this.pepmatchForm.get('proteome')?.valueChanges.subscribe(() => {
      this.inputChange.proteome = this.submitted
    });

    this.pepmatchForm.get('bestMatch')?.valueChanges.subscribe(() => {
      this.inputChange.bestMatch = this.submitted
    });

    const sequenceFieldChange = this.pepmatchForm.get('inputSequenceText')?.valueChanges.pipe(
      debounce(v => timer(1000)),
    );

    sequenceFieldChange?.subscribe(val => {
      // sessionStorage.setItem('sequences', val);
      this.sequenceParser(val);
      this.inputChange.input_sequence_text = this.submitted;
      if (val === '') {  // delete
        this.filteredSequences = [];
        this.numOfAA = 0;
      }
    });
  }

  onRunAll() {
    this.setDefaultFormValues()
    this.errorMessages = [];
    this.warningMessages = [];
    this.emitIndex.emit(this.index);
  }

  onRunMain() {
    if(this.pepmatchForm.invalid){
      this.alertService.add(`Input sequence text is too long. The maximum number of characters allowed is ${this.maxCharacters.toLocaleString()}\nYour sequence contains ${this.inputSequenceText.length.toLocaleString()}`, 'alert-danger');
      this.open(this.alertModal)
      return;
    }

    const hasResult = _.find(this.tools.slice(this.index+1), (o) => o.submitted || o.loading)
    if(this.stages.length > 1 && hasResult) {
      this.open(this.confirmModal);
    } else {
      this.getResultsClicked = true;
      if(this.index===0) {
        this.onRun();
      } else {
        this.onRun2()
      }
    }
  }

  setDefaultFormValues() {
    /** defaults - use Sars placeholders*/
    const {input_sequence_text} = pepmatchData;
    /** Sequences */
    let sequences = this.pepmatchForm.value.inputSequenceText
    if(!sequences && this.index===0) {
      this._stateService.setInputSequenceText(input_sequence_text);
      this.pepmatchForm.controls.inputSequenceText.setValue(input_sequence_text);
    }
  }

  onRunConfirm() {
    let indicies = _.range(this.index + 1, this.stages.length)
    let initTool: Tool;
    console.log(indicies);
    _.forEach(indicies, i => {
      initTool = this.tools[i];
      const unSubmittedTool = {
        ...initTool,
        loading: false,
        submitted: false,
      }
      this._stateService.insertTool(unSubmittedTool, i);
    })
    this.saveState ? this.onSaveTableState() : this.onRun();
  }

  onRun() {
    /**
     Update pipeline State
     *
     * */
    /** Reset global datatables search ext */
    $.fn.dataTable.ext.search = [];

    const stage_number = this.index + 1;
    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    this.setDefaultFormValues()
    /** Sequences */
    let sequences = this.pepmatchForm.value.inputSequenceText

    /** input parameters */
    const inputParameters = this._pipelineService.convertPepmatchForm(this.pepmatchForm)

    /** Table State */

    const { table_state } = this.stages[this.index];

    const newStage = {
      "stage_display_name": "Pepmatch",
      "stage_number": stage_number,
      "stage_type": "prediction",
      "tool_group": "pepmatch",
      "input_sequence_text": sequences,
      "input_parameters": inputParameters,
      "table_state": table_state
    }

    const params = {
      "pipeline_id": '',
      "pipeline_title": this.emailForm.controls['name'].value,
      "email": this.emailForm.controls['email'].value,
      "run_stage_range": run_stage_range,
      "stages": [
        newStage
      ]
    }

    /** set params */
    const initTool = Object.assign(initPepmatchTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })
    this._stateService.setParams(params);
    this._stateService.insertTool(initTool, this.index);
    // this._stateService.insertTableStateToStage({columns: {}}, this.index);

    this.postPipelineAPI(params);
  }

  onRun2() {

    const params = this.params;
    const stage_number = this.index + 1;
    const input_stage_number = this.index; // previous index

    /** run_stage_range */
    const run_stage_range: [number, number] = [stage_number, stage_number]
    /** default formValue */
    this.setDefaultFormValues()

    /** input parameters */
    const inputParameters = this._pipelineService.convertPepmatchForm(this.pepmatchForm)

    const initialStage = this.stages[this.index];
    console.log('initialStage');
    console.log(initialStage);

    /** Table State */
    const { table_state } = this.stages[this.index];

    const stage: Stage = {
      ...initialStage,
      result_url: undefined,
      stage_number: stage_number,
      input_stage_number: input_stage_number,
      input_parameters: inputParameters,
      table_state
    }

    // this.params.stages.push(stage);
    params.stages[this.index] = stage;
    params.run_stage_range = run_stage_range;
    params.pipeline_title = this.emailForm.controls['name'].value;
    params.email = this.emailForm.controls['email'].value;

    console.log(params)

    /** set params */
    this._stateService.setParams(params);
    const loadingTCellPredictionTool = Object.assign(initPepmatchTool, {
      loading: true,
      submitted: false,
      appliedFilters: !_.isEmpty(table_state?.columns)
    })
    console.log('loadingTCellPredictionTool')
    console.log(loadingTCellPredictionTool)
    this._stateService.insertTool(loadingTCellPredictionTool, this.index);

    this.postPipelineAPI(params);
  }

  setValueLimits(column: Columns) : any {
    /* set value_limits (i.e. filters) for each column based on if type is a number/string */
    return column['type'] === 'int' || column['type'] === 'float' ? {
      ...column['value_limits'],
      minValue: NaN,
      maxValue: NaN,
      applied: false
    } : {
      // ...column['value_limits'],
      possibleValues: column['value_limits']['unique_values'],
      textValue: null,
      selectedValues: [],
      applied: false
    }
  }

  postPipelineAPI(params: PipelineParams, getResults: boolean = true) {
    /**
     * params
     * getResults: boolean
     *  false - used in 'Save Table State'
     * */
    /** initial set up */
    this.disableInputs();
    this.loading = getResults; // true - defaults
    this.submitted = !getResults; // false - defaults
    this.tableStateSaving = !getResults
    if (getResults) {
      this.errorMessages = [];
      this.warningMessages = [];
    }
    this._pipelineService.postPipeline(params).subscribe(
      (resp) => {
      // Errors and Warnings
      if (resp['errors'] && resp['errors'].length) {
        console.log(resp);
        console.error(resp['errors']);
        this.errorMessages = resp['errors'];
        if (resp['warnings'].length) {
          console.log(resp['warnings']);
          this.warningMessages = resp['warnings'];
        }
        this.loading = false;
        this.submitted = true;
        const submittedTool = Object.assign(initPepmatchTool, {
          submitted: false,
          loading: false,
        })
        this._stateService.insertTool(submittedTool, this.index);
        this.enableInputs();
        return;
      }
      // Warnings
      if (resp['warnings'].length) {
        console.log(resp['warnings']);
        this.warningMessages = resp['warnings'];
      }

      console.log(resp.pipeline_id);

      const inputDataUri = {input_sequence_text_id: resp.input_sequence_text_id}
      this._stateService.insertInputDataUriToTool(inputDataUri, this.index)

      this._stateService.setPipelineId(resp.pipeline_id);
      this._stateService.setPipelineIdToParams(resp.pipeline_id);
      this._stateService.setPipelineSpecId(resp.pipeline_spec_id);
      /** Display email Modal if emailModalShow=true */
      if (this.showEmailModal) {
        // this.open(this.emailModal);
      }
      this.location.replaceState(`pipeline/${resp.pipeline_id}`)
      // this.titleService.setTitle(`IEDB NG Tools Pipeline`);

      if (getResults) {
        const result_url = `${resp.results_uri}`;
        console.log(result_url);
        this.getResultsSetInterval(result_url);
      } else {
        this.inputChange.table_state = false;
        this.submitted = true;
        this.loading = false;
        this.tableStateSaving = false;
        this.enableInputs();
      }
    },
      error => {
        console.error(error);
        this.errorMessages.push(error.error);
        this.submitted = false;
        this.loading = false;
        const prevTool = this.tools[this.index];
        const submittedTool = Object.assign(prevTool, {
          submitted: this.submitted,
          loading: this.loading,
        })
        this._stateService.insertTool(submittedTool, this.index);
        clearInterval(this.runSetInterval);
        this.enableInputs();
      })
  }

  getResultsSetInterval(result_url: string) {
    this.getResultsClicked = true

    this.sequence_table = [[]]
    this.peptide_table = [[]]

    this.inputChange = _.mapValues(this.inputChange, () => false);
    let increment = 0;
    const milliseconds = 3000;
    this.runSetInterval = setInterval(() => {
      this._pipelineService.getResults(result_url).subscribe(resp => {
        if (resp['status'] === 'error' || resp['errors']) {
          this.errorMessages = resp['data']['errors'];
          this.warningMessages = resp['data']['warnings'];
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTool, this.index);
          clearInterval(this.runSetInterval);
          this.enableInputs();
        }
        if (resp['data']['warnings']?.length) {
          // console.log(resp['warnings']);
          this.warningMessages = resp['data']['warnings'];
        }
        if (resp['data']['errors']?.length) {
          // console.log(resp['warnings']);
          this.errorMessages = resp['data']['errors'];
        }
        if (resp['status'] === 'done') {
          /** 1) Create Column */
          const data = resp['data']['results'];
          console.log(data);

          this.active = data[0].type

          // this.active = data[0].table_data.length ? data[0].type : data[1].type

          _.forEach(data, table => {
            if (table.type === 'peptide_table') {
              this.columns = _.map(table.table_columns, (column, i) => {
                if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                  // console.log(column);
                  this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                }
                column['title'] = `${column['display_name']}`; // required
                column['data'] = `${column['name']}`;
                let source = column['source'];

                column['filterKey'] = `${source}.${column.name}`
                // console.log(`source=${source}`);
                let tempSource = source.split('.')
                // console.log(tempSource);
                source = tempSource[tempSource.length - 1];
                // console.log(`source=${source}`);
                column['data'] = `${source}_${column['name']}`;
                /** remove `core` from name */
                // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                // column['name'] = source === 'core' ? display_name: `${source} ${display_name}`;
                // column['filters'] = column['type'] === 'int' ? {
                //   minValue: NaN,
                //   maxValue: NaN,
                //   applied: false
                // } : {
                //   possibleValues: [],
                //   textValue: null,
                //   selectedValues: [],
                //   applied: false
                // }
                column['value_limits'] = column['value_limits'] &&  this.setValueLimits(column)
                return column
              });
              this.currentOrder = [...this.defaultOrder];
              if (this.columns) {
                this.peptide_table = table.table_data;
                const prevTool = this.tools[this.index];
                console.log(prevTool)
                const submittedClusterTool = Object.assign(prevTool, {
                  submitted: true,
                  loading: false,
                })

                this._stateService.insertTool(submittedClusterTool, this.index);

                clearInterval(this.runSetInterval);
                this.enableInputs();

                this.submitted = true;
                this.loading = false;
              }
            }
            if (table.type === 'input_sequence_table') {
              this.sequence_table_columns = _.map(table.table_columns, (column, i) => {
                // if (column.default_order && Number.isFinite(column.row_sort_priority)) { // set defaultOrder
                //   this.defaultOrder[column.row_sort_priority] = [i, column.default_order === "ascending" ? 'asc' : 'desc'];
                // }
                column['title'] = `${column['display_name']}`; // required
                column['data'] = `${column['name']}`;
                let source = column['source'];

                column['filterKey'] = `${source}.${column.name}`

                // console.log(`source=${source}`);
                let tempSource = source.split('.')
                // console.log(tempSource);
                source = tempSource[tempSource.length - 1];
                // console.log(`source=${source}`);
                column['data'] = `${source}_${column['name']}`;
                /** remove `core` from name */
                // column['name'] = column['display_name'] ? column['display_name'] : column['name']
                // column['filters'] = column['type'] === 'int' ? {
                //   minValue: NaN,
                //   maxValue: NaN,
                //   applied: false
                // } : {
                //   possibleValues: [],
                //   textValue: null,
                //   selectedValues: [],
                //   applied: false
                // }
                column['value_limits'] = column['value_limits'] ? this.setValueLimits(column) : undefined
                return column
              });
              this.sequence_table_order = []
              if (this.sequence_table_columns) {

                this.sequence_table = table.table_data
              }
            }
          })
        }
        if (resp['status'] === 'canceled') {
          /** if status is canceled, then just stop loader **/
          this.submitted = false;
          this.loading = false;
          const prevTool = this.tools[this.index];
          console.log(prevTool)
          const submittedTCellPredictionTool = Object.assign(prevTool, {
            submitted: this.submitted,
            loading: this.loading,
          })
          this._stateService.insertTool(submittedTCellPredictionTool, this.index);
          clearInterval(this.runSetInterval);
          // console.log(data);
          this.enableInputs();
          // set error message
          // this.errorMessages = [`PIPELINE_ID=${this.pipelineId} w/ STATUS=${resp['status']}`]
        }
        else {
          this.getResultsClicked = false;
          // this.apiStatus = resp['status'];
          console.log(`current status: ${resp['status']} - ${increment}`);
          increment += 1;
        }
      }, error => {
        console.error(error);
        this.enableInputs();
        clearInterval(this.runSetInterval);
        this.submitted = true;
        this.loading = false;
      })
    }, milliseconds);
  }

  onFileDropped($event: any[]) {
    console.log($event);
    const file = $event[0];
    const fileSize = file.size / 1024; // in kB
    console.log(file.type);
    if (fileSize > 20000) {
      this.alertService.add('File Size is too big! (20mB max)', 'alert-danger');
      this.open(this.alertModal);
    }
    else {
      this.prepareSequenceFileList($event);
    }
  }

  prepareSequenceFileList(files: Array<any>) {
    console.log(files);
    this.fileName = files[0].name;
    const reader = new FileReader();
    const myForm = this.pepmatchForm;
    for (const item of files) {
      // console.log(item);
      reader.readAsText(item);
      reader.onload = () => {
        const val = reader.result?.toString() || '';
        console.log(val);
        const v = isASCII(val);
        console.log(v);
        if (v) {
          // myForm.patchValue({inputSequenceText: val.substring(0, this.maxCharacters)});
          const w = isMaxLength(val, this.maxCharacters)
          if (w) {
            myForm.patchValue({inputSequenceText: val});
          } else {
            this.alertService.add(`Sequence is too long - max Sequence length is ${this.maxCharacters.toLocaleString("en-US")} characters`, 'alert-danger');
            this.open(this.alertModal);
          }
          // this.sequenceParser(val);  // auto parser
        } else {
          this.alertService.add('Invalid file type.\nValid file types are .txt, .fa, .fasta, .json, .faa,', 'alert-danger');
          this.open(this.alertModal);
        }
      };

      reader.onerror = () => {
        console.log(reader.error);
      };
      item.progress = 0;
      this.files.push(item);
    }
  }

  // Allele Modal Functions

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      animation: true,
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
      this.saveState = false;
    }, (reason) => {
      this.saveState = false;
      // this.alleleSearch.nativeElement.focus();
      // this.closeResult = `Dismissed ${TCellPredictionComponent.getDismissReason(reason)}`;
    });
  }


  onCancel() {
    console.log(this.runSetInterval)
    clearInterval(this.runSetInterval);
    this.emitOnCancel.emit()

    // this.inputChange.table_state = false;
    // // this.submitted = false;
    const currentTool = this.tools[this.index]
    const unSubmittedTool = {
      ...currentTool,
      loading: false,
      submitted: false,
      appliedFilters: undefined
    }
    this._stateService.insertTool(unSubmittedTool, this.index);
    this.warningMessages = [];
    this.loading = false;
    this.tableStateSaving = false;
    this.enableInputs();
  }

  onReset() {
    this.alertService.clear();
    this.errorMessages = [];
    this.warningMessages = [];
  }

  enableInputs() {
    // this.pepmatchForm.enable({emitEvent: false});
    this.pepmatchForm.controls['bestMatch'].enable({emitEvent: false})
    this.pepmatchForm.controls['proteome'].enable({emitEvent: false})
    this.options = Object.assign({}, this.options, {disabled: false});
  }

  disableInputs() {
    // this.pepmatchForm.disable({emitEvent: false});
    this.pepmatchForm.controls['bestMatch'].disable({emitEvent: false})
    this.pepmatchForm.controls['proteome'].disable({emitEvent: false})
    this.options = Object.assign({}, this.options, {disabled: true});
  }

  onFilterApplied() {
    this.inputChange.table_state = this.submitted;
  }

  onClearTableState() {
    this.columns = _.map(this.columns, (c) => {
      c.filters = c.type === 'int'? {
          minValue: NaN,
          maxValue: NaN,
          applied: false
        } :
        {
          possibleValues: [],
          textValue: null,
          selectedValues: [],
          applied: false
        }
      return c
    })
    this.inputChange.table_state = this.submitted;
  }

  onSaveTableStateMain() {
    const hasResult = _.find(this.tools.slice(this.index+1), {submitted: true})
    if (hasResult) {
      this.saveState = true;
      this.open(this.confirmModal);
    } else {
      this.onSaveTableState();
    }
  }

  onSaveTableState() {
    const tool_group = 'pepmatch'

    const params = this.params
    // create colObj and params
    let colObj = columnListToObject(this.columns, tool_group)
    const stages = params.stages;
    const stageTemp = stages[this.index];

    // stageTemp.table_state.columns = colObj;
    stageTemp.table_state = [{table: 'peptide_table', columns: colObj}];

    stages[this.index] = stageTemp
    params.stages = stages;
    // run_stage_range
    const stage_number = this.index + 1;
    params.run_stage_range = [stage_number, stage_number];
    this._stateService.setParams(params);
    // appliedFilters
    let appliedFilters = !_.isEmpty(colObj);
    this._stateService.setToolAppliedFilters(appliedFilters, this.index)
    this.postPipelineAPI(params, false);
    this.saveState = false;
  }

  onNumOfMismatchesChange(changeContext: ChangeContext) : void {
    this.inputChange.mismatch = this.submitted;
  }

  sequenceParser(val: string) {

    const firstChar = ltrim(val)[0];
    const firstLine = val.split('\n')[0]
    console.log(firstLine)

    /** Named white space */
    let firstLineNumOfElements = 0
    let separator = ' '
    if(firstLine.includes(' ')){
      separator = ' '
    } else if (firstLine.includes('\t')){
      separator = '\t'
    } else if (firstLine.includes('\s')){
      separator = '\s'
    }
    firstLineNumOfElements = firstLine.split(separator).length
    console.log(firstLineNumOfElements)
    console.log(separator)

    const slicedVal = val.slice(0, this.maxCharacters)

    if(val.length > this.maxCharacters) {
      //  Skip parsing
    } else if (firstChar === '>' && this.sequenceParserService.fastaValidate(val)) { // fasta
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if ((firstChar === '{' || firstChar === '[') && IsJsonString(val)) { // json
      this.seqFormat = 'JSON'
      this.numOfCharacters = slicedVal.length
    } else if (firstLineNumOfElements === 2 && this.sequenceParserService.namedWhiteSpaceValidate(val, separator)) {
      // named white space
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val.length > 3 && !val.includes('>') && this.sequenceParserService.unnamedWhiteSpaceValidate(val)) {
      // Unnamed
      this.seqFormat = this.sequenceParserService.seqFormat;
      this.numOfCharacters = slicedVal.length
    } else if (val === '') {  // delete
      /**
       * Takes care of it up top - still need this if statement
       * */
      this.seqFormat = 'Unknown';
      this.numOfCharacters = 0
    } else { // error
      this.seqFormat = 'Error';
      this.numOfCharacters = 0
    }
  }

}
